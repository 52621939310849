import * as React from "react";
import styled from "styled-components";

const StyledLabel = styled.label`
  input[type="checkbox"] {
    cursor: pointer;
    display: none;

    + span {
      position: relative;
      cursor: pointer;
    }

    & + span::before,
    &::after {
      font-family: "FontAwesome";
      font-weight: lighter;
      transition: background 0.25s ease;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    }

    & + span::before {
      content: "\\f00d";
      font-size: 16px;
      border: 1px solid #ccc;
      text-indent: 32px;
      width: 52px;
      height: 22px;
      position: relative;
      display: inline-block;
      border-radius: 12px;
      background-color: #888;
      color: #f2f2f2;
      box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.15);
      line-height: 18px;
      border-width: 0;
    }

    & + span::after {
      content: "";
      border: 4px solid #fff;
      height: 18px;
      width: 18px;
      position: absolute;
      border-radius: 100%;
      background-color: #fff;
      text-align: center;
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
      top: 0;
      left: 3px;
      transition: left 0.25s ease;
    }

    &:checked + span {
      ::before {
        content: "\\f00c";
        color: #f2f2f2;
        background-color: #ff9e1b;
        text-indent: 6px;
      }
      ::after {
        left: 32px;
      }
    }

    &:disabled + span::before {
      background-color: #ddd;
      box-shadow: none;
      color: #bbb;
    }
    &:disabled + span::after {
      box-shadow: none;
    }

    &:disabled + span:hover {
      cursor: not-allowed;
    }
  }
`;

export interface ToggleProps extends React.HTMLProps<HTMLInputElement> {}

export class Toggle extends React.Component<ToggleProps> {
  render() {
    let { type, ...props } = this.props;
    return (
      <StyledLabel>
        <input type="checkbox" {...props} />
        <span />
      </StyledLabel>
    );
  }
}

export default Toggle;
