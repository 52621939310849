import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    label.required::after {
        content: " *";
        color: red;
    } /* Putting this here as I don't know where else to put it*/
  `;

export default GlobalStyle;
