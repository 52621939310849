import { useLocalStorage } from "hcss-hooks";
import { Sorting, Grouping } from "@devexpress/dx-react-grid";

export function useSorting(
  templateName: string,
  options: { defaultSorting?: Sorting[] } = { defaultSorting: [] }
) {
  const [sorting, setSorting] = useLocalStorage<Sorting[]>(
    `${templateName}.sorting`,
    options.defaultSorting || []
  );

  // const setNewSorting = (newSorting: Sorting[]) => {
  //   setSorting(newSorting);
  //   if (sorting.length || newSorting.length) {
  //     if (sorting.length && newSorting.length) {
  //       if (
  //         sorting[0].columnName !== newSorting[0].columnName ||
  //         sorting[0].direction !== newSorting[0].direction
  //       ) {
  //         setCurrentPage(0);
  //       }
  //     } else {
  //       setCurrentPage(0);
  //     }
  //   }
  // };

  return { sorting, setSorting };
}

export function createGroupedSorting(
  grouping: Grouping[] = [],
  sorting: Sorting[] = []
) {
  const newSorting: Sorting[] = [];
  for (let index = 0; index < grouping.length; index++) {
    const sortedColumn = sorting.length > index ? sorting[index] : undefined;
    const groupedColumn = grouping.length > index ? grouping[index] : undefined;

    if (
      groupedColumn &&
      sortedColumn &&
      sortedColumn.columnName === groupedColumn.columnName
    ) {
      newSorting.push(sortedColumn);
    }

    if (
      groupedColumn &&
      (!sortedColumn || sortedColumn.columnName !== groupedColumn.columnName)
    ) {
      newSorting.push({
        columnName: groupedColumn.columnName,
        direction: "asc"
      });
    }
  }

  if (sorting.length) {
    const lastSortedColumn = sorting[sorting.length - 1];

    if (
      newSorting
        .map(sort => sort.columnName)
        .indexOf(lastSortedColumn.columnName) === -1
    ) {
      newSorting.push(lastSortedColumn);
    }
  }

  return newSorting;
}
