// Success Green
export const successGreen = "#006627";

// Error Red
export const errorRed = "#A60000";

// New Grey Names

// charcoalGray
export const gray800 = "#1e1e1e";

// stoneGray
export const gray700 = "#3e3e3e";

// mediumDarkGray
export const gray600 = "#6e6e6e";

// mediumLightGray
export const gray500 = "#cccccc";

// silverGray
export const gray400 = "#d6d6d6";

// borderGray
export const gray300 = "#ececec";

// lightGray
export const gray200 = "#f0f0f0";

// offWhite
export const gray100 = "#f6f6f6";

// Old Greys
// export const charcoalGray = "#1e1e1e";
// export const stoneGray = "#3e3e3e";
// export const mediumDarkGray = "#6e6e6e";
// export const mediumLightGray = "#cccccc";
// export const silverGray = "#d6d6d6";
// export const borderGray = "#ececec";
// export const lightGray = "#f0f0f0";
// export const offWhite = "#f6f6f6";
