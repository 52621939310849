import * as React from "react";
import { connectMenu, MenuItem } from "react-contextmenu";
import { HeaderMenuItems, HeaderMenuItemsProps } from "./header-menu-items";
import { StyledContextMenu } from "./styles";
import { TableMenuItems, TableMenuItemsProps } from "./table-menu-items";

export interface HeaderContextMenuProps {
  headerMenuMessages?: HeaderMenuItemsProps["messages"];
  tableMenuMessages?: TableMenuItemsProps["messages"];
  trigger?: { name?: string };
  headerMenuItems?: HeaderMenuItemsProps["menuItems"];
  tableMenuItems?: TableMenuItemsProps["menuItems"];
}

export const HeaderContextMenu = connectMenu("headerclick")(
  ({
    trigger,
    headerMenuMessages,
    tableMenuMessages,
    headerMenuItems,
    tableMenuItems
  }: HeaderContextMenuProps) => {
    const showHeaderMenuItems = trigger?.name ?? false;
    const showTableMenuItems = tableMenuItems?.showVisibility ?? true; // TODO: not expandable
    return (
      <StyledContextMenu id="headerclick">
        {showHeaderMenuItems && (
          <HeaderMenuItems
            columnName={showHeaderMenuItems}
            messages={headerMenuMessages}
            menuItems={headerMenuItems}
          />
        )}
        {showHeaderMenuItems && showTableMenuItems && <MenuItem divider />}
        {showTableMenuItems && (
          <TableMenuItems
            menuItems={tableMenuItems}
            messages={tableMenuMessages}
          />
        )}
      </StyledContextMenu>
    );
  }
);

// quick filter based on type (e.g. string "Add 'road' to current filters,
// date "show projects within 7 days of filter")
