import * as React from "react";
import Modal, { ModalProps } from "../Modal";
import styled from "styled-components";
import Button from "../Button";
import Icon from "../Icon";
import TreeSelector from "../TreeSelector/TreeSelector";
import Link from "../Link";
import { Node } from "../TreeSelector/TreeSelector";
import { LocalizationMessages } from "./SelectModal";
import { getDefaultSelectModalMessages } from "./Utils";
import { CheckboxProps } from "../TreeSelector/TreeSelector";

const LinkWrapper = styled(Link)`
  float: left;

  :hover {
    text-decoration: none;
  }
`;

export interface TreeSelectModalProps extends ModalProps {
  /** Avaible for the caller to receive the selected items once the user clicks Ok */
  onSelectionConfirm: (selectedItems: Array<string | number>) => void;
  nodes?: Array<Node>;
  initialCheckedValues?: Array<string | number>;
  initialExpandedValues?: Array<string | number>;
  messages?: TreeLocalizationMessages;
  checkBoxProps?: CheckboxProps;
}

interface TreeLocalizationMessages extends LocalizationMessages {
  clearAllSelection?: string;
}

interface TreeSelectModalState {
  checked: Array<string | number>;
  expanded: Array<string | number>;
}

export default class TreeSelectModal extends React.Component<
  TreeSelectModalProps,
  TreeSelectModalState
> {
  state: TreeSelectModalState = {
    checked: this.props.initialCheckedValues || [],
    expanded: this.props.initialExpandedValues || []
  };

  onHide = () => {
    // Cancel
    this.props.onHide();
  };

  onSelectionConfirm = () => {
    // Confirm selected items
    this.props.onSelectionConfirm(this.state.checked);
  };

  render() {
    let defaultMessages: TreeLocalizationMessages = getDefaultSelectModalMessages();
    let defaultCheckBoxProps: CheckboxProps = {
      nodes: this.props.nodes ? this.props.nodes : [],
      checked: this.state.checked,
      expanded: this.state.expanded,
      onCheck: checked => this.setState({ checked }),
      onExpand: expanded => this.setState({ expanded })
    };
    if (this.props.checkBoxProps) {
      defaultCheckBoxProps = { ...this.props.checkBoxProps };
    }
    defaultMessages.clearAllSelection = "Clear all selection";
    const {
      title,
      nodes,
      onSelectionConfirm,
      messages,
      initialCheckedValues,
      initialExpandedValues,
      checkBoxProps,
      ...modalProps
    } = this.props;
    defaultMessages = { ...defaultMessages, ...messages };
    return (
      <Modal {...(modalProps as any)}>
        <Modal.Header closeButton>
          <h4 className="modal-title">{title}</h4>
        </Modal.Header>
        <Modal.Body>
          <TreeSelector {...defaultCheckBoxProps} />
        </Modal.Body>
        <Modal.Footer>
          <LinkWrapper
            onClick={() => this.setState({ checked: [] })}
            hcssStyle="Delete"
          >
            <Icon name="minus-circle" margin="right" />
            {defaultMessages.clearAllSelection}
          </LinkWrapper>
          <Button onClick={this.onHide} hcssStyle="ThemeInverted">
            <Icon name="remove" margin="left" />
            {defaultMessages.cancel}
          </Button>
          <Button onClick={this.onSelectionConfirm}>
            <Icon name="check" margin="right" />
            {defaultMessages.ok}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
