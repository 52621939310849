import * as React from "react";
import { ThemeProvider, ThemeProviderProps } from "styled-components";
import GlobalStyle from "./GlobalStyle";
export default class extends React.Component<ThemeProviderProps<object>> {
  render() {
    const { children, ...props } = this.props;
    return (
      <ThemeProvider {...props}>
        <>
          <GlobalStyle />
          {children}
        </>
      </ThemeProvider>
    );
  }
}
