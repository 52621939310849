export function isDuplicateFile(filename: string, files: string[]) {
  return files.find(file => file.toUpperCase() === filename.toUpperCase());
}

export function getDuplicateFilename(
  filename: string,
  uploadedFilenames: string[]
) {
  const newMatch = filename.match(/(.+)(\.[\w\d]+)$/i)!;
  const [, nameOnly, extension] = newMatch;
  const existingRegex = new RegExp(`${nameOnly} \\((\\d+)\\)${extension}`, "i");
  const maxNumber = uploadedFilenames.reduce((prev, curr) => {
    const existingMatch = curr.match(existingRegex);
    if (existingMatch) {
      const [, numberText] = existingMatch;
      const number = Number(numberText);
      if (number > prev) return number;
    }
    return prev;
  }, 0);

  return `${nameOnly} (${maxNumber + 1})${extension}`;
}
