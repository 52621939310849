import {
  IntegratedGrouping as DIntegratedGrouping,
  IntegratedGroupingProps as DIntegratedGroupingProps
} from "@devexpress/dx-react-grid";
import moment from "moment";
import numeral from "numeral";
import * as React from "react";
import { DataColumnType, TypedDataColumn } from "../models";
import { useTableContext } from "../table-context";

export interface IntegratedGroupingProps extends DIntegratedGroupingProps {
  columnOverrides?: Record<
    string,
    (value: any) => { key: string | number; value?: any }
  >;
  typeOverrides?: Record<
    number,
    (value: any) => { key: string | number; value?: any }
  >;
}

export const IntegratedGrouping = ({
  columnOverrides,
  typeOverrides,
  ...other
}: IntegratedGroupingProps) => {
  const { columns } = useTableContext();

  return React.useMemo(() => {
    // console.log("grouping extensions");
    const extensions = generateGroupingExtensions(
      columns,
      columnOverrides,
      typeOverrides
    );
    return <DIntegratedGrouping columnExtensions={extensions} {...other} />;
  }, [columns, columnOverrides, typeOverrides, other]);
};

export function generateGroupingExtensions(
  columns: TypedDataColumn[],
  columnOverrides?: IntegratedGroupingProps["columnOverrides"],
  typeOverrides?: IntegratedGroupingProps["typeOverrides"]
): DIntegratedGrouping.ColumnExtension[] {
  return columns.map(column => {
    const criteria =
      columnOverrides?.[column.name] ??
      typeOverrides?.[column.type] ??
      getGroupingFunction(column);
    return {
      columnName: column.name,
      criteria: criteria
    } as DIntegratedGrouping.ColumnExtension;
  });
}

export function getGroupingFunction({ type, config }: TypedDataColumn) {
  switch (type) {
    case DataColumnType.Boolean:
      return groupBoolean;
    case DataColumnType.Currency:
      return groupCurrency;
    case DataColumnType.Number:
      return groupNumber;
    case DataColumnType.Date:
    case DataColumnType.DateTime:
      return groupDate.bind(null, (config as any)?.dateGrouping ?? "quarter");
    case DataColumnType.Custom:
      return groupCustom.bind(null, (config as any)?.valueAsString);
    default:
      return groupString;
  }
}

function groupBoolean(value = false) {
  if (value) {
    return { key: "Checked", value: "Checked" };
  }
  return { key: "Not Checked", value: "Not Checked" };
}

function groupDate(interval: moment.unitOfTime.StartOf, value?: Date) {
  if (value) {
    const dateValue = moment(value);
    if (dateValue.isValid()) {
      const label = getDateGroupLabel(dateValue, interval);
      return {
        key: label,
        value: label
      };
    }
  }
  return { key: "" };
}

function getDateGroupLabel(
  value: moment.Moment,
  interval: moment.unitOfTime.StartOf
) {
  switch (interval) {
    case "year": {
      return value.startOf(interval).format("YYYY");
    }
    case "month": {
      return value.startOf(interval).format("MMMM YYYY");
    }
    case "quarter": {
      return `${value.startOf(interval).format("MMMM YYYY")} - ${value
        .endOf(interval)
        .format("MMMM YYYY")}`;
    }
    case "week": {
      return `${value.startOf(interval).format("L")} - ${value
        .endOf(interval)
        .format("L")}`;
    }
    case "day": {
      return `${value.startOf(interval).format("L")}`;
    }
    default: {
      return `${value.startOf(interval).format("L")} - ${value
        .endOf(interval)
        .format("L")}`;
    }
  }
}

function groupNumber(value?: number) {
  if (value !== undefined && value !== null) {
    return {
      key: numeral(value).format()
    };
  }
  return { key: "" };
}

function groupCurrency(value?: number) {
  if (value !== undefined && value !== null) {
    return {
      key: numeral(value).format("$0,0.00")
    };
  }
  return { key: "" };
}

function groupString(value?: string) {
  return { key: value || "" };
}

function groupCustom(valueAsString?: (value: any) => string, value?: string) {
  return { key: valueAsString?.(value) ?? "" };
}
