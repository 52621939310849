import * as React from "react";
import styled from "styled-components";
import {
  Accordion as BootstrapAccordion,
  Panel as BootstrapPanel,
  PanelGroup
} from "react-bootstrap";
import { Toolbar } from "./AccordionToolbar";
import PanelToggle from "react-bootstrap/lib/PanelToggle";
import PanelHeading from "react-bootstrap/lib/PanelHeading";
import PanelBody from "react-bootstrap/lib/PanelBody";

function ToggabledTitle({ children, ...props }: PanelToggle.PanelToggleProps) {
  return (
    <BootstrapPanel.Toggle>
      <BootstrapPanel.Title {...props}>{children}</BootstrapPanel.Title>
    </BootstrapPanel.Toggle>
  );
}

const StyledAccordion = styled(BootstrapAccordion)`
  .panel {
    border: none;
    box-shadow: none;
  }

  a.collapsed {
    .panel-title {
      background-color: rgb(246, 246, 246);
      color: #343434;
      font-weight: 400;
      :hover {
      }
      ::before {
        content: "\\F105";
      }
    }

    .panel-heading {
      border-left: solid 2px rgb(217, 217, 217);
    }
  }

  & > a:hover {
    text-decoration: none;
  }

  .panel + .panel {
    margin-top: 4px;
  }

  .panel-heading {
    padding: 0;
    background-color: rgb(245, 245, 245);
    min-height: 36px;
  }

  .panel-title {
    font-size: 13px;
    padding: 10px;
    display: inline-block;
    position: relative;
    line-height: 1;
    color: #343434;
    font-weight: 700;
    :hover {
      text-decoration: none;
    }

    ::before {
      content: "\\F107";
      font-family: "FontAwesome";
      margin: 0 10px 0 5px;
      width: 9px;
      display: inline-block;
      font-size: 15px;
    }
  }

  .panel-toolbar {
    float: right;
    line-height: 36px;
    display: block;
    position: relative;
    padding-right: 10px;

    & label {
      margin-bottom: 0;
    }
  }

  .collapse {
    background-color: #fff;
  }

  .panel.panel-default > .panel-collapse.in > div.panel-body {
    border-top: 2px solid;
    border-color: ${props => props.theme.primaryColor};
  }
  .panel.panel-default > .panel-collapse > div.panel-body {
    border-color: ${props => props.theme.primaryColor};
    border-width: 2px;
  }
`;

export interface AccordionProps extends PanelGroup.PanelGroupProps {}

export default class Accordion extends React.Component<AccordionProps> {
  render() {
    let { children, ...props } = this.props;
    return <StyledAccordion {...(props as any)}>{children}</StyledAccordion>;
  }

  static Panel = BootstrapPanel;
  static Heading = PanelHeading;
  static Title = ToggabledTitle;
  static Toggle = PanelToggle;
  static Body = PanelBody;
  static Toolbar = Toolbar;
}
