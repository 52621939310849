import React from "react";
import { FileMeta } from "../models";
import { initialState, SmartDriveApiState } from "./state";

export type SmartDriveApiAction =
  | { type: "loadFiles" }
  | { type: "setFiles"; payload: FileMeta[] }
  | { type: "updateFiles"; payload: FileMeta[] }
  | { type: "removeFile"; payload: string }
  | { type: "downloadFile"; payload: { id: string; isComplete: boolean } };

export function useSmartDriveReducer() {
  return React.useReducer(reducer, initialState);
}

function reducer(
  state: SmartDriveApiState,
  action: SmartDriveApiAction
): SmartDriveApiState {
  switch (action.type) {
    case "setFiles":
      return { ...state, files: action.payload, loading: false };
    case "loadFiles":
      return { ...state, loading: true };
    case "updateFiles":
      return { ...state, files: mergeFiles(state.files, action.payload) };
    case "removeFile":
      return {
        ...state,
        files: state.files.filter(file => file.id !== action.payload)
      };
    case "downloadFile":
      return {
        ...state,
        downloading: action.payload.isComplete
          ? state.downloading.filter(fileId => fileId !== action.payload.id)
          : [...state.downloading, action.payload.id]
      };
    default:
      return state;
  }
}

function createFileLookup(files: FileMeta[]) {
  const lookup: { [key: string]: FileMeta } = {};
  files.forEach(file => {
    lookup[file.id] = file;
  });
  return lookup;
}

function mergeFiles(existingFiles: FileMeta[], newFiles: FileMeta[]) {
  const fileLookup = createFileLookup(existingFiles);
  const successLookup = createFileLookup(newFiles);

  newFiles = newFiles.filter(file => !(file.id in fileLookup));
  existingFiles = existingFiles.map(file => {
    if (file.id in successLookup) {
      return successLookup[file.id];
    }
    return file;
  });

  return existingFiles.concat(newFiles);
}
