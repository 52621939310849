export const uppyConfig = {
  id: "uppyFileUpload",
  autoProceed: false,
  debug: true,
  allowMultipleUploads: true,
  restrictions: {
    maxFileSize: 104857600,
    maxNumberOfFiles: 20,
    minNumberOfFiles: 1,
    allowedFileTypes: null
  }
};
