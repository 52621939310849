import * as React from "react";
import styled from "styled-components";
import * as ConcreteColors from "../../../ConcreteColors";
import classNames from "classnames";
import { DropdownContext } from "./DropdownContext";

// #region Styles
const StyledDropdownGroup = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  list-style-type: none;
  margin: 0;
  height: 100%;
`;

const DropdownToggle = styled("a").attrs(() => ({
  href: "#"
}))`
  display: block;
  position: relative;
  border: none;
  height: 100%;
  text-align: left;

  &:hover,
  &:focus,
  &.dropdown-active {
    outline: none;
  }
`;

const DropdownButton = styled(DropdownToggle)<{ togglewidth?: number }>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  background: none;
  padding: 0 16px;
  font-size: 1.25rem;
  letter-spacing: 0.02rem;
  font-weight: 600;
  color: ${ConcreteColors.gray700};
  z-index: 3;
  transition: 0.2s;

  /* to prevent overriding from the default styling for anchor elements */
  &:focus:not(:hover):not(.dropdown-active) {
    color: ${ConcreteColors.gray700};
  }

  &:after {
    transition: 0.2s;
  }

  /* triangular pointer, shown only while Dropdown is active and DropdownMenu is visible */
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    top: 100%;
    left: 0;
    background: none;
    border-top: 0px solid transparent;

    border-left-style: solid;
    border-left-color: transparent;
    border-left-width: calc(${props => props.togglewidth}px / 2);

    border-right-style: solid;
    border-right-color: transparent;
    border-right-width: calc(${props => props.togglewidth}px / 2);
    transition: 0.2s, border-right-width, border-left-width 0s; /* prevents glitching when toggle region's width changes for whatever reason */
  }

  &:hover {
    color: ${ConcreteColors.blue200};
    background: ${ConcreteColors.blue100};

    &:after {
      border-top-color: ${ConcreteColors.blue100};
    }
  }

  &.dropdown-active {
    color: white;
    background: ${ConcreteColors.blue200};

    &:after {
      border-top-color: ${ConcreteColors.blue200};
    }
  }

  &:hover,
  &.dropdown-active {
    text-decoration: none !important;
  }

  &.dropdown-active {
    &:after {
      border-top-width: 12px;
      /* if we go with the green background color instead, and hover background is same as active background, use the timing function below */
      /* transition: border-top-width 0.2s cubic-bezier(0.5, 0.0, 0.5, 0.5); */
    }
  }
`;

const DropdownIcon = styled.i`
  font-size: 1.8rem;
  font-weight: 400;
  margin-top: -4px;
`;

// #endregion

class DropdownGroup extends React.Component<React.HTMLProps<HTMLUListElement>> {
  render() {
    const { children, className, ...props } = this.props;
    return (
      <StyledDropdownGroup
        className={classNames(className, "dropdown-group")}
        {...(props as any)}
      >
        {children}
      </StyledDropdownGroup>
    );
  }
}

export type DropdownProps = {
  className?: string;
  children: any;
  fullWidth?: boolean;
};

export type DropdownState = {
  isOpen: boolean;
  toggleRegionWidth: number;
};

class UnstyledDropdown extends React.Component<DropdownProps, DropdownState> {
  private dropdownToggleRef: React.RefObject<any> = React.createRef();
  private dropdownMenuRef: React.RefObject<any> = React.createRef();
  private dropdownToggleRegionRef: React.RefObject<any> = React.createRef();

  constructor(props: DropdownProps) {
    super(props);
    this.state = {
      isOpen: false,
      toggleRegionWidth: 0
    };
    if (props.children.length !== 2) {
      throw new Error("Dropdown requires dropdown toggle and dropdown menu");
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.toggleClose);
    setTimeout(() => {
      if (this.dropdownToggleRegionRef.current) {
        this.setState({
          toggleRegionWidth: (this.dropdownToggleRegionRef
            .current as Element).getBoundingClientRect().width
        });
      }
    }, 250);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.toggleClose);
  }

  toggleClose = (event: any) => {
    if (
      this.dropdownToggleRef.current &&
      !this.dropdownToggleRef.current.contains(event.target) &&
      this.dropdownMenuRef.current &&
      !this.dropdownMenuRef.current.contains(event.target)
    ) {
      this.setState({ isOpen: false });
    }
  };

  forceClose = () => this.setState({ isOpen: false });

  handleToggleFromMenu = (event: any) => {
    /* prevent the dropdown menu from hiding if the user clicks somewhere inside the menu container, but not on a link in the menu */
    if (!(event.target.className as string).includes("dropdown-menu")) {
      this.setState((prevState, props) => {
        return { isOpen: !prevState.isOpen };
      });
    }
  };

  handleToggle = (event: any) => {
    const toggleDropdown = () => {
      this.setState(prevState => {
        return { isOpen: !prevState.isOpen };
      });
    };
    event.preventDefault();
    /* immediately recalculate width of toggle region, then trigger the open/close animation, with the dropdown toggle button pointer */
    this.setState(
      {
        toggleRegionWidth: (this.dropdownToggleRegionRef
          .current as Element).getBoundingClientRect().width
      },
      toggleDropdown
    );
  };

  render() {
    //inner ref is used for styled components and ref should be used if dom element
    //currently only supporting inner ref as there isn't a use case yet for ref
    const dropdownToggle = React.cloneElement(
      this.props.children[0],
      {
        onClick: this.handleToggle,
        ref: this.dropdownToggleRef,
        className: classNames("dropdown-toggle", {
          "dropdown-active": this.state.isOpen
        }),
        togglewidth: this.state.toggleRegionWidth
      },
      this.props.children[0].props.children
    );

    const dropdownMenu = React.cloneElement(
      this.props.children[1],
      {
        isOpen: this.state.isOpen,
        onClick: this.handleToggleFromMenu,
        ref: this.dropdownMenuRef,
        className: classNames("dropdown-menu", {
          "dropdown-active": this.state.isOpen
        })
      },
      this.props.children[1].props.children
    );

    return (
      <DropdownContext.Provider value={this.forceClose}>
        <li
          className={classNames(this.props.className, "dropdown-upgraded", {
            "dropdown-active": this.state.isOpen
          })}
          ref={this.dropdownToggleRegionRef}
        >
          {dropdownToggle}
          {dropdownMenu}
        </li>
      </DropdownContext.Provider>
    );
  }
}

const StyledDropdown = styled(UnstyledDropdown)`
  display: inline-block;
  height: 100%;
  z-index: 2000;
`;

export default class UpgradedDropdown extends React.Component<DropdownProps> {
  static Group = DropdownGroup;
  static Toggle = DropdownToggle;
  static ToggleButton = DropdownButton;
  static Icon = DropdownIcon;
  render() {
    const { children, className, ...props } = this.props;
    return <StyledDropdown {...props}>{children}</StyledDropdown>;
  }
}
