import * as React from "react";
import { MenuItem } from "react-contextmenu";
import { useContextMenu } from "./use-context-menu";

export interface TableMenuItemsProps {
  menuItems?: {
    showVisibility?: boolean;
  };
  messages?: {
    showHideColumns?: string;
  };
}

export const TableMenuItems: React.FunctionComponent<TableMenuItemsProps> = ({
  messages,
  menuItems = {}
}) => {
  const { showVisibility = true } = menuItems;
  const { handleSelectColumns } = useContextMenu();

  return (
    <>
      {showVisibility && (
        <MenuItem onClick={handleSelectColumns}>
          {messages?.showHideColumns ?? `Show/Hide Columns`}
        </MenuItem>
      )}
    </>
  );
};

// quick filter based on type (e.g. string "Add 'road' to current filters,
// date "show projects within 7 days of filter")
