import React, { useEffect } from "react";
import { usePrintContext } from "./context";
import { FC } from "react";
import styled from "styled-components";
import { Box } from "hcss-core";

export interface PrintContainerProps {
  className?: string;
  hide?: boolean;
  title?: string;
}

const PrintContainer: FC<PrintContainerProps> = ({
  className,
  hide,
  title,
  children
}) => {
  const { contentRef, isPrinting } = usePrintContext();

  useEffect(() => {
    const originalTitle = window.document.title;
    if (title && isPrinting) {
      window.document.title = title;
    }

    return () => {
      if (title) {
        window.document.title = originalTitle;
      }
    };
  }, [title, isPrinting]);

  if (!isPrinting && hide) return null;

  return (
    <Box className={className} ref={contentRef}>
      {children}
    </Box>
  );
};

export const Print = styled(PrintContainer)<{}>`
  box-decoration-break: clone;
  display: ${props => (props.hide ? "none" : "block")};

  @media print {
    display: block;
  }

  @page {
    size: auto;
    margin: 30pt;
  }
`;

// eslint-disable-next-line no-unexpected-multiline
export const PrintSection = styled(Box)<{
  breakAfter?: boolean;
  breakBefore?: boolean;
  breakInside?: boolean;
  printOnly?: boolean;
}>`
  display: ${props => (props.printOnly ? "none" : "block")};
  @media print {
    & {
      page-break-inside: ${props => (props.breakInside ? "auto" : "avoid")};
      page-break-after: ${props => (props.breakAfter ? "always" : "auto")};
      page-break-before: ${props => (props.breakBefore ? "always" : "auto")};
      display: block;
    }
  }
`;

export const PrintIgnore: FC = ({ children }) => {
  const { isPrinting } = usePrintContext();
  if (!isPrinting) {
    return <>{children}</>;
  }
  return null;
};
