import Axios, { AxiosInstance } from "axios";
import { UseSmartdriveApiOptions } from "./use-smartdrive-api";
import { FileMeta } from "../models";

export class SmartDriveAxiosInstance {
  axios: AxiosInstance;

  constructor({ token, appId, url, root = "" }: UseSmartdriveApiOptions) {
    this.axios = Axios.create({
      baseURL: url,
      params: { isInternal: false, appId, root },
      headers: { Authorization: `Bearer ${token}` }
    });
  }

  loadFiles() {
    return this.axios.get<FileMeta[]>("/files/list");
  }

  downloadFile(id: string) {
    return this.axios.get("/files", {
      params: { id },
      baseURL: this.axios.defaults.baseURL,
      responseType: "blob"
    });
  }

  deleteFile(id: string) {
    return this.axios.delete("/files", {
      baseURL: this.axios.defaults.baseURL,
      params: { id }
    });
  }
}
