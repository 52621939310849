import * as React from "react";
import styled from "styled-components";
import {
  Modal as BootstrapModal,
  ModalHeader,
  ModalBody,
  ModalDialog,
  ModalFooter,
  ModalTitle
} from "react-bootstrap";
import classNames from "classnames";

const ModalWrapper = styled(BootstrapModal)`
  @media (min-width: 768px) {
    .modal-dialog {
      margin-top: 60px;
    }
  }

  .modal-content {
    border-radius: 0;
    box-shadow: none;
  }
  .modal-header {
    background-color: ${props =>
      props.theme.baseTheme === "Ace" ? props.theme.navbarColor : "White"};
    color: ${props => (props.theme.baseTheme === "Ace" ? "White" : "Black")};
    border-bottom-width: ${props =>
      props.theme.baseTheme === "Ace" ? "1px" : "0px"};

    & > button.close {
      color: ${props => (props.theme.baseTheme === "Ace" ? "White" : "Black")};
      opacity: 0.4;
      font-size: 28px;
      :hover {
        opacity: 0.9;
      }
    }
  }

  .modal-footer {
    background-color: ${props =>
      props.theme.baseTheme === "Ace" ? "#EFF3F8" : "White"};
    border-top-color: ${props =>
      props.theme.baseTheme === "Ace" ? "#E4E9EE" : "White"};
  }

  &.modal-draggable {
    .modal-dialog {
      margin: 0;
    }
    .modal-header {
      :before {
        content: "\\e951";
        font-family: "FontAwesome";
        display: inline-block;
        font-size: 16px;
        margin-right: 8px;
      }
      & > h4 {
        display: inline-block;
      }
    }
  }
`;

export interface ModalProps extends BootstrapModal.ModalProps {
  draggable?: boolean;
}

export default class Modal extends React.Component<ModalProps> {
  private _modalRef: React.RefObject<BootstrapModal>;
  private _waitingForMouseUp: boolean = false;
  private _ignoreBackdropClick: boolean = false;

  constructor(props: ModalProps) {
    super(props);
    this._modalRef = React.createRef();
  }

  // Check if user clicked down in modal
  handleMouseDown = (event: any) => {
    if (event.target.dataset.__tag__ !== "hcss-modal-wrapper") {
      this._waitingForMouseUp = true;
    }
  };

  // Check If user started down click in modal and
  // up click was in the backdrop. If so, ignore backdrop click.
  handleMouseUp = (event: any) => {
    if (
      this._waitingForMouseUp &&
      event.target.dataset.__tag__ === "hcss-modal-wrapper"
    ) {
      this._ignoreBackdropClick = true;
    }

    this._waitingForMouseUp = false;
  };

  // Ignore Hiding if we are bypassing backdrop click
  handleOnHide = () => {
    if (this._ignoreBackdropClick) {
      this._ignoreBackdropClick = false;
      return;
    }

    this.props.onHide();
  };

  render() {
    let { children, className, draggable, onHide, ...props } = this.props;
    return (
      <ModalWrapper
        data-__tag__="hcss-modal-wrapper"
        className={classNames(className, { "modal-draggable": draggable })}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        onHide={this.handleOnHide}
        ref={this._modalRef}
        {...(props as any)}
      >
        {children}
      </ModalWrapper>
    );
  }

  static Header = ModalHeader;
  static Body = ModalBody;
  static Footer = ModalFooter;
  static Title = ModalTitle;
  static Dialog = ModalDialog;
}
