import * as React from "react";
export type FooterProps = {
  children?: string;
};
export default class Footer extends React.Component<FooterProps> {
  static defaultProps = {
    children: " - Product 0.0.1"
  };
  render() {
    return (
      <footer className="footer clear">
        <div className="center">
          <a
            href="http://www.hcss.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            © {new Date().getFullYear()} HCSS
          </a>
          {this.props.children}
        </div>
      </footer>
    );
  }
}
