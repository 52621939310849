import { useLocalStorage } from "hcss-hooks";
import { TypedDataColumn } from "../models";

export function useColumnVisibility(
  templateName: string,
  options: { defaultHiddenColumns?: string[] } = { defaultHiddenColumns: [] }
) {
  const [hiddenColumns, setHiddenColumns] = useLocalStorage<string[]>(
    `${templateName}.columnVisibility`,
    options.defaultHiddenColumns || []
  );

  return { hiddenColumns, setHiddenColumns };
}

export function createDefaultColumnVisibility(columns: TypedDataColumn[]) {
  return columns.map(column => {
    return column.name;
  });
}
