import * as React from "react";
import styled from "styled-components";
import { hcssHeavyJob } from "../StandardColors";
import classNames from "classnames";

const StyledLabel = styled.label`
  font-weight: normal;
  cursor: pointer;
  &.disabled:hover {
    cursor: not-allowed;
  }

  input[type="radio"] {
    display: none;
  }
  .radio-hcss {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 1px solid #ccc;
    position: relative;
    top: 3px;
    margin: 1px;
    background-color: #fafafa;
    border-radius: 50px;
    margin-right: 5px;

    :hover {
      border-color: #ff893c;
    }
  }
  input[type="radio"]:disabled + .radio-hcss,
  input[type="radio"]:disabled:checked + .radio-hcss {
    background-color: #ddd;
    border-color: #ccc;
  }

  input[type="radio"]:checked + .radio-hcss {
    background-color: #fff;
    border-color: #ff893c;
    border-radius: 50px;
  }

  input[type="radio"]:checked + .radio-hcss::before {
    font-family: "FontAwesome";
    content: "\\F111";
    position: absolute;
    top: -3px;
    left: 2.25px;
    color: ${hcssHeavyJob};
    font-size: 12px;
  }

  input[type="radio"]:checked:disabled + .radio-hcss::before {
    color: #bbb;
  }
`;

export interface RadioProps extends React.HTMLProps<HTMLInputElement> {}

export const Radio = ({ children, type, className, ...props }: RadioProps) => {
  return (
    <StyledLabel
      className={classNames(className, { disabled: props.disabled })}
    >
      <input type="radio" {...props} />
      <span className="radio-hcss" />
      {children}
    </StyledLabel>
  );
};

export default Radio;
