// This file defines standard colors for use within components in the bundle. For usage guidelines for these colors, please refer to the Style Guide

// Brand Colors
export const hcssGreen = "#009639";
export const hcssGray = "#636466";

// App Color

// Same color for Project Management and Checkbox component background color
export const hcssHeavyJob = "#005EB8";

// Same color as hcssGreen and Pre-Constructions, Insights, Bid Results
export const hcssHeavyBid = "#009639";

export const hcssSafety = "#FF9E1B";
export const hcssTelematics = "#00B5E2";
export const hcssDispatcher = "#E35205";

// Same color for Maintenance Request
export const hcssE360 = "#007681";

export const hcssFuelerPlus = "#642f6c";
export const hcssCredentials = "#5E7461";
export const hcssELogs = "#7A9A01";
export const hcssCloud = "#64A70B";
export const hcssTrucking = "#00B2A9";
export const hcssForms = "#418FDE";
export const hcssPlans = "#485CC7";
export const hcssSkills = "#4F758B";
export const hcssIntelligence = "#003A70";
export const hcssEmployeeApp = "#7B6469";
