import React from "react";
import styled from "styled-components";
import { StepContainerProps as ComponentProps } from "./Models";

const StepContainer: React.FC<ComponentProps> = (props: ComponentProps) => {
  // Deconstruct props
  const {
    currentStepIndex,
    isTransitioning,
    minContentHeight,
    testId,
    style,
    children
  } = props;

  const getClassName = (): string => {
    let value = "wizard-step-container wizard-current-step-content-container";
    value += ` step-${currentStepIndex + 1}-content-container`;
    if (isTransitioning) value += " transition-active";
    return value;
  };

  return (
    <StyledStepContainer
      className={getClassName()}
      data-testid={testId || "wizard-current-step-content-container"}
      style={{
        minHeight: minContentHeight,
        ...style
      }}
    >
      {children}
    </StyledStepContainer>
  );
};
export default StepContainer;

// Styled components
const StyledStepContainer = styled.div`
  position: relative;
  opacity: 1;
  transform: translateZ(0);
  transition: 0.25s;

  &.transition-active {
    opacity: 0;
  }
`;
