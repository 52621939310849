import * as React from "react";
import Modal, { ModalProps } from "../Modal";
import Button from "../Button";
import Icon from "../Icon";
import { SimpleTableSelector } from "./DataSelectors/SimpleTableSelector";
import { BaseDataSelectorProps } from "./Models";
import { isFunction } from "./Utils";

export interface SelectModalProps extends ModalProps, BaseDataSelectorProps {
  /** Avaible for the caller to receive the selected items once the user clicks Ok */
  onSelectionConfirm: (selectedItems: any[]) => any;
  dataSelector?: any; // TODO: Find a way to mark this a ReactComponentClass or ReactElement that implements IDataSelector
  rows: any;
  messages?: LocalizationMessages;
}

export interface LocalizationMessages {
  ok?: string;
  cancel?: string;
}

/**
 * Basic Selection Modal, it shows a Modal with a default modal header title
 * and a footer with a Cancel and Ok Button, the way the data is going to selected
 * is up to the caller since the prop dataSelector expect a React component that display
 * and select the data the only condition is that it must Implement IDataSelector and
 * returns the selected items on the getItemsSelected method if no dataSelector is provided
 * it will use a default SimpleTable Selector
 */
export default class SelectModal extends React.Component<SelectModalProps> {
  dataSelector: any;

  setDataSelectorRef = (dataSelector: any) => {
    this.dataSelector = dataSelector;
  };

  /**
   * It checks if a Custom Data Selector has been provided, if it has
   * it create the CustomDataSelector and pass the corresponding properties and
   * add the reference in order be able to call it's getItemsSelected method
   * if no dataSelector is provided it's uses SimpleTableSelector
   */
  createDataSelector = () => {
    const CustomDataSelector = this.props.dataSelector;
    const dataSelectorProps: BaseDataSelectorProps = {
      columnKey: this.props.columnKey,
      columns: this.props.columns,
      rows: this.props.rows,
      initialSelectedIds: this.props.initialSelectedIds
    };

    if (React.isValidElement(CustomDataSelector)) {
      return React.cloneElement(CustomDataSelector, {
        ...{ ref: this.setDataSelectorRef },
        ...dataSelectorProps
      });
    }

    if (CustomDataSelector && isFunction(CustomDataSelector)) {
      return (
        <CustomDataSelector
          ref={this.setDataSelectorRef}
          {...dataSelectorProps}
        />
      );
    }

    return (
      <SimpleTableSelector
        ref={this.setDataSelectorRef}
        columnKey={this.props.columnKey}
        columns={this.props.columns}
        rows={this.props.rows}
        initialSelectedIds={this.props.initialSelectedIds}
      />
    );
  };

  onHide = () => {
    // Cancel
    this.props.onHide();
  };

  onSelectionConfirm = () => {
    // Confirm selected items
    const selectedItems = this.dataSelector.getItemsSelected();
    this.props.onSelectionConfirm(selectedItems);
  };

  render() {
    return (
      <Modal show={this.props.show} onHide={this.onHide}>
        <Modal.Header closeButton>
          <h4 className="modal-title">{this.props.title}</h4>
        </Modal.Header>
        <Modal.Body>{this.createDataSelector()}</Modal.Body>
        <Modal.Footer>
          <Button onClick={this.onHide} hcssStyle="ThemeInverted">
            <Icon name="remove" margin="right" />
            Cancel
          </Button>
          <Button onClick={this.onSelectionConfirm}>
            <Icon name="check" margin="right" />
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
