import * as React from "react";
import {
  default as ReactDropzone,
  DropzoneProps as ReactDropzoneProps
} from "react-dropzone";
import { gray500 } from "../AceColors";
import styled from "styled-components";

const StyledDropzone = styled.div`
  width: 100%;
  height: 100%;
  & > div.drop-zone-class {
    color: #f00;
    width: 100%;
    height: 100%;
    display: table;
    vertical-align: middle;
    border: 2px dashed ${gray500};
  }
  & > div.drop-zone-class > div {
    width: 100%;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    display: inline-cell;
    color: #000;
    margin: 10px;
  }
`;

export interface DropzoneProps extends ReactDropzoneProps {
  message?: string;
}

export default class Dropzone extends React.Component<DropzoneProps, any> {
  render() {
    const message = this.props.message
      ? this.props.message
      : "Please drop your file(s) here or click to select";
    return (
      <StyledDropzone>
        <ReactDropzone className="drop-zone-class" {...this.props}>
          <div>{message}</div>
        </ReactDropzone>
      </StyledDropzone>
    );
  }
}
