import { useLocalStorage } from "hcss-hooks";
import { TypedDataColumn } from "../models";

export function useColumnOrdering(templateName: string) {
  const [columnOrder, setColumnOrder] = useLocalStorage<string[]>(
    `${templateName}.columnOrder`,
    []
  );

  return { columnOrder, setColumnOrder };
}

export function createDefaultColumnOrder(
  columns: TypedDataColumn[],
  currentOrder: string[]
) {
  const newOrder: string[] = [...currentOrder];

  const allColumns = columns.map(column => {
    return column.name;
  });

  allColumns.forEach(column => {
    if (newOrder.indexOf(column) === -1) {
      newOrder.push(column);
    }
  });

  return newOrder;
}
