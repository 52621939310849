import {
  IntegratedFiltering as DIntegratedFiltering,
  IntegratedFilteringProps
} from "@devexpress/dx-react-grid";
import * as React from "react";
import { DataColumnType } from "../models";
import { useTableContext } from "../table-context";

export const IntegratedFiltering = (props: IntegratedFilteringProps) => {
  const { columns } = useTableContext();
  return React.useMemo(() => {
    return (
      <DIntegratedFiltering
        columnExtensions={columns
          .filter(
            c =>
              c.type === DataColumnType.Links ||
              c.type === DataColumnType.CheckList ||
              c.type === DataColumnType.Custom
          )
          .map(c => ({
            columnName: c.name,
            predicate: (value, filter) => {
              if (c.type === DataColumnType.Links) {
                return (
                  linkToString(value)
                    .toUpperCase()
                    .indexOf(filter.value!.toUpperCase()) > -1
                );
              }
              if (c.type === DataColumnType.CheckList) {
                return (
                  checkListToString(value)
                    .toUpperCase()
                    .indexOf(filter.value!.toUpperCase()) > -1
                );
              }
              if (c.type === DataColumnType.Custom && c.config) {
                return (
                  c.config
                    .valueAsString(value)
                    .toUpperCase()
                    .indexOf(filter.value!.toUpperCase()) > -1
                );
              }
              return true;
            }
          }))}
        {...props}
      />
    );
  }, [columns, props]);
};

const linkToString = (value: any) => {
  let retString = "";
  if (value) {
    value.forEach((link: any, index: number) => {
      retString += index > 0 ? ", " : "";
      retString += link.description || link.url;
    });
  }
  return retString;
};

const checkListToString = (value: any) => {
  let retString = "";
  if (value) {
    value.forEach((list: any, index: number) => {
      retString += index > 0 ? ", " : "";
      retString += list.checked ? "Checked " : "Not Checked ";
      retString += list.description;
    });
  }
  return retString;
};
