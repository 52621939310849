import { TableHeaderRow as DTableHeaderRow } from "@devexpress/dx-react-grid-bootstrap3";
import * as React from "react";
import { ContextMenuTrigger } from "react-contextmenu";

export const TableHeaderCell = ({
  children,
  ...props
}: DTableHeaderRow.CellProps) => {
  return (
    <DTableHeaderRow.Cell {...props}>
      <ContextMenuTrigger
        attributes={{ style: { width: "100%" } }}
        id="headerclick"
        collect={() => ({
          name: props.column.name,
          title: props.column.title
        })}
      >
        {children}
      </ContextMenuTrigger>
    </DTableHeaderRow.Cell>
  );
};
