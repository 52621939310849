// @ts-ignore
import { isGroupTableRow } from "@devexpress/dx-grid-core";
import {
  Plugin,
  Template,
  TemplateConnector,
  TemplatePlaceholder
} from "@devexpress/dx-react-core";
import { produce } from "immer";
import React from "react";
import { TypedDataColumn } from "../models";
import { useTableContext } from "../table-context";
import { getGroupKeys } from "./group-keys";
import { DataColumnType } from "../models copy";

export interface ColumnInfoProps {
  name: string;
  type: number;
}

//grab out each column's name and type
const returnColumnInfo = (columns: any) => {
  const cols = columns
    .map((col: any) => {
      if (col.props.tableColumn.column != null) {
        return {
          name: col.props.tableColumn.column.name,
          type: col.props.tableColumn.column.type,
          getCellValue: col.props.tableColumn.column.getCellValue
            ? col.props.tableColumn.column.getCellValue
            : (row: any) => row[col.props.tableColumn.column.name]
        };
      }
      return null;
    })
    .filter((o: any) => o !== null);
  return cols;
};

const totalColumnValues = (
  colAccumulator: any,
  newValue: any,
  columnInfo: any[]
) =>
  columnInfo.map(col => {
    let groupVal;
    if (colAccumulator.length > 0) {
      groupVal = colAccumulator.find((group: ColumnInfoProps) =>
        group.hasOwnProperty(col.name)
      );
    }
    if (
      col.type === DataColumnType.Number ||
      col.type === DataColumnType.Currency
    ) {
      const newCellTotal = col.getCellValue(newValue);
      const valTotal =
        (groupVal && groupVal[col.name] ? groupVal[col.name] : 0) +
        (newCellTotal ? newCellTotal : 0);
      return { [col.name]: valTotal, type: col.type };
    } else {
      return {
        [col.name]: null,
        type: col.type
      };
    }
  });

//filter to only rows in the group, grab the column names, reduce each into totals
const totalAndReduceColumn = (getters: any, params: any, grouping: any) => {
  const response: TypedDataColumn[] = getters.allRows.detailRows
    .filter((row: any) => {
      let matchesGroupKeys = true;
      const rowGroupKeysArray = params.tableRow.row.compoundKey.split("|");
      //Handle multiple filters
      rowGroupKeysArray.forEach(function(
        tableRowGroupKey: string,
        index: number
      ) {
        const allRowsGroupKeys = getGroupKeys(
          [row],
          grouping,
          getters.tableColumns.map((tableColumn: any) => tableColumn.column)
        )
          .slice(-1)[0]
          .split("|");
        if (tableRowGroupKey !== allRowsGroupKeys[index])
          matchesGroupKeys = false;
      });
      return matchesGroupKeys;
    })
    .reduce((groupRowAcc: ColumnInfoProps[], newRow: any) => {
      const columnInfo = returnColumnInfo(params.children);
      //for each column, attempt to sum up
      const totals = totalColumnValues(groupRowAcc, newRow, columnInfo);
      return totals;
    }, {});
  return response.length > 0 ? response : [];
};

export const GroupTotals = () => {
  const {
    grouping: { grouping }
  } = useTableContext();

  return (
    <Plugin name="GroupTotals">
      <Template
        name="tableRow"
        predicate={({ tableRow }: any) => isGroupTableRow(tableRow)}
      >
        {(params: any) => (
          <TemplateConnector>
            {getters => {
              const updatedParams = produce(params, draft => {
                draft.grouping = getters.grouping;
                draft.tableRow.row.totaledColumnData = totalAndReduceColumn(
                  getters,
                  params,
                  grouping
                );
              });
              return <TemplatePlaceholder params={updatedParams} />;
            }}
          </TemplateConnector>
        )}
      </Template>
    </Plugin>
  );
};
