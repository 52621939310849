import {
  SummaryItem,
  SummaryState as DSummaryState,
  SummaryStateProps
} from "@devexpress/dx-react-grid";
import * as React from "react";
import {
  DataColumnType,
  Grouping,
  SummaryType,
  TypedDataColumn
} from "../models";
import { useTableContext } from "../table-context";

export const SummaryState = (props: SummaryStateProps) => {
  const {
    columns,
    extendedGroups,
    summary: { summaryItems }
  } = useTableContext();

  return React.useMemo(() => {
    const allSummaryItems = createDefaultSummaryItems(
      columns,
      summaryItems,
      extendedGroups
    );
    return (
      <DSummaryState
        totalItems={allSummaryItems}
        groupItems={allSummaryItems.map(s => ({
          ...s,
          showInGroupFooter: false,
          alignByColumn: true
        }))}
        {...props}
      />
    );
  }, [props, columns, summaryItems, extendedGroups]);
};

export function createDefaultSummaryItems(
  columns: TypedDataColumn[],
  currentSummaryItems: SummaryItem[],
  grouping: Grouping[]
) {
  const newItems: SummaryItem[] = [...currentSummaryItems];

  const numericColumns = columns
    .filter(
      c =>
        c.type === DataColumnType.Number || c.type === DataColumnType.Currency
    )
    .map(column => {
      return {
        columnName: column.name,
        type: SummaryType.Sum
      };
    });

  numericColumns.forEach(column => {
    if (!newItems.find(i => i.columnName === column.columnName)) {
      newItems.push(column);
    }
  });

  return newItems.filter(
    item => !grouping.find(g => g.columnName === item.columnName)
  ); // we have to filter grouped columns because if we are grouped on a numeric column it will cause the group totals to be off by one
}
