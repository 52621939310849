import * as React from "react";
import styled from "styled-components";

export interface WidgetToolsProps extends React.HTMLProps<HTMLDivElement> {}

const Tools = ({ className, ref, children, ...props }: WidgetToolsProps) => {
  return (
    <Toolbar className={className} {...(props as any)}>
      {children}
    </Toolbar>
  );
};

export default Tools;

const Toolbar = styled.div`
  line-height: 36px;
  padding: 0;
  margin: 0;
  display: block;
  padding: 0 10px;
  line-height: 36px;
  float: right;
  position: relative;
  &:before {
    display: inline-block;
    content: "";
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: -1px;
    border: 1px solid #d9d9d9;
    border-width: 0 1px 0 0;
  }

  &.no-border:before {
    display: none;
  }

  & > label {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
  }

  & > .widget-menu {
    display: inline-block;
    position: relative;
  }

  & > .widget-menu > a,
  & > a {
    font-size: 14px;
    display: inline-block;
    padding: 0;
    line-height: 36px;
  }

  & > .widget-menu > a:hover,
  & > a:hover {
    text-decoration: none;
  }

  & > .widget-menu > a[data-action],
  & > a[data-action] {
    -webkit-transition: transform 0.1s;
    -o-transition: transform 0.1s;
    transition: transform 0.1s;
  }
  & > .widget-menu > a[data-action] > .ace-icon,
  & > a[data-action] > .ace-icon {
    margin-right: 0;
  }
  & > .widget-menu > a[data-action]:focus,
  & > a[data-action]:focus {
    text-decoration: none;
    outline: 0;
  }
  & > .widget-menu > a[data-action]:hover,
  & > a[data-action]:hover {
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
  }
  & .progress {
    vertical-align: middle;
    display: inline-block;
    margin: 0;
  }
  & > .dropdown,
  & > .dropup {
    display: inline-block;
  }
  .widget-toolbox.toolbox-vertical,
  .widget-toolbox.toolbox-vertical + .widget-main {
    display: table-cell;
    vertical-align: top;
  }
  & > .nav-tabs {
    border-bottom-width: 0;
    margin-bottom: 0;
    top: auto;
    margin-top: 3px !important;
  }
  & > .nav-tabs > li {
    margin-bottom: auto;
  }
  & > .nav-tabs > li > a {
    box-shadow: none;
    position: relative;
    top: 1px;
    margin-top: 1px;
  }
  & > .nav-tabs > li:not(.active) > a {
    border-color: transparent;
    background-color: transparent;
  }
  & > .nav-tabs > li:not(.active) > a:hover {
    background-color: transparent;
  }
  & > .nav-tabs > li.active > a {
    background-color: #fff;
    border-bottom-color: transparent;
    box-shadow: none;
    margin-top: auto;
  }
  & > .nav-tabs .widget-color-orange > .widget-header > li > a {
    color: #855d10;
  }

  & > .btn-toolbar {
    margin: 0 !important;
    padding: 0;
  }
`;
