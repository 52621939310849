import { FileMeta } from "../models";

export interface SmartDriveApiState {
  files: FileMeta[];
  loading: boolean;
  downloading: string[];
}

export const initialState: SmartDriveApiState = {
  files: [],
  downloading: [],
  loading: true
};
