import * as React from "react";
import styled from "styled-components";
import { hcssHeavyJob } from "../StandardColors";
import classNames from "classnames";

const StyledLabel = styled.label`
  font-weight: normal;
  cursor: pointer;
  padding-left: 10px;
  position: relative;

  &.disabled:hover {
    cursor: not-allowed;
  }

  input[type="checkbox"] {
    opacity: 0;
  }
  .checkbox-hcss {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 1px solid #ccc;
    position: absolute;
    top: 3px;
    left: 0;
    margin: 1px;
    background-color: #fafafa;
    border-radius: 2px;

    :hover {
      border-color: #ff893c;
    }
  }
  input[type="checkbox"]:disabled + .checkbox-hcss,
  input[type="checkbox"]:disabled:checked + .checkbox-hcss {
    background-color: #ddd;
    border-color: #ccc;
  }

  input[type="checkbox"]:checked + .checkbox-hcss {
    background-color: ${hcssHeavyJob};
    border-color: ${hcssHeavyJob};
    border-radius: 2px;
  }
  input[type="checkbox"]:checked + .checkbox-hcss::before {
    font-family: "FontAwesome";
    content: "\\F00C";
    position: absolute;
    left: 1px;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
  }
  input[type="checkbox"]:checked:disabled + .checkbox-hcss::before {
    color: #bbb;
  }

  input[type="checkbox"]:focus + .checkbox-hcss {
    border-color: #ff893c;
  }
`;

export interface CheckboxProps extends React.HTMLProps<HTMLInputElement> {}

export const Checkbox = ({
  children,
  type,
  className,
  ...props
}: CheckboxProps) => {
  return (
    <StyledLabel
      className={classNames(className, { disabled: props.disabled })}
    >
      <input type="checkbox" {...props} />
      <span className="checkbox-hcss" />
      {children}
    </StyledLabel>
  );
};

export default Checkbox;
