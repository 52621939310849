import * as React from "react";
import { FormControl } from "react-bootstrap";
import styled from "styled-components";
import * as ConcreteColors from "../../ConcreteColors";
import { InputFieldContext } from "./Context";
import { ValidationStateType } from "./models";
import classNames from "classnames";

const StyledField = styled(FormControl)`
  display: block;
  width: 100%;
  padding: 4px 8px;
  transition: 0.2s;
  :focus {
    box-shadow: none;
    border-color: ${ConcreteColors.blue200};
  }

  &.has-error {
    background: #ffeff0;
  }
`;

export interface TextFieldProps
  extends FormControl.FormControlProps,
    ValidationStateType {}

export const TextField = ({
  children,
  className,
  required,
  ...props
}: TextFieldProps) => {
  const context = React.useContext(InputFieldContext);

  const classes = classNames(className, {
    "has-error":
      context.validationState === "error" || props.validationState === "error",
    "has-success":
      context.validationState === "success" ||
      props.validationState === "success"
  });

  return (
    <StyledField
      {...(props as any)}
      className={classes}
      required={context.required || required}
    >
      {children}
    </StyledField>
  );
};

export default TextField;
