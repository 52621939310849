import { Column } from "@devexpress/dx-react-grid";

export enum DataColumnType {
  ShortText = 0,
  LongText = 1,
  Date = 2,
  List = 3,
  States = 4,
  Subform = 5,
  Boolean = 6,
  Number = 7,
  Currency = 8,
  DateTime = 9,
  Estimates = 10,
  BidResults = 11,
  Links = 12,
  PhoneNumber = 13,
  CheckList = 14
}

export interface TypedDataColumn extends Column {
  type: DataColumnType;
  config?: { [key: string]: any };
  readOnly?: boolean;
  required?: boolean;
  id?: string;
}
