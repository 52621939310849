import React, { ReactNode } from "react";
import { OAuthConfiguration } from "../models";
import { getUrlForOAuthProvider } from "../oauth-providers";
import styled from "styled-components";
import { OutboxAxiosInstance } from "../api";

export interface OAuthSignInLinkProps extends OAuthConfiguration {
  children: ReactNode;
}
export const OAuthSignInLink = ({
  userId,
  baseApiUrl,
  baseOutboxAuthUrl,
  provider,
  getAccessToken,
  onUpdate,
  children
}: OAuthSignInLinkProps) => {
  const updateSettings = async () => {
    try {
      const accessToken = getAccessToken();
      const client = new OutboxAxiosInstance(baseApiUrl, accessToken);
      const response = await client.getUserOAuthInfo();
      const result = response.data;
      if (onUpdate) {
        onUpdate(result);
      }
    } catch (error) {
      // TODO: Handle error
    }
  };

  const handleOnClick = () => {
    const providerUrl = getUrlForOAuthProvider(provider);
    const signInURL = `${baseOutboxAuthUrl}/${providerUrl}/${userId}`;
    const signInWindow = window.open(signInURL);

    const timer = setInterval(() => {
      if (signInWindow && signInWindow.closed) {
        clearInterval(timer);
        updateSettings();
      }
    }, 500);
  };

  return <StyledLink onClick={handleOnClick}>{children}</StyledLink>;
};

const StyledLink = styled.a`
  cursor: pointer !important;
`;
