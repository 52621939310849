import React from "react";
import styled from "styled-components";
import debounce from "lodash/debounce";
import { ExpandableContentStatus } from "../ExpandableContentStatus";

export interface SlideoutPanelHeaderProps {
  onHide: (event: any) => void;
  panelTitle: string;
}
export class SlideoutPanelHeader extends React.PureComponent<
  SlideoutPanelHeaderProps,
  any
> {
  handleHidePanelRequested = (event: any) => {
    this.props.onHide(event);
  };

  render() {
    return (
      <StyledSlideoutPanelHeader className="modal-panel-header">
        <StyledSlideoutPanelCloseButton
          className="modal-panel-close-button"
          onClick={this.handleHidePanelRequested}
        >
          <i className="fa fa-times" />
        </StyledSlideoutPanelCloseButton>
        <StyledSlideoutPanelTitle className="modal-panel-title">
          {this.props.panelTitle}
        </StyledSlideoutPanelTitle>
      </StyledSlideoutPanelHeader>
    );
  }
}

export class SlideoutPanelBody extends React.PureComponent {
  render() {
    return (
      <StyledSlideoutPanelBody className="modal-panel-body">
        {this.props.children}
      </StyledSlideoutPanelBody>
    );
  }
}

export class SlideoutPanelFooter extends React.PureComponent {
  render() {
    return (
      <StyledSlideoutPanelFooter className="modal-panel-footer">
        {this.props.children}
      </StyledSlideoutPanelFooter>
    );
  }
}

export interface SlideoutPanelProps {
  className?: string;
  isActive: boolean;
  onHide: () => void;
}
export interface SlideoutPanelState {
  panelStatus: ExpandableContentStatus;
}
export class SlideoutPanel extends React.PureComponent<
  SlideoutPanelProps,
  SlideoutPanelState
> {
  static Header = SlideoutPanelHeader;
  static Body = SlideoutPanelBody;
  static Footer = SlideoutPanelFooter;

  readonly state = {
    panelStatus: ExpandableContentStatus.Collapsed
  };

  componentDidUpdate(
    prevProps: SlideoutPanelProps,
    prevState: SlideoutPanelState
  ) {
    if (prevProps.isActive !== this.props.isActive) {
      document.addEventListener("keydown", this.handleKeyPressed, false);
      if (this.props.isActive) {
        this.setState(
          { panelStatus: ExpandableContentStatus.Expanding },
          debounce(
            () =>
              this.setState({ panelStatus: ExpandableContentStatus.Expanded }),
            50
          )
        );
      } else {
        document.removeEventListener("keydown", this.handleKeyPressed, false);
        this.setState(
          { panelStatus: ExpandableContentStatus.Collapsing },
          debounce(
            () =>
              this.setState({ panelStatus: ExpandableContentStatus.Collapsed }),
            425
          )
        );
      }
    }
  }

  handleHidePanelRequested = () => {
    this.props.onHide();
  };

  handleKeyPressed = (event: any) => {
    if (event.key === "Escape") this.handleHidePanelRequested();
  };

  render() {
    return (
      this.state.panelStatus !== ExpandableContentStatus.Collapsed && (
        <StyledSlideoutPanel
          className={`modal-panel${
            this.props.className ? ` ${this.props.className}` : ""
          }`}
          data-panel-status={this.state.panelStatus.toString()}
        >
          <StyledSlideoutPanelOverlayScreen
            className="modal-panel-overlay-screen"
            onClick={this.handleHidePanelRequested}
          />
          <StyledSlideoutPanelContent className="modal-panel-content">
            {this.props.children}
          </StyledSlideoutPanelContent>
        </StyledSlideoutPanel>
      )
    );
  }
}

export default SlideoutPanel;

const StyledSlideoutPanel = styled("div")`
  position: absolute;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &[data-panel-status="expanding"] {
    & > .modal-panel-overlay-screen {
      opacity: 0;
    }
    & > .modal-panel-content {
      right: -500px;
    }
  }
  &[data-panel-status="expanded"] {
    .modal-panel-overlay-screen {
      opacity: 1;
    }
    & > .modal-panel-content {
      right: 0;
    }
  }
  &[data-panel-status="collapsing"] {
    .modal-panel-overlay-screen {
      opacity: 0;
    }
    & > .modal-panel-content {
      right: -500px;
    }
  }
  &[data-panel-status="collapsed"] {
    .modal-panel-overlay-screen {
      opacity: 0;
    }
    & > .modal-panel-content {
      right: -500px;
    }
  }
`;

const StyledSlideoutPanelOverlayScreen = styled("div")`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2010;
  transition: 0.3s;
`;

const StyledSlideoutPanelContent = styled("div")`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  position: absolute;
  width: 480px;
  top: 0;
  height: 100%;
  background: #ffffff;
  box-shadow: 0 0 20px -5px #000000;
  z-index: 2020;
  transition: 0.4s;
`;

const StyledSlideoutPanelHeader = styled("div")`
  position: relative;
  padding: 36px 20px 16px 20px;
  background: #f6f6f6;
`;

const StyledSlideoutPanelCloseButton = styled("div")`
  display: inline-block;
  position: absolute;
  top: 36px;
  right: 20px;
  font-size: 20px;
  color: #707070;
  line-height: 24px;
  vertical-align: middle;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    color: #1e1e1e;
  }
`;

const StyledSlideoutPanelTitle = styled("h3")`
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  vertical-align: middle;
  margin: 0;
`;

const StyledSlideoutPanelBody = styled("div")`
  height: 100px;
  flex-grow: 1;
  margin-bottom: 16px;
  padding: 0 20px;
  overflow: auto;
`;

const StyledSlideoutPanelFooter = styled("div")`
  width: 100%;
  margin-bottom: 24px;
  padding: 0 20px 20px 20px;
  background: #ffffff;
  text-align: center;
`;
