import * as React from "react";
import {
  PaneledPageContext,
  PaneledPageContextState
} from "./PaneledPageContext";

// With the FiltersToggle, you can create custom components to toggle the filters panel open or closed,
// and can place them anywhere within the PaneledPage's structure, when it becomes necessary. Examples include: an
// 'Apply' button inside the filter panel's contents, or a link/button inside the PaneledPage's content area.
export interface FiltersToggleProps {
  /** Determines what event will actually toggle the filters panel. Default is "click"; i.e. clicking the FiltersToggle will toggle the filters panel. */
  toggleFiltersOnEvent: "click" | "hover";
}
export default class FiltersToggle extends React.PureComponent<
  FiltersToggleProps,
  any
> {
  handleClick = (event: any, pageContext: PaneledPageContextState) => {
    if (
      this.props.toggleFiltersOnEvent === "click" &&
      pageContext.onToggleFiltersPanel
    )
      pageContext.onToggleFiltersPanel(event);
  };

  handleMouseEnter = (event: any, pageContext: PaneledPageContextState) => {
    if (
      this.props.toggleFiltersOnEvent === "hover" &&
      pageContext.onToggleFiltersPanel
    )
      pageContext.onToggleFiltersPanel(event);
  };

  render() {
    return (
      <PaneledPageContext.Consumer>
        {(pageContext: PaneledPageContextState) => (
          <span
            className="filters-panel-toggle-listener"
            onClick={event => this.handleClick(event, pageContext)}
            onMouseDown={event => this.handleMouseEnter(event, pageContext)}
          >
            {this.props.children}
          </span>
        )}
      </PaneledPageContext.Consumer>
    );
  }
}
