import {
  Column,
  IntegratedSorting,
  Row,
  SortingState,
  SelectionState,
  IntegratedSelection
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableSelection,
  VirtualTable
} from "@devexpress/dx-react-grid-bootstrap3";
import React from "react";
import { TableActionsCell } from "./table-actions";
import { TableFileCell } from "./table-filecell";
import { FileMeta } from "../models";
import styled from "styled-components";

export interface SmartDriveGridProps {
  files: FileMeta[];
  selection?: string[];
  setSelection?: (ids: string[]) => any;
}
export const SmartDriveGrid = ({
  files,
  selection,
  setSelection
}: SmartDriveGridProps) => {
  return (
    <Grid
      getRowId={getRowId}
      rootComponent={Root}
      rows={files}
      columns={columns}
    >
      <SortingState
        defaultSorting={[{ columnName: "fileName", direction: "asc" }]}
      />
      <IntegratedSorting />
      <SelectionState
        selection={selection}
        // @ts-ignore
        onSelectionChange={setSelection}
      />
      <IntegratedSelection />
      <VirtualTable
        tableComponent={STable}
        height="auto"
        cellComponent={Cell}
        columnExtensions={[{ columnName: "actions", width: 300 }]}
      />
      {/* 
      // @ts-ignore */}
      <TableSelection
        highlightRow
        selectByRowClick
        // @ts-ignore
        rowComponent={StyledSelectionRow}
      />
    </Grid>
  );
};

// @ts-ignore
// https://github.com/DevExpress/devextreme-reactive/issues?utf8=%E2%9C%93&q=is%3Aissue+is%3Aopen+rowcomponent
export const StyledSelectionRow = styled<any>(TableSelection.Row)`
  cursor: pointer;

  &:hover,
  &:hover td {
    background-color: #eee !important;
  }

  &.active,
  &.active td {
    background-color: #e3f1f8 !important;
  }
`;

const columns: Column[] = [
  { name: "fileName", title: "Name" },
  { name: "actions", title: "" }
];

const STable = (props: any) => {
  return (
    <VirtualTable.Table {...props} style={{ borderCollapse: "collapse" }} />
  );
};

const getRowId = (row: Row) => row.id as string;
const Root = (props: Grid.RootProps) => <Grid.Root {...props} />;

const Cell = (props: Table.DataCellProps) => {
  const { column } = props;
  switch (column.name) {
    case "fileName":
      return <TableFileCell {...props} />;
    case "actions":
      return <TableActionsCell {...props} />;
    default:
      return <Table.Cell {...props} />;
  }
};
