import * as React from "react";
import styled from "styled-components";

export type SubTitleProps = {
  children: any;
};

const StyledSubTitle = styled.div`
  h3 {
    font-size: ${props =>
      props.theme.baseTheme === "Ace" ? "1.7rem" : "1.38rem"};
    color: ${props =>
      props.theme.baseTheme === "Ace" ? "#808080" : "#000000"};
  }
`;

export default class SubTitle extends React.Component<SubTitleProps> {
  render() {
    return (
      <StyledSubTitle>
        <div>
          <h3>{this.props.children}</h3>
        </div>
      </StyledSubTitle>
    );
  }
}
