import classNames from "classnames";
import * as React from "react";
import styled from "styled-components";

export type LoadingProps = {
  /** Any extra class to apply to the loader */
  className?: string;
  /** Is it loading? */
  isLoading: boolean;
  /** If you need to increase the size of the loading spinner, pass in a number to multiply the size by that number. ONLY works on whole numbers 2, 3, 4, 5. */
  size?: number;
};

class L extends React.Component<LoadingProps> {
  render() {
    return this.props.isLoading ? (
      <i
        className={classNames(
          this.props.className,
          "fa fa-spinner fa-spin",
          this.props.size ? `fa-${this.props.size}x` : undefined
        )}
      />
    ) : null;
  }
}

const StyledLoading = styled(L)`
  line-height: normal;
`;

export default function Loading(props: LoadingProps) {
  return <StyledLoading {...props} />;
} //HACK: Somehow this is not working so doing this for now: https://react-styleguidist.js.org/docs/thirdparties.html#styled-components
