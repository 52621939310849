import {
  GroupingState as DGroupingState,
  GroupingStateProps
} from "@devexpress/dx-react-grid";
import memoize from "memoize-one";
import * as React from "react";
import { DataColumnType, TypedDataColumn } from "../models";
import { useTableContext } from "../table-context";
import { Grouping } from "../models";

export const GroupingState = (props: GroupingStateProps) => {
  const {
    columns,
    grouping: { setGrouping, expandedGroups, setExpandedGroups },
    extendedGroups
  } = useTableContext();

  return React.useMemo(() => {
    // console.log("grouping");
    const filteredGrouping = filteredGroups(columns, extendedGroups);
    return (
      <DGroupingState
        grouping={filteredGrouping}
        onGroupingChange={newGrouping => {
          setGrouping(newGrouping);
        }}
        expandedGroups={expandedGroups}
        onExpandedGroupsChange={setExpandedGroups}
        columnExtensions={getGroupingStateExtensions(columns)}
        {...props}
      />
    );
  }, [
    columns,
    extendedGroups,
    setGrouping,
    expandedGroups,
    setExpandedGroups,
    props
  ]);
};

export const filteredGroups = memoize(
  (columns: TypedDataColumn[], grouping: Grouping[]) => {
    const columnNames = columns.map(column => column.name);
    return grouping.filter(group => columnNames.indexOf(group.columnName) > -1);
  }
);

export const getGroupingStateExtensions = memoize(
  (columns: TypedDataColumn[]): DGroupingState.ColumnExtension[] => {
    return columns
      .filter(
        column =>
          !column.groupingEnabled ||
          column.type === DataColumnType.Links ||
          column.type === DataColumnType.CheckList
      )
      .map(column => ({
        columnName: column.name,
        groupingEnabled: false
      }));
  }
);
