import * as React from "react";
import styled from "styled-components";

const ContainerArrow = styled.b.attrs(() => ({
  className: "fa fa-angle-down"
}))`
  display: block;
  width: 14px !important;
  height: 14px;
  line-height: 14px;
  text-shadow: none;
  font-size: 18px;
  position: absolute;
  right: 10px;
  top: 12px;
  padding: 0;
  text-align: center;
`;

export type ContainerMenuListProps = {
  children: any;
};

const StyledContainerMenuList = styled.ul`
  background-color: #ffffff;
  border-color: #e5e5e5;
  margin: 0;
  padding: 0;
  position: relative;
  display: "block";
  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    left: 18px;
    top: 0;
    bottom: 0;
    border: 1px dotted #9dbdd6;
    border-width: 0 0 0 1px;
  }
  & > li > a {
    background-color: #ffffff;
    color: #616161;
  }

  & li:not(:first-of-type) {
    border-top-color: #e4e4e4;
    border-top-style: dotted;
  }

  & li > & > li:not(.active) a:not(:hover) {
    color: #757575;
  }

  & li > & > li > a {
    margin-left: 5px;
  }
  & > li a > i {
    font-size: 12px;
    line-height: 17px;
    vertical-align: top;
  }

  & > li::before {
    content: "";
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 7px;
    left: 20px;
    top: 17px;
    border-top: 1px dotted #9dbdd6;
  }

  & > li & > li::before {
    display: none;
  }
`;

function ContainerMenuList({ children, ...props }: ContainerMenuListProps) {
  return (
    <StyledContainerMenuList {...props}>{children}</StyledContainerMenuList>
  );
}

export { ContainerArrow, ContainerMenuList };
