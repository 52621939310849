import {
  Plugin,
  Template,
  TemplateConnector,
  TemplatePlaceholder
} from "@devexpress/dx-react-core";
import { Dropdown, Icon, MenuItem } from "hcss-components";
import * as React from "react";
import styled from "styled-components";
import { useTableContext } from "../table-context";
import { ButtonContainer } from "./toolbar-button-container";

export interface OtherOptionsProps {
  onExpandAll: (groups: string[]) => any;
  onCollapseAll: () => any;
  messages?: {
    exportCsv?: string;
    exportPdf?: string;
    showHideColumns?: string;
    expandAll?: string;
    collapseAll?: string;
  };
  options?: {
    showCsvExport?: boolean;
    showPdfExport?: boolean;
    showColumnVisibility?: boolean;
    showExpandCollapse?: boolean;
  };
}

export const OtherOptions = ({
  onExpandAll,
  onCollapseAll,
  messages,
  options = {}
}: OtherOptionsProps) => {
  const {
    showCsvExport = true,
    showPdfExport = true,
    showColumnVisibility = true,
    showExpandCollapse = true
  } = options;

  const {
    grouping: { grouping },
    columnSelect: { setShowModal }
  } = useTableContext();
  return (
    <Plugin key="csv">
      <Template name="toolbarContent">
        <TemplatePlaceholder />

        <TemplateConnector>
          {(getters, actions) => {
            return (
              <ButtonContainer order={4}>
                <div data-testid="projectsmenu-options">
                  <Dropdown id="other-options" pullRight>
                    <Dropdown.Toggle bsStyle="link" noCaret>
                      <StyledIcon name="ellipsis-v" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {showCsvExport && (
                        <MenuItem
                          key="exportcsv"
                          eventKey={0}
                          onClick={() =>
                            actions.exportCSV({
                              tableColumns: getters.tableColumns,
                              exportRows: getters.allRows.detailRows,
                              getCellValue: getters.getCellValue
                            })
                          }
                        >
                          {messages?.exportCsv ?? "Export to CSV"}
                        </MenuItem>
                      )}
                      {showPdfExport && (
                        <MenuItem
                          key="exportpdf"
                          eventKey={1}
                          onClick={() =>
                            actions.exportPDF({
                              tableColumns: getters.tableColumns,
                              exportRows: getters.allRows.detailRows,
                              getCellValue: getters.getCellValue
                            })
                          }
                        >
                          {messages?.exportPdf ?? "Export to PDF"}
                        </MenuItem>
                      )}
                      {showColumnVisibility && (
                        <MenuItem
                          key="selectColumns"
                          eventKey={2}
                          onClick={() => setShowModal(true)}
                        >
                          {messages?.showHideColumns ?? "Edit Columns"}
                        </MenuItem>
                      )}
                      {/* <MenuItem
                        key="highlightproj"
                        eventKey={3}
                        onClick={() => setShowProjectsModal(true)}
                      >
                        Highlight Projects
                      </MenuItem> */}
                      {/* This is split into multiple conditionals because the MenuItem components will only hide correctly if they are direct children of Dropdown.Menu. */}
                      {showExpandCollapse && grouping.length > 0 && (
                        <MenuItem
                          key="expand"
                          eventKey={4}
                          onClick={() =>
                            actions.groupKeys({
                              allRows: getters.allRows,
                              grouping: getters.grouping,
                              onExpandAll: onExpandAll,
                              tableColumns: getters.tableColumns
                            })
                          }
                        >
                          {messages?.expandAll ?? "Expand All"}
                        </MenuItem>
                      )}
                      {showExpandCollapse && grouping.length > 0 && (
                        <MenuItem
                          key="collapse"
                          eventKey={5}
                          onClick={onCollapseAll}
                        >
                          {messages?.collapseAll ?? "Collapse All"}
                        </MenuItem>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </ButtonContainer>
            );
          }}
        </TemplateConnector>
      </Template>
    </Plugin>
  );
};

const StyledIcon = styled(Icon)`
  color: #333;
`;
