import * as React from "react";
import styled from "styled-components";
import { FormGroup, FormGroupProps } from "react-bootstrap";
import { InputFieldContext, InputFieldContextType } from "./Context";
import { Message } from "./Message";
import { Label } from "./Label";
import { TextField } from "./TextField";
import { ValidationStateType } from "./models";
import { Omit } from "../../typehelpers";

const StyledGroup = styled(FormGroup)`
  &.form-group {
    display: block;
  }

  /* &.has-success {
    .form-control,
    .form-control:focus {
      box-shadow: none;
    }

    .help-block {
      color: #006627;
    }
  } */

  /* &.has-error {
    .control-label {
      color: #a60000;
    }

    .form-control,
    .form-control:focus {
      background: #ffeff0;
      box-shadow: none;
    }

    .help-block {
      color: #a60000;
    }
  } */
`;

export interface InputProps
  extends Omit<FormGroupProps, "validationState">,
    ValidationStateType {}

export default class Input extends React.Component<InputProps> {
  render() {
    let { children, ref, ...props } = this.props;
    const value: InputFieldContextType = {
      validationState: props.validationState,
      required: props.required
    };

    return (
      <InputFieldContext.Provider value={value}>
        <StyledGroup {...(props as any)}>{children}</StyledGroup>
      </InputFieldContext.Provider>
    );
  }

  static Message = Message;
  static Label = Label;
  static TextField = TextField;
}
