import * as React from "react";
import styled from "styled-components";
import Dropdown from "./Dropdown";
import HelpMenu from "./HelpMenu";
import Link from "../../Link";
import Icon from "../../Icon";
import * as ConcreteColors from "../../../ConcreteColors";

export type NavbarProps = {
  className?: string;
  logoIcon?: any;
  productBrandLight: string;
  productBrandBold: string;
  homeTo?: string;
  hamburgerMenu?: any;
  helpMenu?: any;
  profileMenu?: any;
  renderHtmlAnchorForHomeTo?: boolean;
  navMenu?: any;
};

export default class Navbar extends React.Component<NavbarProps> {
  static defaultProps = {
    homeTo: "/",
    helpMenu: <HelpMenu />,
    logoIcon: <Icon name="hcss" />
  };
  render() {
    return (
      <Nav className="page-navbar">
        <NavLogoArea className="navbar-logo-area">
          {this.props.hamburgerMenu}
          <ProductLogo
            className="navbar-product-home-link"
            to={this.props.homeTo}
            renderHtmlAnchor={this.props.renderHtmlAnchorForHomeTo}
          >
            <div className="navbar-product-icon">{this.props.logoIcon}</div>
            <div className="navbar-product-name">
              {this.props.productBrandLight}{" "}
              <strong>{this.props.productBrandBold}</strong>
            </div>
          </ProductLogo>
        </NavLogoArea>
        <NavFeatureMenus className="navbar-feature-menus">
          <Dropdown.Group>
            {this.props.navMenu}
            {this.props.helpMenu}
            {this.props.profileMenu}
          </Dropdown.Group>
        </NavFeatureMenus>
      </Nav>
    );
  }
}

const Nav = styled.nav`
  display: flex; /* will ensure responsiveness based on screen size */
  flex-direction: row;
  flex-wrap: nowrap; /* will prevent the tools on the right from jumping down when the screen is made smaller */
  align-items: center; /* ensures that text, icons, and other content will appear centered along the same x-axis, in the middle */
  justify-content: space-between; /* ensures that app logo area stays at left, and menus and tools stay at right */
  height: 45px;

  background-color: white;
  border-bottom: 2px solid ${ConcreteColors.gray300};
  font-size: 14px;
  z-index: 1000;
`;

const NavLogoArea = styled("div")`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
`;

const NavFeatureMenus = styled("div")`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
`;

const ProductLogo = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  height: 100%;
  margin-left: 12px;
  font-family: "ProximaNova";
  font-size: 20px;

  &:hover,
  &:active {
    text-decoration: none;

    & > .navbar-product-name {
      color: ${props => props.theme.primaryColor};
      text-decoration: none !important;
    }
  }

  & > .navbar-product-icon,
  & > .navbar-product-name {
    display: inline-block;
    vertical-align: middle;
    transition: 0.2s;
  }

  & > .navbar-product-icon {
    color: ${props => props.theme.primaryColor};
    line-height: 0;
    margin: -1px 10px 0 0;

    & > i {
      font-size: 2.5rem;
    }
  }

  & > .navbar-product-name {
    color: ${ConcreteColors.gray700};
    font-family: "Inter", "ProximaNova", Arial, Helvetica, sans-serif;
    font-size: 1.7rem;
    margin: -1px 0 0 0;

    & > strong {
      font-weight: 800;
    }
  }
`;
