import classNames from "classnames";
import * as React from "react";
import styled from "styled-components";
import SidebarMenuList from "./SidebarMenuList";
import SidebarToggle from "./SidebarToggle";

export type SidebarProps = {
  /** @ignore */
  className?: string;
  /** @ignore */
  viewportWidth?: number;
  /** SidebarLinkMenuItem, SidebarContainerMenuItem */
  children: any;
  navbarHeight?: string;
  sidebarExpanded?: boolean;
  onToggle?: () => void;
};

export type SidebarState = {
  sidebarExpanded: boolean;
};

const Nav = styled.nav`
  margin-top: 2px;
  width: 189px;
  background-color: ${(props: any) => props.theme.sidebarColor};
  border-style: solid;
  border-color: #cccccc;
  border-width: 0 1px 0 0;
  line-height: normal;
  height: calc(100% - 2px);

  &.menu-min {
    width: 42px;

    > ul > li > a > span {
      display: none;
      position: absolute;
      height: 39px;
      line-height: 38px;
      background-color: #f5f5f5;
      z-index: 121;
      box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.2);
      padding-left: 12px;
      border: 1px solid #ccc;
      top: -1px;
      left: 42px;
      width: 173px;
      border-bottom: 1px solid #ddd;
    }

    > ul > li > a > span.container-menu-text {
      border: none;
      box-shadow: none;
      border-bottom: 1px solid #ddd;
    }

    > ul > li ul::before,
    > ul > li ul li::before,
    > ul > li > a > b,
    > ul > li ul i {
      display: none;
    }

    > ul > li > ul {
      background: #fff;
      position: absolute;
      z-index: 120;
      left: 41px;
      top: -2px;
      width: 175px;
      border: 1px solid #ccc;
      box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.2);
      padding-top: 38px;
      padding-bottom: 2px;
      display: none;
    }

    > ul > li > ul > li > a {
      padding: 7px 0 8px 24px;
    }

    > ul > li:hover > a > span,
    > ul > li:hover > ul {
      display: block;
    }
  }
`;

//Interesting logic to support existing pages layout scenario
export default class Sidebar extends React.Component<
  SidebarProps,
  SidebarState
> {
  static defaultProps = {
    viewportWidth: Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    ),
    navbarHeight: "47px"
  };
  constructor(props: SidebarProps) {
    super(props);
    this.state = {
      sidebarExpanded: props.sidebarExpanded ?? props.viewportWidth! > 1024
    };
  }

  handleToggle = () => {
    this.setState((prevState, props) => {
      var newState = !prevState.sidebarExpanded;
      return { sidebarExpanded: newState };
    });
  };

  render() {
    return (
      <Nav
        data-testid="sidebar"
        style={{ minHeight: `calc(100vh - ${this.props.navbarHeight})` }}
        className={
          this.props.sidebarExpanded !== undefined
            ? classNames({ "menu-min": !this.props.sidebarExpanded })
            : classNames({ "menu-min": !this.state.sidebarExpanded })
        }
      >
        <SidebarMenuList>{this.props.children}</SidebarMenuList>
        <SidebarToggle
          data-testid="sidebar-toggle"
          onToggle={
            this.props.sidebarExpanded !== undefined
              ? this.props.onToggle
              : this.handleToggle
          }
          sidebarExpanded={
            this.props.sidebarExpanded !== undefined
              ? this.props.sidebarExpanded
              : this.state.sidebarExpanded
          }
        />
      </Nav>
    );
  }
}
