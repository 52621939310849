import React, { ReactElement, FC } from "react";
import ReactToPrint from "react-to-print";
import { Button, Icon } from "hcss-components";
import { usePrintContext } from "./context";

type PrintButtonProps = {
  ButtonComponent?: ReactElement;
};

export const PrintButton: FC<PrintButtonProps> = ({ ButtonComponent }) => {
  const { setIsPrinting, contentRef } = usePrintContext();

  const getDomReadyForPrint = () => {
    // see comment in context.ts for why this is necessary
    return new Promise<void>((resolve, reject) => {
      try {
        setIsPrinting(true, resolve);
      } catch (err) {
        console.error(err);
        setIsPrinting(false);
        reject();
      }
    });
  };
  return (
    <ReactToPrint
      trigger={() => {
        if (ButtonComponent) return ButtonComponent;
        return (
          <Button hcssStyle="ThemeInverted" style={{ float: "right" }}>
            <Icon name="print" margin="right" />
            Print
          </Button>
        );
      }}
      content={() => contentRef.current}
      onBeforeGetContent={getDomReadyForPrint}
      onAfterPrint={() => setIsPrinting(false)}
    />
  );
};
