import {
  TypedDataColumn,
  DataColumnType,
  Grouping,
  BaseTypedDataColumn
} from "../models";
import { useMemo } from "react";
import { keyBy } from "lodash";
import moment from "moment";

export function useVirtualColumns(
  columns: TypedDataColumn[],
  grouping: Grouping[]
) {
  const columnLookup = useMemo(() => keyBy(columns, "name"), [columns]);

  const extendedGroups = useMemo(() => {
    return grouping
      .filter(g => g.columnName in columnLookup)
      .map(group => {
        const interval = group.config?.dateGrouping ?? "year";
        if (
          columnLookup[group.columnName]?.type === DataColumnType.DateTime ||
          columnLookup[group.columnName]?.type === DataColumnType.Date
        )
          return {
            columnName: `${group.columnName}|VIRTUAL|${interval}`,
            originalColumnName: group.columnName,
            isVirtual: true,
            config: { ...group.config, dateGrouping: interval }
          };

        return { ...group, isVirtual: false };
      });
  }, [grouping, columnLookup]);

  const virtualColumns = useMemo(
    () =>
      extendedGroups
        .filter(group => group.isVirtual)
        .map(
          (group): BaseTypedDataColumn<DataColumnType.DateTime> => {
            const originalColumn = columnLookup[group.originalColumnName!];
            return {
              ...originalColumn,
              type: DataColumnType.DateTime,
              name: group.columnName,
              id: group.columnName,
              config: group.config,
              getCellValue: (row: any) => {
                const value =
                  originalColumn!.getCellValue?.(row, originalColumn!.name) ??
                  row[originalColumn!.name];

                if (value) {
                  const dateValue = moment(value);
                  if (dateValue.isValid()) {
                    return moment(value).startOf(group.config?.dateGrouping);
                  }
                }
                return null;
              }
            };
          }
        ),

    [columnLookup, extendedGroups]
  );

  return { extendedGroups, virtualColumns };
}
