import * as React from "react";
import styled from "styled-components";
import classNames from "classnames";
import { ModalPanelHeader } from "./ModalPanelHeader";
import { ModalPanelBody } from "./ModalPanelBody";
import { Backdrop } from "../Backdrop";

const ModalPanelWrapper = styled.div`
  position: relative;
  margin-top: 10px;
  transition: 0.25s;
  background-color: white;

  &.open {
    & > .modal-panel-header {
      background-color: #fff;
      & > .fa.fa-chevron-down {
        transform: rotate(-180deg);
        padding-bottom: 2px;
      }
    }
    & > .modal-panel-body {
      overflow: visible;
      background: #fff;
      box-shadow: 0 16px 20px -12px #aaaaaa, 0 16px 20px -12px #aaaaaa;
      height: auto;
    }
  }
`;

export interface ModalPanelProps extends React.HTMLProps<HTMLDivElement> {
  /** Function that toggles the open prop. */
  onClose: () => void;
  /** Controls collapse and expansion on the ModalPanel. */
  open: boolean;
  /** A ref that points to the container that the ModalPanel is above. This is used to apply the backdrop. */
  contentContainerRef: React.RefObject<any>;
}

export class ModalPanel extends React.Component<ModalPanelProps> {
  render() {
    const {
      className,
      children,
      open,
      contentContainerRef,
      onClose,
      ref,
      ...other
    } = this.props;

    return (
      <ModalPanelWrapper
        className={classNames("modal-panel", className, { open })}
        {...(other as any)}
      >
        {this.props.children}
        {this.props.open && (
          <Backdrop elementRef={contentContainerRef} onClick={onClose} />
        )}
      </ModalPanelWrapper>
    );
  }

  static Header = ModalPanelHeader;
  static Body = ModalPanelBody;
}

export default ModalPanel;
