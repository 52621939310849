import * as React from "react";
import styled from "styled-components";
import {
  TabContainer,
  TabContent,
  Tab as BootstrapTab,
  TabPane
} from "react-bootstrap";

const StyledContainer = styled(TabContainer)`
  .nav-tabs {
    border-color: #c5d0dc;
    margin-left: 0;
    position: relative;
    top: 1px;
    li > a {
      border-radius: 0;
      border-color: #c5d0dc;
      background-color: #f9f9f9;
      margin-right: -1px;
      color: #999;
      padding: 7px 12px 8px;
      line-height: 18px;
      :hover,
      :focus {
        background-color: #fff;
        color: #4c8fbd;
      }
      :focus {
        outline: none;
      }
    }
    li.active > a,
    li.active > a:focus,
    li.active > a:hover {
      border-color: #c5d0dc #c5d0dc transparent;
      border-top: 2px solid #4c8fbd;
      box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.15);
      margin-top: -1px;
      z-index: 1;
      background-color: #fff;
    }
    li.dropdown.open > a {
      background-color: #4f99c6;
      border-color: #4f99c6;
      color: #fff;
    }
    li.dropdown.active a {
      cursor: pointer;
    }
    ul.dropdown-menu {
      border-radius: 0;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      li > a {
        padding: 1px 11px 4px;
        background-color: #fff;
        color: #333;
        margin: 0;
        :hover,
        :focus,
        :active {
          background-color: #6fb3e0;
          color: #fff;
        }
      }
      li.active > a {
        background-color: #6fb3e0;
        color: #fff;
        border: none;
        box-shadow: none;
        :hover {
          margin: 0;
        }
      }
    }
  }

  .tab-content {
    border: 1px solid #c5d0dc;
    padding: 16px 12px;
  }
`;

export interface TabProps extends BootstrapTab.TabProps {}

export default class Tab extends React.Component<TabProps> {
  render() {
    let { children, ...props } = this.props;
    return <BootstrapTab {...props}>{children}</BootstrapTab>;
  }
  static Container = StyledContainer;
  static Pane = TabPane;
  static Content = TabContent;
}

export { StyledContainer };
