import { useSessionStorage } from "hcss-hooks";

export function useSearch(templateName: string) {
  const [searchValue, setSearchValue] = useSessionStorage(
    `${templateName}.searchValue`,
    ""
  );

  return { searchValue, setSearchValue };
}
