import { useLocalStorage } from "hcss-hooks";
import { TypedDataColumn, SummaryType, DataColumnType } from "../models";
import { SummaryItem } from "@devexpress/dx-react-grid";
import { useCallback } from "react";

export function useSummary(
  templateName: string,
  options: { defaultSummary?: SummaryItem[] } = { defaultSummary: [] }
) {
  const [summaryItems, _setSummaryItems] = useLocalStorage<SummaryItem[]>(
    `${templateName}.summaryItems`,
    options.defaultSummary ?? []
  );

  const setSummaryItems = useCallback(
    (val: SummaryType, column: TypedDataColumn) => {
      _setSummaryItems((prevSummary: SummaryItem[]) => {
        const newSummary: SummaryItem[] = prevSummary.filter(
          c => c.columnName !== column.name
        );
        newSummary.push({ columnName: column.name, type: val });
        return newSummary;
      });
    },
    [_setSummaryItems]
  );

  const showOptionToggle = (column: TypedDataColumn) => {
    return (
      column.type === DataColumnType.Currency ||
      column.type === DataColumnType.Number
    );
  };

  return { summaryItems, setSummaryItems, showOptionToggle };
}
