import * as React from "react";
import { Collapse } from "react-bootstrap";
import styled from "styled-components";
import { WidgetContext } from "./Context";

export interface WidgetBodyProps extends React.HTMLProps<HTMLDivElement> {
  /** Make it look disabled - does not change functionality at this time */
  disabled?: boolean;
}

const Body = ({ className, ref, children, ...props }: WidgetBodyProps) => {
  const context = React.useContext(WidgetContext);
  if (context.open !== undefined)
    return (
      <Collapse in={context.open}>
        <WidgetBodyStyled className={className} {...(props as any)}>
          <WidgetMain>{children}</WidgetMain>
        </WidgetBodyStyled>
      </Collapse>
    );
  return (
    <WidgetBodyStyled className={className} {...(props as any)}>
      <WidgetMain>{children}</WidgetMain>
    </WidgetBodyStyled>
  );
};

export default Body;

const WidgetBodyStyled = styled.div<{ disabled?: boolean }>`
  color: ${props => (props.disabled ? "#707070" : "inherit")};
`;

const WidgetMain = styled.div`
  padding: 12px;
`;
