import * as React from "react";
import Dropdown from "./Dropdown";
import DropdownMenu from "./DropdownMenu";

export type ProfileMenuProps = {
  className?: string;
  /** MenuItem, Divider, or equivalent. */
  children: any;
  firstName: string;
  lastName: string;
  /** Depending on product this could be business unit or company. */
  subtext: string;
};

export default class ProfileMenu extends React.Component<ProfileMenuProps> {
  render() {
    return (
      <Dropdown>
        <Dropdown.ToggleButton>
          <Dropdown.Icon
            className="fa fa-user-profile"
            style={{ float: "left", padding: "0 15px 0 0", marginTop: "3px" }}
          />
          <div style={{ float: "left", padding: "0 15px 0 0" }}>
            <span
              style={{
                display: "inline-block",
                position: "relative",
                lineHeight: "16px",
                width: "100px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                fontSize: "14.6667px",
                verticalAlign: "middle"
              }}
            >
              {this.props.firstName}{" "}
              {this.props.lastName && this.props.lastName[0] + "."}
              <small
                style={{
                  display: "block",
                  fontSize: "80%",
                  color: "lightgrey",
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {this.props.subtext}
              </small>
            </span>
          </div>
          <i className="fa fa-caret-down" />
        </Dropdown.ToggleButton>
        <DropdownMenu right>{this.props.children}</DropdownMenu>
      </Dropdown>
    );
  }
}
