import {
  compose,
  space,
  SpaceProps,
  ColorProps,
  typography,
  TypographyProps,
  color,
  layout,
  LayoutProps,
  background,
  BackgroundProps,
  border,
  BorderProps,
  shadow,
  ShadowProps,
  position,
  PositionProps,
  flex,
  FlexProps,
  GridAreaProps,
  gridArea
} from "styled-system";
import styled from "styled-components";

type ColorPropsOverride = Omit<ColorProps, "color"> & { color?: string };

export type BoxProps = SpaceProps &
  ColorPropsOverride &
  TypographyProps &
  LayoutProps &
  BackgroundProps &
  BorderProps &
  ShadowProps &
  PositionProps &
  FlexProps &
  GridAreaProps;

export const boxStyles = compose(
  space,
  color,
  typography,
  layout,
  background,
  border,
  shadow,
  position,
  flex,
  gridArea
);

export const Box = styled.div<BoxProps>(boxStyles, {
  boxSizing: "border-box",
  minWidth: 0
});
