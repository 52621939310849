import { Action, Plugin } from "@devexpress/dx-react-core";
import { keyBy, values } from "lodash";
import * as React from "react";
import { getGroupingFunction } from "../grouping/integrated-grouping";
import { TypedDataColumn, Grouping } from "../models";

export function getGroupKeys(
  rows: any[],
  groups: Grouping[],
  columns: TypedDataColumn[]
) {
  const groupKeys: { [key: string]: string } = {};
  const columnsByName = keyBy(columns, "name");
  const validGroups = groups.filter(group => group.columnName in columnsByName);

  rows.forEach((row: any) => {
    const keys: string[] = validGroups.map(({ columnName }) => {
      const column = columnsByName[columnName];
      const value = column.getCellValue
        ? column.getCellValue(row, columnName)
        : row[columnName];
      const { key } = getGroupingFunction(column)(value);

      return key;
    });

    keys.forEach((key, keyIndex) => {
      const compoundKey = createCompoundKey(keys, keyIndex);
      groupKeys[compoundKey] = compoundKey;
    });
  });

  return values(groupKeys);
}

export interface GroupKeysProps {
  allRows: { detailRows: any[] };
  grouping: Grouping[];
  onExpandAll: any;
  tableColumns: any[];
}

export class GroupKeys extends React.PureComponent {
  expandAll = (props: GroupKeysProps) => {
    props.onExpandAll(
      getGroupKeys(
        props.allRows.detailRows,
        props.grouping,
        props.tableColumns.map(tableColumn => tableColumn.column)
      )
    );
  };
  render() {
    return (
      <Plugin>
        <Action name="groupKeys" action={this.expandAll} />
      </Plugin>
    );
  }
}

export const createCompoundKey = (keys: string[], index: number): string => {
  let key = "";
  for (let i = 0; i <= index; i++) {
    if (i > 0) {
      key += "|";
    }
    key += keys[i];
  }
  return key;
};
