import * as React from "react";
import classNames from "classnames";
import styled, { withTheme } from "styled-components";
import * as AceColors from "../../AceColors";
import { WidgetContext } from "./Context";
import * as ConcreteColors from "../../ConcreteColors";
import { Theme } from "../../themes";

export interface WidgetHeaderProps extends React.HTMLProps<HTMLDivElement> {
  /** Turn off the bottom border on the widget header */
  hideBorder?: boolean;
  /** Make the widget appear draggable - no dragging functionality yet */
  draggable?: boolean;
  theme?: Theme;
}

export const Header = ({
  className,
  ref,
  children,
  draggable,
  theme,
  ...props
}: WidgetHeaderProps) => {
  const context = React.useContext(WidgetContext);

  let noBorder = false;
  if (props.hideBorder === true) {
    noBorder = true;
  }
  if (!context.open) {
    noBorder = true;
  }

  return (
    <StyledHeader
      noBorder={noBorder}
      theme={theme}
      className={classNames(className, { draggable: draggable })}
      {...(props as any)}
    >
      {children}
    </StyledHeader>
  );
};

var ThemedHeader = withTheme(Header);

export default ThemedHeader;

const StyledHeader = styled.div<{ noBorder?: boolean }>`
  box-sizing: content-box;
  position: relative;
  height: 36px;
  color: ${props =>
    props.theme && props.theme.baseTheme === "Concrete"
      ? ConcreteColors.gray700
      : AceColors.gray800};
  border-bottom: ${props => (props.noBorder ? "0px" : "1px solid #ddd")};
  padding-left: 12px;

  &.draggable {
    cursor: move;
    cursor: grab;
    &:before {
      content: "\\e951";
      font-family: "FontAwesome";
      margin-right: 10px;
      width: 16px;
      display: inline-block;
    }
    &:hover {
      background-color: ${props =>
        props.theme !== undefined && props.theme.baseTheme === "Concrete"
          ? ConcreteColors.blue100
          : AceColors.gray100};
    }
  }
  &.draggable.active {
    cursor: grabbing;
  }
  &:before,
  &:after {
    content: "";
    display: table;
    line-height: 0;
  }

  &:after {
    clear: right;
  }
`;
