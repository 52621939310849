import * as React from "react";
import styled from "styled-components";
import SidebarContainerMenuItem from "./SidebarContainerMenuItem";
import { containerSingularOpenManager } from "../containerSingularOpenManager";

const StyledSidebarMenuList = styled.ul`
  margin: 0px;
  padding: 0;
  list-style: none;
  & > li {
    background-color: #ffffff;
  }
  & > li:last-child {
    border-bottom-width: 1px;
  }
  & > li:hover::before {
    display: block;
    position: absolute;
    top: -1px;
    left: 0;
    z-index: 1;
    height: 41px;
    width: 3px;
    max-width: 3px;
    overflow: hidden;
    background-color: ${props => props.theme.primaryColor};
    content: "";
  }
  & > li ul {
    line-height: 19.5px;
  }
  & > li ul > li > a {
    height: 100%;
    padding-left: 37px;
  }
  & > li ul > li ul > li {
    line-height: 18px;
  }
`;

export default class SidebarMenuList extends React.Component {
  constructor(props: any) {
    super(props);
    const children = props.children;
    if (!Array.isArray(children)) return;
    children.forEach(i => {
      if (i.type === SidebarContainerMenuItem)
        containerSingularOpenManager.addContainer(i.props.name);
    });
  }
  render() {
    return <StyledSidebarMenuList>{this.props.children}</StyledSidebarMenuList>;
  }
}
