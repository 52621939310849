import {
  VirtualTable as DVirtualTable,
  VirtualTableProps
} from "@devexpress/dx-react-grid-bootstrap3";
import * as React from "react";
import { generateTableColumnExtensions } from "./columns/column-extensions";
import { useTableContext } from "./table-context";

export const VirtualTable = (props: VirtualTableProps) => {
  const { columns } = useTableContext();

  return React.useMemo(
    () => (
      <DVirtualTable
        height="auto"
        estimatedRowHeight={60}
        columnExtensions={generateTableColumnExtensions(columns)}
        stubCellComponent={StubCellComponent}
        {...props}
      />
    ),
    [columns, props]
  );
};

const StubCellComponent = ({ children, ...other }: any) => {
  return (
    <DVirtualTable.StubCell
      {...other}
      colSpan={other.tableColumn.key !== "Symbol(flex)" ? 1 : 2}
    >
      {children}
    </DVirtualTable.StubCell>
  );
};
