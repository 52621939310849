import { Button, Icon, Modal } from "hcss-components";
import React from "react";
import { useSmartDriveContext } from "../smartdrive-context";

export const SmartDriveDeleteConfirmation = () => {
  const {
    api: { deleteFile },
    fileOperations: { fileToDelete, setFileToDelete },
    allSelectedRowsOperations: { selectedRowIds, setSelectedRowIds }
  } = useSmartDriveContext();

  const showModal = fileToDelete !== undefined;

  return (
    <Modal show={showModal} onHide={() => setFileToDelete(undefined)}>
      <Modal.Header closeButton>
        <Modal.Title>Delete File?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showModal &&
          `Are you sure you want to delete ${fileToDelete!.fileName}?`}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            if (fileToDelete) {
              deleteFile(fileToDelete.id)
                .then(() => {
                  const newSelectedRowIds = selectedRowIds.filter(
                    rowId => rowId !== fileToDelete.id
                  );
                  setSelectedRowIds(newSelectedRowIds);
                })
                .catch(err => {
                  console.error(err);
                });
              setFileToDelete(undefined);
            }
          }}
          hcssStyle="Delete"
        >
          <Icon name="trash-o" />
          &nbsp;Confirm Delete
        </Button>
        <Button
          hcssStyle="ThemeInverted"
          onClick={() => setFileToDelete(undefined)}
        >
          <Icon name="times" />
          &nbsp;Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
