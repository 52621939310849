import * as React from "react";
import styled from "styled-components";
import Dropdown from "./Dropdown";
import HelpMenu from "./HelpMenu";
import Link from "../Link";
export type NavbarProps = {
  className?: string;
  logoIcon?: any;
  productBrandLight: string;
  productBrandBold: string;
  homeTo?: string;
  hamburgerMenu?: any;
  helpMenu?: any;
  profileMenu?: any;
  renderHtmlAnchorForHomeTo?: boolean;
};

const Nav = styled.nav`
  font-size: 14px;
  background-color: ${(props) => props.theme.navbarColor};
  height: 45px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  z-index: 1000;

  & a,
  & a:hover {
    color: white;
    text-decoration: none;
  }
`;

const ProductLogo = styled(Link)`
  color: white;
  margin-left: 10px;
  text-decoration: none;
  font-family: "ProximaNova";
  font-size: 28px;
  line-height: normal;

  & > b {
    font-family: "ProximaNova Heavy";
  }

  :focus {
    color: white;
  }
`;

const NavbarHeader = styled.div`
  float: right;
  margin-right: 10px;
`;

const StyledLogoIcon = styled.i`
  color: white;
  font-size: 28px;
  padding: auto;
  margin-top: 20px;
  margin-right: 5px;
  line-height: normal;
`;

export default class Navbar extends React.Component<NavbarProps> {
  static defaultProps = { homeTo: "/", helpMenu: <HelpMenu /> };
  render() {
    return (
      <Nav>
        {this.props.hamburgerMenu}
        <ProductLogo
          to={this.props.homeTo}
          renderHtmlAnchor={this.props.renderHtmlAnchorForHomeTo}
        >
          <StyledLogoIcon>{this.props.logoIcon}</StyledLogoIcon>
          {this.props.productBrandLight} <b>{this.props.productBrandBold}</b>
        </ProductLogo>
        <NavbarHeader>
          <Dropdown.Group>
            {this.props.helpMenu}
            {this.props.profileMenu}
          </Dropdown.Group>
        </NavbarHeader>
      </Nav>
    );
  }
}
