import {
  TableColumnResizing as DTableColumnResizing,
  TableColumnResizingProps
} from "@devexpress/dx-react-grid-bootstrap3";
import * as React from "react";
import { useTableContext } from "../table-context";

export const TableColumnResizing = (
  props: Partial<TableColumnResizingProps>
) => {
  const {
    sizing: { columnWidths, setColumnWidths }
  } = useTableContext();

  return React.useMemo(() => {
    // console.log("sizing");
    return (
      <DTableColumnResizing
        columnWidths={columnWidths}
        onColumnWidthsChange={setColumnWidths}
        {...props}
      />
    );
  }, [columnWidths, setColumnWidths, props]);
};

export const ColumnResizing = TableColumnResizing;
