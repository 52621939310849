import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { deserializeValue, serializeValue } from "./serialization";

export function useLocalStorage<T>(
  key: string,
  initialValue: T
): [T, Dispatch<SetStateAction<T>>] {
  const [item, setItem] = useState<T>(() => {
    const value = deserializeValue(window.localStorage.getItem(key));
    if (value === null || value === undefined) {
      return initialValue;
    }
    return value;
  });

  useEffect(() => {
    const serializedItem = serializeValue(item);
    if (serializedItem) {
      window.localStorage.setItem(key, serializedItem);
    }
  }, [key, item]);

  return [item, setItem];
}
