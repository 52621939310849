import * as React from "react";
import styled from "styled-components";
import classNames from "classnames";

const ModalPanelBodyWrapper = styled.div`
  position: absolute;
  width: 100%;
  z-index: 50;
  height: 0;
  overflow: hidden;
  transition: 0.25s;
`;

export interface ModalPanelBodyProps extends React.HTMLProps<HTMLDivElement> {}

export const ModalPanelBody = (props: ModalPanelBodyProps) => {
  const { className, ref, ...other } = props;
  return (
    <ModalPanelBodyWrapper
      className={classNames("modal-panel-body", className)}
      {...(other as any)}
    >
      {props.children}
    </ModalPanelBodyWrapper>
  );
};

export default ModalPanelBody;
