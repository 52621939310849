import { useLocalStorage } from "hcss-hooks";
import { Grouping } from "../models";
import { useCallback } from "react";
import moment from "moment";
import { uniqBy } from "lodash";

export function useGrouping(
  templateName: string,
  options: {
    defaultGrouping?: Grouping[];
    defaultExpandedGroups?: string[];
  } = { defaultGrouping: [], defaultExpandedGroups: [] }
) {
  const [grouping, _setGrouping] = useLocalStorage<Grouping[]>(
    `${templateName}.grouping`,
    options.defaultGrouping || []
  );

  const setGrouping = useCallback(
    (newGrouping: Grouping[]) => {
      const mappedColumns = newGrouping.map(group => {
        if (group.isVirtual) {
          return {
            columnName: group.originalColumnName!,
            config: group.config
          };
        }

        // hack fix for working with DevExpress grid drag and drop group ordering
        const standardName = group.columnName.split("|");
        if (standardName?.[1] === "VIRTUAL") {
          return {
            columnName: standardName[0],
            config: {
              dateGrouping: standardName[2] as moment.unitOfTime.StartOf
            }
          };
        }

        return group;
      });

      const uniqueGroups = uniqBy(
        mappedColumns,
        group => `${group.columnName}|${group.config?.dateGrouping || "year"}`
      );
      _setGrouping(uniqueGroups);
    },
    [_setGrouping]
  );

  const [expandedGroups, setExpandedGroups] = useLocalStorage<string[]>(
    `${templateName}.grouping.expanded`,
    options.defaultExpandedGroups || []
  );
  return { grouping, setGrouping, expandedGroups, setExpandedGroups };
}
