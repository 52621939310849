import styled from "styled-components";
import Link from "../Link";

const SidebarMenuItemLink = styled(Link)`
  font-size: 13px;
  margin: 0;
  padding-left: 7px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: block;
  height: 39px;
  line-height: 20px;
  text-shadow: none;
  position: relative;

  &,
  &:visited,
  &:focus,
  &:active,
  &:hover {
    color: rgb(64, 64, 64);
    text-decoration: none;
  }
  &:hover {
    background-color: #ffffff;
    font-weight: 700;
    color: ${props => props.theme.primaryColor};
  }
`;

const SidebarMenuItemIcon = styled.i`
  font-size: 18px;
  display: inline-block;
  min-width: 30px;
  margin-right: 2px;
  vertical-align: sub;
  text-align: center;
  font-weight: 400;
`;

export { SidebarMenuItemLink, SidebarMenuItemIcon };
