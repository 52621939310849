import * as React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import styled, { css } from "styled-components";

export interface LinkProps extends React.HTMLProps<HTMLAnchorElement> {
  /** Where does it go? */
  to?: any;
  /** The style of link you want to use. Currently only a Delete style can be chosen apart from the default one. Requires an onClick to be passed in order to apply the style. */
  hcssStyle?: "Delete";
  /** Force rendering of regular anchor tag, instead of react router link. Using the to prop is preferred over setting this prop.  */
  renderHtmlAnchor?: boolean;
  testId?: string;
}

const LinkStyle = ({ hcssStyle }: LinkProps) => css`
  cursor: pointer;
  color: ${hcssStyle === "Delete" ? "#dd3214" : "#005eb8"};

  :hover,
  :focus {
    color: ${hcssStyle === "Delete" ? "#dd3214" : "#005eb8"};
  }
`;

const StyledLink = styled.a<LinkProps>`
  ${LinkStyle}
`;

const StyledReactRouterLink = styled(({ hcssStyle, ...rest }) => (
  <ReactRouterLink {...rest} />
))<LinkProps>`
  ${LinkStyle}
`;

const _defaultTestId = "Link";

export default class Link extends React.Component<LinkProps> {
  render() {
    const {
      to,
      children,
      href,
      ref,
      renderHtmlAnchor,
      testId,
      as,
      ...props
    } = this.props;
    if (to !== undefined && !renderHtmlAnchor) {
      return (
        <StyledReactRouterLink
          to={to}
          data-testid={testId ?? _defaultTestId}
          {...props}
        >
          {children}
        </StyledReactRouterLink>
      );
    }

    return (
      <StyledLink
        href={to ? to : href}
        data-testid={testId ?? _defaultTestId}
        {...props}
      >
        {children}
      </StyledLink>
    );
  }
}
