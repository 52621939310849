import * as React from "react";
import Body from "./WidgetBody";
import Header from "./WidgetHeader";
import Title from "./WidgetTitle";
import Tools from "./WidgetTools";
import styled from "styled-components";
import { WidgetContext } from "./Context";

export interface WidgetProps extends React.HTMLProps<HTMLDivElement> {
  /** Enables collapsing of the widget body. Requires toggle prop on Widget.Title component */
  collapsible?: boolean;
  /** If True Widget is expanded on initial render, only used with collapsible prop */
  open?: boolean;
  /** Do you want to style this widget as a card (with a drop shadow). Defaults to true.  Applies 'card' class from main.css */
  isCard?: boolean;
  /** Hide the outer border of the widget */
  hideBorder?: boolean;
  /** To define a background color, set this prop to a valid CSS color value */
  overrideBackgroundColor?: string;
  /** Make the widget appear disabled - limited right now to changing bg and font colors */
  disabled?: boolean;
}
export interface WidgetState {
  open: boolean;
  handleToggle: () => void;
}

export default class Widget extends React.Component<WidgetProps, WidgetState> {
  constructor(props: WidgetProps) {
    super(props);
    this.state = {
      open: props.open ? props.open : false,
      handleToggle: this.handleToggle
    };
  }

  public static defaultProps = {
    isCard: true,
    hideBorder: false
  };

  handleToggle = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    let {
      className,
      ref,
      children,
      collapsible,
      hideBorder,
      ...props
    } = this.props;
    let additionalClasses = "";
    if (this.props.isCard) {
      additionalClasses = " card ";
    }

    let noBorder = false;
    if (this.props.hideBorder) {
      noBorder = true;
    }

    let backgroundColor = "";
    if (this.props.disabled) {
      backgroundColor = "#f6f6f6";
    }
    if (this.props.overrideBackgroundColor) {
      backgroundColor = this.props.overrideBackgroundColor;
    }

    let disabled = false;
    if (this.props.disabled) {
      disabled = true;
    }

    const WidgetComponent = (
      <WidgetContainer
        backgroundColor={backgroundColor}
        className={additionalClasses + " " + this.props.className}
        disabled={disabled}
        {...(props as any)}
      >
        <WidgetBox hideBorder={noBorder}>{children}</WidgetBox>
      </WidgetContainer>
    );

    if (collapsible)
      return (
        <WidgetContext.Provider value={this.state}>
          {WidgetComponent}
        </WidgetContext.Provider>
      );
    else return <>{WidgetComponent}</>;
  }

  static Body = Body;
  static Header = Header;
  static Title = Title;
  static Tools = Tools;
}

const WidgetContainer = styled.div<{ backgroundColor?: string }>`
  background-color: ${props => props.backgroundColor || "#fff"};
`;

const WidgetBox = styled.div<{ hideBorder?: boolean }>`
  padding: 0;
  margin: 3px 0;
  border: ${props => (props.hideBorder ? "0px" : "1px solid #ccc")};
`;
