import * as React from "react";

export type DetailPageTitleProps = {
  children: any;
};

export default class DetailPageTitle extends React.Component<
  DetailPageTitleProps
> {
  static defaultProps = {
    title: "Details Page Title"
  };

  render() {
    return (
      <div className="details-header-container">
        <div id="search-container">
          <div id="search-browse-box">
            <button
              className="next-button button-disabled"
              id="next_button"
              /*onclick="search.getRecord(search.nextId)"*/ disabled
            >
              <i className="fa fa-chevron-right sixteenpx"></i>
            </button>
            <button
              className="previous-button button-disabled"
              id="previous_button"
              /*onclick="search.getRecord(search.prevId)"*/ disabled
            >
              <i className="fa fa-chevron-left sixteenpx"></i>
            </button>
            <div className="dropdown-panel-search">
              <div className="dropdown-panel-search-input-container">
                <div className="dropdown-panel-search-icon">
                  <div className="content">
                    <span></span>
                  </div>
                </div>
                <input
                  id="search_textbox"
                  className="dropdown-panel-search-input"
                  type="text"
                  placeholder="Search unregistered equipment..." /*maxlength="100" tabindex="-1" onkeyup="search.onSearchText(this)" onfocus="search.onFocusSearch()" onclick="search.onFocusSearch()" onkeypress="search.onEnterPress(event)"*/
                />
                <div
                  id="search-clear"
                  className="dropdown-panel-search-clear" /*onclick="search.onClearText()" style="display: none;"*/
                >
                  <i className="fa fa-times"></i>
                </div>
              </div>
            </div>
          </div>
          <div
            className="search-list-filter-container" /*onclick="search.onClickFilterIcon()" style="display: none;"*/
          >
            <div className="filter-text">
              Filter: <span id="filterType">Unregistered Equipment</span>
            </div>
            <div className="search-list-filter-icon search-list-advance-icon">
              <div className="content">
                <div className="valign">
                  <span></span>
                </div>
              </div>
            </div>
            <div className="search-list-filter-icon search-list-filter-icon-down">
              <div className="content">
                <div className="valign">
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <div /*style="position: absolute; right: 310px; top: 0;"*/>
            <table
              id="filter-equipment-table"
              className="e-dropdown-list-filter-option filter-group-advance-table" /*style="display: none;"*/
            >
              <tbody>
                <tr /*onclick="search.filterEquipmentType(1)"*/>
                  <td id="registered-equipment-selection"></td>
                  <td>
                    <div className="content">
                      <div className="valign">
                        <span>Registered</span>
                        <span> </span>
                        <span className="modal-filter-description"></span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr /*onclick="search.filterEquipmentType(2)"*/>
                  <td
                    id="unregistered-equipment-selection"
                    className="dropdown-list-filter-option-selected"
                  ></td>
                  <td>
                    <div className="content">
                      <div className="valign">
                        <span>Unregistered</span>
                        <span> </span>
                        <span className="modal-filter-description"></span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr /*onclick="search.filterEquipmentType(0)"*/>
                  <td id="all-equipment-selection"></td>
                  <td>
                    <div className="content">
                      <div className="valign">
                        <span>All</span>
                        <span> </span>
                        <span className="modal-filter-description"></span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            className="dropdown-panel-lists" /*style="display: none; height: 52px;"*/
          >
            <table id="tbl-search-list" className="search-table">
              <tbody className="v-scrollable">
                <tr
                  id="1227e823-09f6-44da-b767-c2cd0eba3ae4"
                  data-id="1227e823-09f6-44da-b767-c2cd0eba3ae4"
                  /*onclick="search.onListClick(this)"*/ data-isregistered="false"
                  data-code="APV-2"
                  data-description=""
                  data-type=""
                  className="hover-click"
                >
                  <td className="dropdown-list-item-cell text-cell">
                    <div className="content">
                      <div className="valign">
                        <div className="dropdown-list-item-code" title="APV-2">
                          APV-2
                        </div>
                        <div
                          className="dropdown-list-item-description equipment"
                          title=""
                        ></div>
                      </div>
                    </div>
                  </td>
                  <td className="dropdown-list-item-cell type-cell">
                    <div className="content">
                      <div className="valign">
                        <span
                          className="dropdown-list-item-description equipment"
                          title=""
                        ></span>
                      </div>
                    </div>
                  </td>
                  <td
                    id="LIST_1227e823-09f6-44da-b767-c2cd0eba3ae4"
                    className="dropdown-list-item-cell"
                    data-entity="Equipment"
                  ></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="page-header details-header">
          <h1>{this.props.children}</h1>
        </div>
      </div>
    );
  }
}
