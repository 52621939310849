/** This is exported as a default module for testing purposes.
 * We should expect to use this for level 1 menu containers only.
 * This is very hacky and best I can think of right now.
 */

export default class ContainerSingularOpenManager {
  sidebarContainerMenuItemToCloseMap: { [name: string]: any } = {};
  containers: string[] = [];

  addCloseCallback = (name: string, close: any) => {
    this.sidebarContainerMenuItemToCloseMap[name] = close;
  };

  addContainer = (name: string) => {
    this.containers.push(name);
  };

  handleOnOpen = (name: string) => {
    if (!this.containers.some(c => c === name)) return;

    this.containers
      .filter(n => n !== name)
      .forEach((n: string) => this.sidebarContainerMenuItemToCloseMap[n]());
  };
}

const containerSingularOpenManager = new ContainerSingularOpenManager();
export { containerSingularOpenManager };
