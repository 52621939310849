import * as React from "react";
import styled from "styled-components";
import classNames from "classnames";
import { Icon } from "../Icon";

const ModalPanelHeaderWrapper = styled.div`
  height: 41px;
  vertical-align: middle;
  transition: all 0.2s ease 0s;
  padding: 10px 16px;
  background-color: #d6d6d6;
  :hover {
    cursor: pointer;
  }

  & > .fa.fa-chevron-down {
    margin-right: 10px;
    margin-top: 2px;
    font-size: 12px;
    vertical-align: text-top;
  }
`;

export interface ModalPanelHeaderProps
  extends React.HTMLProps<HTMLDivElement> {}

export const ModalPanelHeader = (props: ModalPanelHeaderProps) => {
  const { className, children, ref, ...other } = props;
  return (
    <ModalPanelHeaderWrapper
      className={classNames("modal-panel-header", className)}
      {...(other as any)}
    >
      <Icon name="chevron-down" />
      {children}
    </ModalPanelHeaderWrapper>
  );
};

export default ModalPanelHeader;
