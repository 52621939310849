import * as ConcreteColors from "./ConcreteColors";

export type Theme = {
  baseTheme: "Ace" | "Concrete";
  primaryColor: string;
  navbarColor: string;
  navDropdownButtonColor: string;
  navDropdownButtonHoverColor: string;
  buttonColor?: string;
  pageColor: string;
  sidebarColor: string;
  sidebarItemColor: string;
};

const credentials: Theme = {
  baseTheme: "Ace",
  primaryColor: "#39453A",
  navbarColor: "#5f7361",
  navDropdownButtonColor: "#485749",
  navDropdownButtonHoverColor: "#39453A",
  buttonColor: "#5f7361",
  pageColor: "#ffffff",
  sidebarColor: "#f2f2f2",
  sidebarItemColor: "#f8f8f8"
};

const heavyBid: Theme = {
  baseTheme: "Ace",
  primaryColor: "#009639",
  navbarColor: "#009639",
  navDropdownButtonColor: "#009639",
  navDropdownButtonHoverColor: "#009639",
  buttonColor: "#009639",
  pageColor: "#ffffff",
  sidebarColor: "#f2f2f2",
  sidebarItemColor: "#f8f8f8"
};

const heavyJob: Theme = {
  baseTheme: "Ace",
  primaryColor: "#005eb8",
  navbarColor: "#005eb8",
  navDropdownButtonColor: "#4D8FCD",
  navDropdownButtonHoverColor: "#5996D1",
  buttonColor: "#005eb8",
  pageColor: "#ffffff",
  sidebarColor: "#f2f2f2",
  sidebarItemColor: "#f8f8f8"
};

const employeeApp: Theme = {
  baseTheme: "Ace",
  primaryColor: "#7B6469",
  navbarColor: "#7B6469",
  navDropdownButtonColor: "#a18b90",
  navDropdownButtonHoverColor: "#a18b90",
  buttonColor: "#7B6469",
  pageColor: "#ffffff",
  sidebarColor: "#f2f2f2",
  sidebarItemColor: "#f8f8f8"
};

const telematics: Theme = {
  baseTheme: "Ace",
  primaryColor: "#00b5e2",
  navbarColor: "#00b5e2",
  navDropdownButtonColor: "#009ac0",
  navDropdownButtonHoverColor: "#08a",
  pageColor: "#ffffff",
  sidebarColor: "#f2f2f2",
  sidebarItemColor: "#f8f8f8"
}; //Needs to be themed properly

const setups: Theme = {
  baseTheme: "Ace",
  primaryColor: "#005eb8",
  navbarColor: "#005eb8",
  navDropdownButtonColor: "#4D8FCD",
  navDropdownButtonHoverColor: "#5996D1",
  buttonColor: "#005eb8",
  pageColor: "#ffffff",
  sidebarColor: "#f2f2f2",
  sidebarItemColor: "#f8f8f8"
}; //Needs to be themed properly

const safety: Theme = {
  baseTheme: "Ace",
  primaryColor: "#ff9e1b",
  navbarColor: "#000000",
  navDropdownButtonColor: "#000000",
  navDropdownButtonHoverColor: "#000000",
  pageColor: "#f0f0f0",
  sidebarColor: "#ffffff",
  sidebarItemColor: "#ffffff"
}; //Needs to be themed properly

const skills: Theme = {
  baseTheme: "Ace",
  primaryColor: "#50748a",
  navbarColor: "#000000",
  navDropdownButtonColor: "#000000",
  navDropdownButtonHoverColor: "#000000",
  buttonColor: "#50748a",
  pageColor: "#ffffff",
  sidebarColor: "#f2f2f2",
  sidebarItemColor: "#f8f8f8"
}; //Needs to be themed properly

const forms: Theme = {
  baseTheme: "Ace",
  primaryColor: "#3f8ddf",
  navbarColor: "#000000",
  navDropdownButtonColor: "#000000",
  navDropdownButtonHoverColor: "#000000",
  buttonColor: "#3f8ddf",
  pageColor: "#ffffff",
  sidebarColor: "#f2f2f2",
  sidebarItemColor: "#f8f8f8"
}; //Needs to be themed properly

const eLogs: Theme = {
  baseTheme: "Ace",
  primaryColor: "#79993d",
  navbarColor: "#000000",
  navDropdownButtonColor: "#000000",
  navDropdownButtonHoverColor: "#000000",
  buttonColor: "#79993d",
  pageColor: "#ffffff",
  sidebarColor: "#f2f2f2",
  sidebarItemColor: "#f8f8f8"
}; //Needs to be themed properly

const community: Theme = {
  baseTheme: "Ace",
  primaryColor: "#0f9948",
  navbarColor: "#000000",
  navDropdownButtonColor: "#000000",
  navDropdownButtonHoverColor: "#000000",
  buttonColor: "#0f9948",
  pageColor: "#ffffff",
  sidebarColor: "#f2f2f2",
  sidebarItemColor: "#f8f8f8"
}; //Needs to be themed properly

const academy: Theme = {
  baseTheme: "Ace",
  primaryColor: "#009639",
  navbarColor: "#000000",
  navDropdownButtonColor: "#000000",
  navDropdownButtonHoverColor: "#000000",
  buttonColor: "#009639",
  pageColor: "#ffffff",
  sidebarColor: "#f2f2f2",
  sidebarItemColor: "#f8f8f8"
}; //Needs to be themed properly

const projectmanager: Theme = {
  baseTheme: "Concrete",
  primaryColor: "#005eb8",
  navbarColor: "#ffffff",
  navDropdownButtonColor: "#ffffff",
  navDropdownButtonHoverColor: "#f0f0f0",
  buttonColor: "#1e1e1e",
  pageColor: "#f0f0f0",
  sidebarColor: "#ffffff",
  sidebarItemColor: "#f0f0f0"
};

const preConstruction: Theme = {
  baseTheme: "Concrete",
  primaryColor: ConcreteColors.blue200,
  navbarColor: "#ffffff",
  navDropdownButtonColor: "#6e6e6e",
  navDropdownButtonHoverColor: "#cccccc",
  buttonColor: ConcreteColors.blue200,
  pageColor: "#f2f2f2",
  sidebarColor: "#ffffff",
  sidebarItemColor: "#f8f8f8"
};

const equipment360: Theme = {
  baseTheme: "Ace",
  primaryColor: "#007681",
  navbarColor: "#007681",
  navDropdownButtonColor: "#075f67",
  navDropdownButtonHoverColor: "#034248",
  buttonColor: "#007681",
  pageColor: "#f2f2f2",
  sidebarColor: "#ffffff",
  sidebarItemColor: "#f8f8f8"
};

const themes = {
  credentials,
  heavyJob,
  heavyBid,
  telematics,
  employeeApp,
  setups,
  safety,
  skills,
  forms,
  eLogs,
  community,
  academy,
  projectmanager,
  preConstruction,
  equipment360
};

export default themes;
