import React from "react";
import { ProgressTrackProps as ComponentProps } from "./Models";
import * as ConcreteColors from "../../../ConcreteColors";
import styled from "styled-components";

const ProgressTrack: React.FC<ComponentProps> = (props: ComponentProps) => {
  // Destructure props
  const { currentStep } = props;

  const calcCompletionTrackLength = (): number => {
    const numberMarkerWidth = 40;
    return (currentStep + 1) * numberMarkerWidth + currentStep * 80;
  };

  //
  //
  //
  return (
    <TrackContainer
      className="wizard-progress-track-line"
      data-testid="progress-track-line"
    >
      <CompletionTrack style={{ width: calcCompletionTrackLength() }} />
    </TrackContainer>
  );
};
export default ProgressTrack;

// Styled components
const TrackContainer = styled.div`
  position: absolute;
  height: 2px;
  width: calc(100% - 80px);
  margin-top: 36px;
  margin-left: 40px;

  background: ${ConcreteColors.gray300};
  transform: translateZ(0);
  transition: 0.3s linear;
`;

const CompletionTrack = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;

  background: ${ConcreteColors.gray400};
  transform: translateZ(0);
  transition: 0.3s linear;
`;
