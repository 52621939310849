import * as React from "react";
import styled from "styled-components";
import Dropdown from "./Dropdown";
import * as ConcreteColors from "../../../ConcreteColors";

export type HamburgerMenuProps = {
  children: any;
};

const HamburgerDropdown = styled(Dropdown)``;

const HamburgerDropdownButton = styled(Dropdown.ToggleButton)`
  &:hover i {
    color: ${ConcreteColors.blue200};
  }

  &.dropdown-active i {
    color: white;
  }
`;

const HamburgerMenuDropdownIcon = styled(Dropdown.Icon)`
  color: ${ConcreteColors.gray400};
  font-size: 2rem;
  margin-top: -3px;
`;

const HamburgerDropdownMenu = styled.ul`
  position: absolute;
  top: 40px;
  padding: 16px 0;
  list-style: none;
  background-color: white;
  border: none;
  border-radius: 0;
  box-shadow: 0 6px 8px -6px ${ConcreteColors.gray500};
  z-index: 2;

  & > li p {
    white-space: normal;
  }

  & > li:hover > a {
    background: unset;
    color: unset;
  }

  display: ${(props: any) => (props.isOpen ? "block" : "none")};
`;

export default class HamburgerMenu extends React.Component<
  HamburgerMenuProps,
  {}
> {
  render() {
    return (
      <Dropdown.Group className="dropdown-group">
        <HamburgerDropdown className="hamburger-dropdown">
          <HamburgerDropdownButton className="hamburger-dropdown-button">
            <HamburgerMenuDropdownIcon className="fa fa-bars" />
          </HamburgerDropdownButton>
          <HamburgerDropdownMenu
            id="dropdownList"
            className="user-menu dropdown-menu-left dropdown-menu"
          >
            {this.props.children}
          </HamburgerDropdownMenu>
        </HamburgerDropdown>
      </Dropdown.Group>
    );
  }
}
