import createUseContext from "constate";
import React from "react";
import { FileMeta, SmartDriveOptions, SDError } from "./models";
import { useSmartDriveApi } from "./use-smartdrive-api";
import { useUppy } from "./use-uppy";

export const useSmartDriveContext = createUseContext(
  ({
    appId,
    root,
    url,
    isInternal,
    isShareable,
    isReadOnlyUser,
    getToken,
    onFilesChanged,
    onLinkCopied,
    onError
  }: SmartDriveOptions) => {
    const token = getToken();
    const api = useSmartDriveApi({
      appId,
      root,
      token,
      url,
      isInternal,
      isShareable,
      isReadOnlyUser,
      onError
    });

    const { files, dispatch } = api;

    React.useEffect(() => {
      onFilesChanged && onFilesChanged(files);
    }, [files, onFilesChanged]);

    const uppySuccess = React.useCallback(
      (success: FileMeta[]) =>
        dispatch({ type: "updateFiles", payload: success }),
      [dispatch]
    );

    const handleUpplyUploadComplete = React.useCallback(
      (successful: FileMeta[], failed: SDError) => {
        uppySuccess(successful);

        if (failed) {
          onError!(failed);
        }
      },
      [onError, uppySuccess]
    );

    const uppy = useUppy({
      token,
      endpoint: api.uploadUrl,
      currentFiles: api.files,
      onUploadComplete: handleUpplyUploadComplete
    });

    const [fileToDelete, setFileToDelete] = React.useState<
      FileMeta | undefined
    >();

    const [selectedRowIds, setSelectedRowIds] = React.useState<string[]>([]);

    const [modalShowUp, setModalShowUp] = React.useState<boolean>();

    return {
      api,
      isReadOnlyUser,
      uppy,
      actions: {
        onLinkCopied
      },
      fileOperations: {
        fileToDelete,
        setFileToDelete
      },
      allSelectedRowsOperations: {
        selectedRowIds,
        setSelectedRowIds
      },
      delModalOperations: {
        modalShowUp,
        setModalShowUp
      }
    };
  }
);

export const SmartDriveContext = useSmartDriveContext;
