import * as React from "react";
import { HelpBlock } from "react-bootstrap";
import Icon, { IconProps } from "../Icon";
import { InputFieldContext } from "./Context";
import styled from "styled-components";
import * as AceColors from "../../AceColors";
import classNames from "classnames";
import { ValidationStateType } from "./models";

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 4px;

  > .help-block {
    margin: 1px 0 0 0;
    font-size: 12px;
    color: ${AceColors.gray700};
    &[data-message-shown="true"] {
      display: block;
    }
    &[data-message-shown="false"] {
      display: none;
    }
  }

  > .fa {
    padding-right: 6px;
    font-size: 14px;
    padding-bottom: 2px;
  }
  > .fa-exclamation-circle {
    color: ${AceColors.errorRed};
  }
  > .fa-check-circle {
    color: ${AceColors.successGreen};
  }

  &.has-error > .help-block {
    color: ${AceColors.errorRed};
  }
`;

export interface MessageProps
  extends React.HTMLProps<HTMLDivElement>,
    ValidationStateType {}

export const Message = ({ children, className, ...props }: MessageProps) => {
  const context = React.useContext(InputFieldContext);

  let feedback: null | React.ReactElement<IconProps> = null;
  if (context.validationState === "error")
    feedback = <Icon name="exclamation-circle" />;
  else if (context.validationState === "success")
    feedback = <Icon name="check-circle" />;

  const classes = classNames("form-feedback", className, {
    "has-error":
      context.validationState === "error" || props.validationState === "error",
    "has-success":
      context.validationState === "success" ||
      props.validationState === "success"
  });

  return (
    <MessageWrapper className={classes} {...(props as any)}>
      {feedback}
      <HelpBlock>{children}</HelpBlock>
    </MessageWrapper>
  );
};
