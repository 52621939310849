import { Grid } from "@devexpress/dx-react-grid-bootstrap3";
import * as React from "react";
import styled from "styled-components";
import { DataColumnType, TypedDataColumn } from "./models";

interface FilteredColumns {
  numberColumns: TypedDataColumn[];
  textColumns: TypedDataColumn[];
  dateColumns: TypedDataColumn[];
  booleanColumns: TypedDataColumn[];
}

export function filterColumns(columns: TypedDataColumn[]) {
  const filteredColumns: FilteredColumns = {
    numberColumns: [],
    textColumns: [],
    dateColumns: [],
    booleanColumns: []
  };

  columns.forEach(column => {
    if (
      column.type === DataColumnType.ShortText ||
      column.type === DataColumnType.LongText
    ) {
      filteredColumns.textColumns.push(column);
    }
    if (column.type === DataColumnType.Boolean) {
      filteredColumns.booleanColumns.push(column);
    }
    if (
      column.type === DataColumnType.Date ||
      column.type === DataColumnType.DateTime
    ) {
      filteredColumns.dateColumns.push(column);
    }
    if (
      column.type === DataColumnType.Number ||
      column.type === DataColumnType.Currency
    ) {
      filteredColumns.numberColumns.push(column);
    }
  });

  return filteredColumns;
}

export const getRowId = (row: any) => row.id;

export const Root: React.FunctionComponent<Grid.RootProps> = props => (
  <Grid.Root style={{ height: "100%", width: "100%" }} {...props} />
);

export const TableWrapper = styled.div<{ height?: string }>`
  height: ${props => props.height || "calc(100vh - 250px)"};
  td,
  th {
    background-color: #fff !important;
  }
  th {
    border-right: none !important;
  }
`;
