import { Sorting } from "@devexpress/dx-react-grid";
import { find } from "lodash";
import { DataColumnType } from "../models";
import { useTableContext } from "../table-context";

export function useContextMenu() {
  const {
    columnLookup,
    visibility: { hiddenColumns, setHiddenColumns },
    grouping: { grouping, setGrouping },
    sorting: { sorting, setSorting },
    columnSelect: { setShowModal },
    coloring: { setColoringColumnName, coloringColumnName },
    columns
  } = useTableContext();

  const handleHide = (e: any, passed: any) => {
    const newHiddenColumns = [...hiddenColumns];
    if (newHiddenColumns.indexOf(passed.name) === -1) {
      newHiddenColumns.push(passed.name);
      setHiddenColumns(newHiddenColumns);
    }
  };
  const handleGroup = (e: any, passed: any, config?: any) => {
    const newGrouping = [...grouping];
    newGrouping.push({
      columnName: passed.name,
      config: config?.dateGrouping ? config : undefined
    });

    setGrouping(newGrouping);
  };

  const nextSortingDescription = (columnName: string) => {
    const sortingValue = nextSortingValue(columnName);
    if (sortingValue === "asc") {
      return "Ascending";
    }
    return "Descending";
  };

  function nextSortingValue(columnName: string) {
    const existingSorting = find(sorting, s => s.columnName === columnName);
    if (existingSorting && existingSorting.direction === "asc") {
      return "desc";
    }
    return "asc";
  }

  const handleSort = (e: any, passed: any) => {
    const newSorting: Sorting = {
      columnName: passed.name,
      direction: nextSortingValue(passed.name)
    };
    setSorting([newSorting]);
  };

  const handleSelectColumns = () => {
    setShowModal(true);
  };

  const groupingEnabled = (name: string) => {
    const column = columnLookup[name];
    if (!column) {
      return false;
    }

    if (!columnLookup[name]?.groupingEnabled) {
      return false;
    }

    if (column.type === DataColumnType.Links) {
      return false;
    }

    if (column.type === DataColumnType.CheckList) {
      return false;
    }

    // don't allow grouping if it's the last visible column in the table
    if (columns.length - hiddenColumns.length - grouping.length <= 1) {
      return false;
    }

    return true;
  };

  const handleHighlight = (e: any, passed: any) => {
    isHighlightedColumn(passed.name)
      ? setColoringColumnName("")
      : setColoringColumnName(passed.name);
  };

  const isHighlightedColumn = (columnName: string) => {
    return columnName === coloringColumnName;
  };

  const getColumn = (columnName: string) => {
    return columnLookup[columnName];
  };

  return {
    handleHide,
    handleSort,
    handleGroup,
    handleSelectColumns,
    handleHighlight,
    isHighlightedColumn,
    getColumn,
    groupingEnabled,
    nextSortingValue,
    nextSortingDescription
  };
}
