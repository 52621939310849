import classNames from "classnames";
import * as React from "react";
import SidebarMenuItem from "./SidebarMenuItem";
import {
  SidebarMenuItemIcon,
  SidebarMenuItemLink
} from "./SidebarMenuItemComponents";

export type SidebarLinkMenuItemProps = {
  /** name of the menu item */
  children: any;
  to: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  toAlternatives?: string[];
  className?: string;
  iconClassName?: string;
  /** force rendering of html anchor tag, instead of react-router-link */
  renderHtmlAnchor?: boolean;
  /** @ignore */
  windowLocationPathname?: string;
};

export function isSidebarLinkMenuItemActive(
  to: string,
  toAlternatives: string[] | undefined,
  windowLocationPathname = window.location.pathname
) {
  return (
    windowLocationPathname.toLowerCase() === to.toLowerCase() ||
    (toAlternatives &&
      toAlternatives.some(t =>
        windowLocationPathname.toLowerCase().startsWith(t.toLowerCase())
      ))
  );
}
export default function SidebarLinkMenuItem({
  children,
  to,
  toAlternatives,
  iconClassName,
  className,
  onClick,
  renderHtmlAnchor,
  windowLocationPathname = window.location.pathname
}: SidebarLinkMenuItemProps) {
  return (
    <SidebarMenuItem
      className={classNames({
        active: isSidebarLinkMenuItemActive(
          to,
          toAlternatives,
          windowLocationPathname
        )
      })}
    >
      <SidebarMenuItemLink
        onClick={onClick}
        className={className}
        to={to as any}
        renderHtmlAnchor={renderHtmlAnchor}
      >
        {iconClassName && <SidebarMenuItemIcon className={iconClassName} />}
        <span>{children}</span>
      </SidebarMenuItemLink>
    </SidebarMenuItem>
  );
}
