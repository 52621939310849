import {
  IntegratedSummary as DIntegratedSummary,
  IntegratedSummaryProps
} from "@devexpress/dx-react-grid";
import { SummaryType } from "../models";
import * as React from "react";

export const IntegratedSummary = React.memo((props: IntegratedSummaryProps) => (
  <DIntegratedSummary calculator={customCalculator} {...props} />
));

export const SummaryCalculator = IntegratedSummary;

export const customCalculator = (type: string, rows: any[], getValue: any) => {
  if (type === SummaryType.Sum) {
    if (!rows.length) {
      return null;
    }
    const total = rows.reduce((sum, row) => {
      const value = getValue(row) || 0;
      return sum + value;
    }, 0);
    return total;
  }

  if (type === SummaryType.Avg) {
    if (!rows.length) {
      return null;
    }
    const nonNullRows = rows.filter(row => {
      const value = getValue(row);
      return value !== undefined && value !== null;
    });

    return (
      nonNullRows.reduce((sum, row) => sum + getValue(row), 0) /
      nonNullRows.length
    );
  }

  return DIntegratedSummary.defaultCalculator(type, rows, getValue);
};
