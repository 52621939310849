import { Button, ConcreteColors, Icon } from "hcss-components";
import React, { FC } from "react";
import styled from "styled-components";

export interface TablePlaceholderProps {
  name: string;
  isReadOnlyUser?: boolean;
  onClick: () => any;
}

export const TablePlaceholder: FC<TablePlaceholderProps> = ({
  name,
  isReadOnlyUser,
  onClick
}) => {
  return (
    <Placeholder>
      <Button onClick={onClick} disabled={isReadOnlyUser}>
        {!isReadOnlyUser && <Icon name="plus" style={{ marginRight: 5 }} />}
        {name}
      </Button>
    </Placeholder>
  );
};

const Placeholder = styled.div`
  text-align: center;
  padding: 40px 0px;
  border-radius: 5px;
  background-color: ${ConcreteColors.gray300};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
