import * as React from "react";
// @ts-ignore
import { AlertList } from "react-bs-notifier";
import { Button } from "./Button";
import { createGlobalStyle, withTheme } from "styled-components";
import { Theme } from "../themes";
import * as ConcreteColors from "../ConcreteColors";

export type NotificationState = {
  alerts: any[];
};

export type NotificationProps = {
  /** Optional, automatically provided by your site theme  */
  theme?: Theme;
};

/** Expect single instance of Notification */
/** @ignore */
export class Notification extends React.Component<
  NotificationProps,
  NotificationState
> {
  readonly state = { alerts: [] };
  static Instance: Notification;

  constructor(props: NotificationProps) {
    super(props);
    Notification.Instance = this;
  }

  onAlertDismissed = (alert: any) => {
    this.setState(prevState => {
      return {
        alerts: prevState.alerts.filter(a => a !== alert)
      };
    });
  };

  formatAlertMessage = (
    alertId: number,
    message?: React.ReactChild
  ): React.ReactChild => {
    const handleDismiss = () => {
      const currentAlert: any = this.state.alerts.find(
        (a: any) => a.id === alertId
      );
      this.onAlertDismissed(currentAlert);
    };

    const renderMessageBody = () => (
      <div className="message-body-wrapper">{message}</div>
    );

    const renderMessageFooter = () => {
      if (!!this.props.theme && this.props.theme.baseTheme === "Concrete") {
        return (
          <div className="message-footer-wrapper">
            <Button
              className="alert-dismiss-button alert-action-button"
              onClick={handleDismiss}
              title="Dismiss"
            >
              Dismiss
            </Button>
          </div>
        );
      }
      return null;
    };

    return (
      <div className="message-container">
        {!!message && renderMessageBody()}
        {renderMessageFooter()}
      </div>
    );
  };

  notify = (
    type: "success" | "info" | "warning" | "danger",
    headline: string,
    message: React.ReactChild = "",
    timeout: number
  ) => {
    this.setState(prevState => {
      const newAlertId = new Date().getTime();
      return {
        alerts: [
          ...prevState.alerts,
          {
            id: newAlertId,
            type,
            headline,
            message: this.formatAlertMessage(newAlertId, message),
            timeout
          }
        ]
      };
    });
  };

  renderConcreteStyleOverrides = () => {
    if (!!this.props.theme && this.props.theme.baseTheme === "Concrete")
      return <ConcreteStyles />;
    return null;
  };

  render() {
    return (
      <>
        {this.renderConcreteStyleOverrides()}
        <AlertList
          alerts={this.state.alerts}
          onDismiss={this.onAlertDismissed}
        />
      </>
    );
  }
}

export function notify(
  type: "success" | "info" | "warning" | "danger",
  headline: string,
  message?: React.ReactChild,
  timeout: number = 5000
) {
  Notification.Instance.notify(type, headline, message, timeout);
}

const ConcreteStyles = createGlobalStyle`
  div[class^="AlertContainer-container"] {
    top: 50px;
    z-index: 1051;
  }

  div.alert {
    position: relative !important;
    padding: 20px 24px 16px 24px;
    min-width: 300px;
    max-width: 400px;
    font-family: Inter, Arial, sans-serif;
  }

  div.alert-success,
  div.alert-info,
  div.alert-warning,
  div.alert-danger {
    background-color: ${ConcreteColors.gray700};
    color: white;
    border: 0px solid white;
    border-left-width: 40px;
    box-shadow: 0 2px 8px -2px ${ConcreteColors.gray500};
    border-radius: 4px;
  }

  .alert:before {
    position: absolute;
    font-size: 20px;
  }

  div.alert-success h4,
  div.alert-info h4,
  div.alert-warning h4,
  div.alert-danger h4 {
    font-weight: 600;
    margin-bottom: 10px;
  }
  div.alert-success {
    border-color: ${ConcreteColors.green200};
  }

  div.alert-info {
    border-color: ${ConcreteColors.blue200};
  }
  div.alert-warning {
    border-color: #f67e17;
  }
  div.alert-danger {
    border-color: ${ConcreteColors.red200};
  }
  div.alert-success i,
  div.alert-info i,
  div.alert-warning i,
  div.alert-danger i {
    display: none;
  }

  div.alert-success:before,
  div.alert-info:before,
  div.alert-warning:before,
  div.alert-danger:before {
    font-family: "FontAwesome";
    display: block;
    width: 10px;
    height: 0px;
  }

  div.alert-success:before {
    content: "\f00c";
    top: 13px;
    left: -30px;
  }
  div.alert-info:before {
    content: "\f129";
    top: 14px;
    left: -23px;
  }
  div.alert-warning:before {
    content: "\f071";
    top: 14px;
    left: -30px;
  }
  div.alert-danger:before {
    content: "\f12a";
    top: 14px;
    left: -23px;
  }

  .alert-dismissable > button[class*="close"] {
    display: none !important;
  }

  .alert > div[class*="msgContainer"] {
    width: 100%;
  }
  .alert > div[class*="msgContainer"] > h4[class*="headline"] {
    font-size: 1.8rem;
    color: #ffffff;
  }
  .alert > div[class*="msgContainer"] > div[class*="body"] {
    font-size: 1.4rem;
    white-space: normal;
    word-break: break-all;
    overflow: hidden;
    max-width: unset;
    color: ${ConcreteColors.gray400};
  }

  .alert .message-container {
    & > .message-body-wrapper {
      margin-bottom: 20px;
    }
    & > .message-footer-wrapper {
      & > .alert-action-button {
        padding: 4px 0;
        margin-right: 8px;

        border-width: 0;
        font-size: 1.3rem;
        font-weight: 600;
        text-transform: uppercase;
        color: #ffffff;

        opacity: 1;
        outline: none;

        &,
        &:active,
        &:active:focus {
          outline: none;
        }

        &:active,
        &:active:focus {
          color: ${ConcreteColors.gray400};
        }
      }
    }
  }
`;

var ThemedNotification = withTheme(Notification);
export default ThemedNotification;
