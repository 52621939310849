import { Table } from "@devexpress/dx-react-grid-bootstrap3";
import { Icon } from "hcss-components";
import React from "react";
import styled from "styled-components";

export const getSizeFormat = (bytes: number) => {
  let suffix: string[] = [];
  suffix = ["B", "KB", "MB", "GB"];

  let index = 0;
  do {
    bytes /= 1024;
    index++;
  } while (bytes >= 1024);

  return `${bytes.toFixed(2)} ${suffix[index]}`;
};

const mimeIconMap: any = {
  // Media
  image: "file-image-o",
  audio: "file-audio-o",
  video: "file-video-o",

  // Documents
  "application/pdf": "file-pdf-o",
  "application/msword": "file-word-o",
  "application/vnd.ms-word": "file-word-o",
  "application/vnd.oasis.opendocument.text": "file-word-o",
  "application/vnd.openxmlformats-officedocument.wordprocessingml":
    "file-word-o",
  "application/vnd.ms-excel": "file-excel-o",
  "application/vnd.openxmlformats-officedocument.spreadsheetml": "file-excel-o",
  "application/vnd.oasis.opendocument.spreadsheet": "file-excel-o",
  "application/vnd.ms-powerpoint": "file-powerpoint-o",
  "application/vnd.openxmlformats-officedocument.presentationml":
    "file-powerpoint-o",
  "application/vnd.oasis.opendocument.presentation": "file-powerpoint-o",
  "text/plain": "file-text-o",
  "text/html": "file-code-o",
  "application/json": "file-code-o",
  "application/xml": "file-code-o",
  "application/octet-stream": "file-text-o",

  // Archives
  "application/gzip": "file-archive-o",
  "application/zip": "file-archive-o",
  "application/x-zip-compressed": "file-archive-o"
};

const getIconName = (mimetype: string) => {
  if (mimetype in mimeIconMap) {
    return mimeIconMap[mimetype];
  }
  return "file-o";
};

const FileIcon = styled(Icon)`
  font-size: 2em;
`;

export const TableFileCell = ({ value, row }: Table.DataCellProps) => {
  return (
    <td>
      <FileCell>
        <TableFlex style={{ gridArea: "icon" }}>
          <FileIcon name={getIconName(row.mime)} />
        </TableFlex>
        <TableFlex
          style={{ gridArea: "fileInfo", justifyContent: "flex-start" }}
        >
          <div className="details">{value}</div>
          <div>
            <span className="size">Size: {getSizeFormat(row.size)}</span>
            <span className="date">
              Uploaded:{" "}
              {new Date(row.modified || row.created).toLocaleDateString()}
            </span>
          </div>
        </TableFlex>
      </FileCell>
    </td>
  );
};

const FileCell = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 30px auto;
  grid-template-areas: "icon fileInfo";
  grid-gap: 12px;
  padding: 4px;

  .details {
    width: 100%;
  }

  .size,
  .date {
    margin-right: 5px;
    display: inline-block;
    font-size: 0.9em;
    color: #404040;
  }

  .size {
    width: 8em;
  }

  .date {
    width: 10em;
  }
`;

const TableFlex = styled.div`
  display: flex;
  grid-area: area;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;
