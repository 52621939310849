import * as React from "react";
import { Icon } from "../Icon";
import { BreadcrumbItem, BreadcrumbItemProps } from "./BreadcrumbItem";
import { gray600 } from "../../AceColors";

import styled from "styled-components";

const BreadcrumbsWrapper = styled("div")`
  & > .breadcrumb {
    margin: 10px 0 10px;
    font-size: 13px;
    padding: 0;
    background-color: unset;

    > i {
      font-size: 18px;
    }

    > li:last-child {
      font-weight: bold;
    }
  }
  & > .breadcrumb > li {
    padding: 0 3px;
  }
  & > .breadcrumb > li > a {
    color: #0370f5;
  }
  & > .breadcrumb > li + li:before {
    font-family: FontAwesome;
    font-size: 14px;
    content: "\f105";
    color: ${gray600};
    padding: 0;
    margin-right: 6px;
    font-weight: normal;
  }
`;

export type BreadcrumbsProps = {
  /** FontAwesome 4 icon name without 'fa fa-' prefix */
  iconName?: string;
  /** Children prop takes a list of Breadcrumbs.Item components */
  children?:
    | React.ReactElement<BreadcrumbItemProps>
    | React.ReactElement<BreadcrumbItemProps>[];
};

export default class Breadcrumbs extends React.Component<BreadcrumbsProps> {
  static defaultProps = {};

  render() {
    return (
      <BreadcrumbsWrapper>
        <ul className="breadcrumb">
          {this.props.iconName && (
            <Icon name={this.props.iconName} margin="right" />
          )}
          {this.props.children}
        </ul>
      </BreadcrumbsWrapper>
    );
  }

  static Item = BreadcrumbItem;
}
