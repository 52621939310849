import * as React from "react";
import { useTableContext } from "../table-context";
import { OtherOptions, OtherOptionsProps } from "./other-options";

export const OtherOptionsButton = (
  props: Omit<OtherOptionsProps, "onCollapseAll" | "onExpandAll">
) => {
  const {
    grouping: { setExpandedGroups }
  } = useTableContext();

  return React.useMemo(() => {
    // console.log("other options");
    return (
      <OtherOptions
        onCollapseAll={() => setExpandedGroups([])}
        onExpandAll={groups => setExpandedGroups(groups)}
        {...props}
      />
    );
  }, [setExpandedGroups, props]);
};
