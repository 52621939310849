import {
  SelectionState as DSelectionState,
  SelectionStateProps
} from "@devexpress/dx-react-grid";
import * as React from "react";
import { useTableContext } from "../table-context";

export const SelectionState = (props: Partial<SelectionStateProps>) => {
  const {
    selection: { selection, setSelection }
  } = useTableContext();

  return React.useMemo(() => {
    console.log("selection");
    return (
      <DSelectionState
        selection={selection}
        onSelectionChange={setSelection}
        {...props}
      />
    );
  }, [selection, setSelection, props]);
};
