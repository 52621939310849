import * as React from "react";
import { useLocalStorage } from "hcss-hooks";
import { TableColumnWidthInfo } from "@devexpress/dx-react-grid";
import { TypedDataColumn, DataColumnType } from "../models";
import { keyBy } from "lodash";

export function createDefaultColumnSizes(
  columns: TypedDataColumn[],
  currentWidths: TableColumnWidthInfo[]
): TableColumnWidthInfo[] {
  const currentWidthHash = keyBy(currentWidths, "columnName");
  const newWidths = [...currentWidths];

  columns.forEach(column => {
    if (!(column.name in currentWidthHash)) {
      newWidths.push({
        columnName: column.name,
        width: defaultColumnWidths({ name: column.name, type: column.type })
      });
    }
  });

  return newWidths;
}

export function useColumnResizing(
  templateName: string,
  columns: TypedDataColumn[],
  options: { defaultSizing?: TableColumnWidthInfo[] } = { defaultSizing: [] }
) {
  const [cachedColumnWidths, setColumnWidths] = useLocalStorage<
    TableColumnWidthInfo[]
  >(`${templateName}.columnSizing`, options.defaultSizing ?? []);

  const columnWidths = React.useMemo(() => {
    return createDefaultColumnSizes(columns, cachedColumnWidths);
  }, [columns, cachedColumnWidths]);

  return { columnWidths, setColumnWidths };
}

export const defaultColumnWidths = ({ name, type }: TypedDataColumn) => {
  if (type === DataColumnType.Date || type === DataColumnType.DateTime) {
    return 145;
  }
  if (type === DataColumnType.LongText) {
    return 360;
  }
  if (type === DataColumnType.States) {
    return 85;
  }
  return 200;
};
