import * as React from "react";
import styled, { css } from "styled-components";

export const Divider = styled.li`
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
  font-size: 0;
`;

export interface DropdownMenuProps extends React.HTMLProps<HTMLUListElement> {
  className?: string;
  /** @ignore */
  isOpen?: boolean;
  right?: boolean;
}

const StyledDropdownMenu = styled.ul<DropdownMenuProps>`
  z-index: 1031;
  top: 99%;
  position: absolute;
  list-style: none;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  -webkit-padding-start: 0;
  display: ${(props: DropdownMenuProps) => (props.isOpen ? "block" : "none")};
  ${(props: DropdownMenuProps) =>
    (props.right &&
      css`
        right: 0;
        left: auto;
        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          border-bottom: 7px solid white;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          right: 9px;
          top: -6px;
        }
      `) as any};
`;

export default StyledDropdownMenu;

/** Causing issues when using ref and .contains in Dropdown.tsx when detecting if a click was made outside the dropdown.
 * Ref forwarding is a solution but exporting styled components is a lot simpler. */

// export class DropdownMenu extends React.Component<DropdownMenuProps> {
//   static defaultProps = { right: false };
//   render() {
//     const { children, ...props } = this.props;
//     return (
//       <StyledDropdownMenu {...props as any}>{children}</StyledDropdownMenu>
//     );
//   }
// }
