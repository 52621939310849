import axios from "axios";
import { AxiosInstance } from "axios";
import { OAuthInfoModel } from "./models";

export class OutboxAxiosInstance {
  axios: AxiosInstance;

  constructor(baseUrl: string, accessToken: string) {
    this.axios = axios.create({
      baseURL: baseUrl,
      headers: { Authorization: `Bearer ${accessToken}` }
    });
  }

  getUserOAuthInfo() {
    return this.axios.get<OAuthInfoModel>("/api/outbox/UserOAuthInfo");
  }

  sendTestOAuthMessage() {
    return this.axios.get<void>("/api/outbox/SendTestOAuthMessage");
  }
}
