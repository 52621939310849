import {
  TableGroupRow as DTableGroupRow,
  TableGroupRowProps,
  VirtualTable,
  TableSummaryRow
} from "@devexpress/dx-react-grid-bootstrap3";
import React, { ReactChild } from "react";
import { GroupCell } from "./group-cell";

export const TableGroupRow = (props: TableGroupRowProps) => {
  return (
    <DTableGroupRow
      cellComponent={GroupCell}
      indentCellComponent={StubCell}
      {...props}
    />
  );
};

export const StubCell = (props: any) => {
  return (
    <VirtualTable.Cell
      {...props}
      style={{ ...props.style, backgroundColor: "#FFF" }}
    />
  );
};

export const SummaryCell = (
  props: TableSummaryRow.ItemProps & {
    predicate?: (type: string) => boolean;
    format: (value: any) => ReactChild;
  }
) => {
  if (props.predicate?.(props.type) ?? true) {
    return (
      <div>
        <strong>{props.format?.(props.value) ?? props.value}</strong>
      </div>
    );
  }

  return <DTableGroupRow.SummaryItem {...props} />;
};
