import React from "react";
import { StepCompleteIndicatorProps } from "./Models";
import * as ConcreteColors from "../../../ConcreteColors";
import { Icon } from "../../Icon";
import styled from "styled-components";

const StepCompleteIndicator: React.FC<StepCompleteIndicatorProps> = (
  props: StepCompleteIndicatorProps
) => {
  // Deconstruct props
  const { index, containerBackground, ...rest } = props;

  const useCustomBackgroundClear = () => {
    if (containerBackground)
      return {
        background: containerBackground,
        boxShadow: `0 0 0 2px ${containerBackground}`
      };
    return undefined;
  };

  //
  //
  //
  return (
    <StyledCompletionIndicatorContainer
      className="step-completion-indicator"
      data-testid={`step-${index + 1}-completion-indicator`}
      style={useCustomBackgroundClear()}
      {...rest}
    >
      <Icon name="check-circle" />
    </StyledCompletionIndicatorContainer>
  );
};
export default StepCompleteIndicator;

// Styled components
const StyledCompletionIndicatorContainer = styled.div`
  position: absolute;
  top: 22px;
  right: 14px;

  background: #ffffff;
  box-shadow: 0 0 0 2px #ffffff;
  border-radius: 50px;

  color: ${ConcreteColors.green200};
  font-size: 18px;
  line-height: 1;
  z-index: 5;

  & > i {
    line-height: 0;
  }
`;
