import classNames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import * as ConcreteColors from "../../../ConcreteColors";
import { LinkProps } from "../../Link";
import { useDropdownMenuManager } from "./DropdownMenuManager";

const Icon = styled.i`
  width: 19.5px;
  margin-right: 10px;
`;

const MenuItemLink = styled.div`
  .dropdown-menu-link {
    display: inline-block;
    font-size: 1.35rem;
    line-height: normal;
    padding: 6px 0;
    /* min-width: 160px; */
    max-width: 100%;
    color: ${ConcreteColors.gray700};
    cursor: pointer;
  }
  .dropdown-menu-link:hover {
    color: ${ConcreteColors.blue200};
  }

  .dropdown-menu-link:active {
    color: ${ConcreteColors.blue300};
  }
`;

export interface DropdownMenuItemProps extends LinkProps {
  /** (Optional) Specify an icon class to use an icon at the beginning of your menu item */
  iconClassName?: string;
}

function UpgradedDropdownMenuItem({
  className,
  iconClassName,
  children,
  to,
  ref,
  onClick,
  ...props
}: DropdownMenuItemProps) {
  const useReactRouterLink = to !== undefined;

  return (
    <MenuItemLink className={classNames("dropdown-menu-item", className)}>
      {useReactRouterLink ? (
        <Link
          to={to}
          {...props}
          className="dropdown-menu-link"
          onClick={onClick}
        >
          {iconClassName && <Icon className={iconClassName} />}
          {children}
        </Link>
      ) : (
        <a onClick={onClick} {...props} className="dropdown-menu-link">
          {iconClassName && <Icon className={iconClassName} />}
          {children}
        </a>
      )}
    </MenuItemLink>
  );
}

const HcssDropdownMenuItem = styled(
  useDropdownMenuManager(UpgradedDropdownMenuItem)
)`
  ${props =>
    props.disabled === true &&
    css`
      text-decoration: none;
      filter: alpha(opacity=65);
      -webkit-box-shadow: none;
      box-shadow: none;
      opacity: 0.65;
      pointer-events: none;
      cursor: not-allowed;
    `};
`;

export default HcssDropdownMenuItem;
