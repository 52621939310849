import * as React from "react";
import { TypedDataColumn, TableSection } from "./models";
import {
  TableContextDefaults,
  TableContextHookOverrides,
  useTableContext
} from "./table-context";

export interface TableContainerProps {
  templateName: string;
  sections?: TableSection[];
  columns: TypedDataColumn[];
  overrides?: TableContextHookOverrides;
  defaults?: TableContextDefaults;
  templateDisplayName?: string;
}

export const TableContainer: React.FunctionComponent<TableContainerProps> = ({
  columns,
  sections,
  templateName,
  overrides,
  defaults,
  children
}) => {
  return (
    <useTableContext.Provider
      templateName={templateName}
      sections={sections}
      columns={columns}
      key={templateName}
      overrides={overrides}
      defaults={defaults}
    >
      {children}
    </useTableContext.Provider>
  );
};
