import React, { PropsWithChildren as WithChildren } from "react";
import { ProfileMenuProps } from "./Models";
import Dropdown from "../Dropdown";
import DropdownMenu from "../DropdownMenu";
import UserInfoSection from "./UserInfoSection";
import ProfileMenuItem from "./ProfileMenuItem";
// import * as ConcreteColors from "../../../../ConcreteColors";
import styled from "styled-components";

export type ProfileMenuComponent = React.FC<WithChildren<ProfileMenuProps>> & {
  Item: typeof ProfileMenuItem;
};

const ProfileMenu: ProfileMenuComponent = (
  props: WithChildren<ProfileMenuProps>
) => {
  // Destructure props
  const {
    firstName,
    lastName,
    companyName,
    children,
    className,
    menuClassName,
    userInfoComponent,
    menuIcon
  } = props;

  const renderUserInfo = () => {
    const UserInfo = userInfoComponent || UserInfoSection;
    return (
      <UserInfo
        firstName={firstName}
        lastName={lastName}
        companyName={companyName}
      />
    );
  };

  return (
    <Dropdown className={`profile-menu ${className || ""}`}>
      <Dropdown.ToggleButton>
        <Dropdown.Icon className={menuIcon || "fa fa-user-circle-o"} />
      </Dropdown.ToggleButton>
      <DropdownMenu
        right
        className={`profile-menu-dropdown ${menuClassName || ""}`}
      >
        <MenuInnerWrapper className="profile-menu-inner-wrapper">
          {renderUserInfo()}
          <ul className="menu-item-list">{children}</ul>
        </MenuInnerWrapper>
      </DropdownMenu>
    </Dropdown>
  );
};

ProfileMenu.Item = ProfileMenuItem;
export default ProfileMenu;

// Styled components
const MenuInnerWrapper = styled.div`
  position: relative;
  margin: 0 -16px;
  width: 360px;

  & > ul {
    margin: 32px 0 -32px;
    list-style-type: none;
    width: 100%;
  }
`;
