import React from "react";
import { FileMeta, SDError } from "../models";
import { SmartDriveAxiosInstance } from "./api";
import { useSmartDriveReducer } from "./reducer";

export interface UseSmartdriveApiOptions {
  url: string;
  appId: string;
  root: string;
  token: string;
  isInternal?: boolean;
  isShareable?: boolean;
  isReadOnlyUser?: boolean;
  onError?: (error: SDError) => void;
}

export function useSmartDriveApi(options: UseSmartdriveApiOptions) {
  const [state, dispatch] = useSmartDriveReducer();
  const { appId, root, url, token } = options;
  const api = React.useMemo(
    () => new SmartDriveAxiosInstance({ appId, root, url, token }),
    [appId, root, url, token]
  );
  // Setup api instance
  const uploadUrl =
    `${options.url}files` +
    `?appId=${options.appId}` +
    `&root=${options.root}` +
    `&isInternal=${options.isInternal || false}` +
    `&isShareable=${options.isShareable || false}`;

  // Setup async actions
  const loadFilesAsync = React.useCallback(async () => {
    try {
      dispatch({ type: "loadFiles" });
      const result = await api.loadFiles();
      dispatch({ type: "setFiles", payload: result.data });
    } catch (error) {
      dispatch({ type: "setFiles", payload: [] });
      options.onError!({
        statusCode: error?.response?.status,
        statusText: error?.response?.statusText
      });
      console.error(error);
    }
  }, [api, dispatch, options.onError]);

  const downloadFile = React.useCallback(
    async (file: FileMeta) => {
      if (state.downloading.indexOf(file.id) === -1) {
        try {
          dispatch({
            type: "downloadFile",
            payload: { id: file.id, isComplete: false }
          });

          const { data } = await api.downloadFile(file.id);

          // tslint:disable-next-line: deprecation
          const { saveAs } = await import("file-saver");
          saveAs(data, file.fileName);
        } catch (error) {
          options.onError!({
            statusCode: error?.response?.status,
            statusText: error?.response?.statusText
          });
          console.error(error);
        }

        dispatch({
          type: "downloadFile",
          payload: { id: file.id, isComplete: true }
        });
      }
    },
    [api, dispatch, options.onError, state.downloading]
  );

  const deleteFile = React.useCallback(
    async (fileId: string) => {
      try {
        await api.deleteFile(fileId);
        dispatch({ type: "removeFile", payload: fileId });
      } catch (error) {
        options.onError!({
          statusCode: error?.response?.status,
          statusText: error?.response?.statusText
        });
        console.error(error);
      }
    },
    [api, dispatch, options.onError]
  );

  // load files when mounted
  React.useEffect(() => {
    loadFilesAsync().catch(err => {
      console.error(err);
    });
  }, [loadFilesAsync]);

  return {
    files: state.files,
    loading: state.loading,
    downloading: state.downloading,
    dispatch,
    downloadFile,
    deleteFile,
    baseUrl: options.url,
    uploadUrl
  };
}
