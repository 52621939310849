import * as React from "react";
import * as ReactDOM from "react-dom";
import styled from "styled-components";

const BackdropWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #d6d6d6;
  opacity: 0.8;
  top: 0;
  left: 0;
`;

export interface BackdropProps extends React.HTMLProps<HTMLDivElement> {
  /** A ref of the container that will be covered by the backdrop. */
  elementRef: React.RefObject<any>;
}

export class Backdrop extends React.Component<BackdropProps> {
  render() {
    const { elementRef, ...other } = this.props;
    if (elementRef.current) {
      return ReactDOM.createPortal(
        <BackdropWrapper {...(other as any)} />,
        elementRef.current
      );
    } else {
      console.warn("Ref passed to `Backdrop` is null");
      return null;
    }
  }
}

export default Backdrop;
