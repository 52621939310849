import * as React from "react";
import styled from "styled-components";
const Icon = styled.i`
  width: 19.5px;
  margin-right: 10px;
`;

const MenuItemLink = styled.a`
  display: block;
  font-size: 13px;
  line-height: 1.42857143;
  padding: 4px 12px;
  white-space: nowrap;
  min-width: 160px;
  cursor: pointer;
  &&,
  &&:hover {
    color: #333;
  }
  &&:hover {
    background-color: rgb(245, 245, 245);
  }
`;
export interface DropdownMenuItemProps
  extends React.HTMLProps<HTMLAnchorElement> {
  iconClassName: string;
  children: string;
}

export default function DropdownMenuItem({
  iconClassName,
  children,
  ...props
}: DropdownMenuItemProps) {
  const { ref, href, ...rest } = props;
  return (
    <li>
      <MenuItemLink href={href} {...(rest as any)}>
        <Icon className={iconClassName} />
        {children}
      </MenuItemLink>
    </li>
  );
}
