import styled from "styled-components";

const SidebarMenuItem = styled.li.attrs(() => ({
  "data-testid": "sidebar-menu-item"
}))`
  display: block;
  position: relative;
  float: none;
  padding: 0;
  border-style: solid;
  border-width: 1px 0px 0px;
  border-color: rgb(224, 224, 224);
  margin: 0;
  &.active > a {
    font-weight: 700;
    color: ${props => props.theme.primaryColor};
  }

  &.active::after {
    display: block;
    content: "";
    position: absolute;
    right: -2px;
    top: -1px;
    bottom: 0;
    z-index: 1;
    border: 2px solid ${props => props.theme.primaryColor};
    border-width: 0 2px 0 0;
  }
`;
export default SidebarMenuItem;
