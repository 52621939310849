import * as React from "react";
//import { Icon, ConcreteColors } from "../../../index";
import styled from "styled-components";
import classNames from "classnames";
import Icon from "../../Icon";
import * as ConcreteColors from "../../../ConcreteColors";

const MenuItemStyling = styled("li")<{ color?: string }>`
  &.hamburgermenu-item {
    a {
      padding: 16px 32px 16px 16px;
      cursor: pointer;

      &:hover,
      &:active {
        background: ${ConcreteColors.gray200};
      }

      .product-link {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: stretch;
        justify-content: flex-start;

        & > .product-link-icon {
          margin-top: -3px;
          margin-right: 8px;
          flex-shrink: 0;
          font-size: 3.2rem;
          color: ${props => props.color || ConcreteColors.gray700};
        }

        & > .product-link-info {
          flex-grow: 1;
          font-family: "Inter", Arial, Helvetica, sans-serif;

          & > .product-name {
            font-size: 2rem;
            font-weight: 400;
            color: ${props => props.color || ConcreteColors.gray700};
            line-height: 2.6rem;

            & > strong {
              font-weight: 800;
            }
          }

          & > .product-desc {
            font-size: 1.3rem;
            font-weight: 400;
            color: ${ConcreteColors.gray700};
          }
        }
      }
    }
  }
`;
export interface HamburgerMenuItemProps extends React.HTMLProps<HTMLLIElement> {
  iconName: string;
  subtitle?: string;
  color?: string;
  productBrandBold: string;
  productBrandLight: string;
}
export const HamburgerMenuItem = ({ ...props }: HamburgerMenuItemProps) => {
  let {
    iconName,
    productBrandBold,
    productBrandLight,
    subtitle,
    className,
    href,
    ref,
    ...others
  } = props;
  return (
    <MenuItemStyling
      className={classNames("hamburgermenu-item", className)}
      {...(others as any)}
    >
      <a href={href} target="_blank" rel="noopener noreferrer">
        <div className="product-link">
          <div className="product-link-icon">
            <Icon name={iconName} />
          </div>
          <div className="product-link-info">
            <div className="product-name">
              {productBrandLight} <strong>{productBrandBold}</strong>
            </div>
            <div className="product-desc">{subtitle}</div>
          </div>
        </div>
      </a>
    </MenuItemStyling>
  );
};
export default HamburgerMenuItem;
