import { ContextMenu } from "react-contextmenu";
import styled from "styled-components";
import { ContextMenuProps } from "./models";
import React from "react";

const WrappedContextMenu = styled(ContextMenu)`
  z-index: 20000;
  &,
  & .react-contextmenu {
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  }

  & .react-contextmenu-item {
    &:hover {
      background-color: #f5f5f5;
    }
    padding: 3px 20px;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    z-index: 20001;
    cursor: pointer;
  }

  & .react-contextmenu-submenu {
    padding: 0;
  }

  & nav.react-contextmenu-submenu nav {
    display: none;
  }

  & nav.react-contextmenu-submenu nav.react-contextmenu--visible {
    display: initial;
  }

  & .react-contextmenu-item--divider {
    border-bottom: 1px solid #d6d6d6;
    padding: 0px;
    margin: 4px 0px;

    &:hover {
      background-color: #fff;
    }
  }
`;

export const StyledContextMenu: React.FC<ContextMenuProps> = props => {
  return <WrappedContextMenu {...props} />;
};

export const MenuItemHeader = styled.div`
  display: block;
  font-size: 1.22rem;
  font-weight: 600;
  text-transform: uppercase;
  color: rgb(64, 64, 64);
  padding: 3px 20px;
`;
