import React from "react";
import { WizardProgressProps } from "./Models";
import ProgressStep from "./ProgressStep";
import ProgressTrack from "./ProgressTrack";
import styled from "styled-components";

const WizardProgress: React.FC<WizardProgressProps> = (
  props: WizardProgressProps
) => {
  // Destructure props
  const {
    currentStep,
    onChangeStep,
    stepNames,
    lastCompletedStepIndex,
    progressStepComponent,
    progressTrackComponent,
    stepCompleteIndicatorComponent,
    style,
    testId,
    containerBackground
  } = props;

  //
  //
  //
  // Render a clickable step marker in the progress bar for
  // each individual step in the Wizard
  const renderProgressStep = (name: string, index: number) => {
    const isComplete = index <= lastCompletedStepIndex;
    const isCurrentStep = index === currentStep;
    const isClickable = index <= lastCompletedStepIndex + 1 && !isCurrentStep;

    const StepMarker = progressStepComponent || ProgressStep;

    return (
      <StepMarker
        key={index}
        onClick={onChangeStep}
        index={index}
        name={name}
        isClickable={isClickable}
        isComplete={isComplete}
        isCurrentStep={isCurrentStep}
        stepCompleteIndicatorComponent={stepCompleteIndicatorComponent}
        containerBackground={containerBackground}
      />
    );
  };

  //
  //
  //
  // Render the track line that connects the step markers in the
  // progress bar and helps indicate to the user how much they've
  // completed and how much is left.
  const renderProgressTrack = () => {
    const TrackLine = progressTrackComponent || ProgressTrack;
    return <TrackLine currentStep={currentStep} />;
  };

  //
  //
  //
  return (
    <ProgressContainer
      className="wizard-progress-container"
      data-testid={testId || "wizard-progress-container"}
      style={style}
    >
      <div className="progress-container-inner-wrapper">
        {renderProgressTrack()}
        <ProgressStepList
          className="wizard-progress-step-list"
          data-testid="wizard-progress-step-list"
        >
          {stepNames.map(renderProgressStep)}
        </ProgressStepList>
      </div>
    </ProgressContainer>
  );
};
export default WizardProgress;

// Styled components
const ProgressContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 0 auto 16px;

  font-family: "Inter", Arial, Helvetica, sans-serif;
  transform: translateZ(0);

  & > .progress-container-inner-wrapper {
    display: inline-block;
    position: relative;
    transform: translateZ(0);
  }
`;

const ProgressStepList = styled.ul`
  margin: 0;
  list-style-type: none;
  transform: translateZ(0);
`;
