import * as React from "react";
import { TreeSelectorWrapper } from "./Styled";
import { Icons, Language } from "react-checkbox-tree";
const CheckboxTree = require("react-checkbox-tree");

export { Icons, Language };

/** Redeclare type definitions from react-checkbox-tree because they are
 * currently wrong, if its fixed we could just use and re-export them
 * see: https://github.com/jakezatecky/react-checkbox-tree/issues/145
 * https://github.com/jakezatecky/react-checkbox-tree/issues/127
 * https://github.com/jakezatecky/react-checkbox-tree/pull/48
 */
export interface Node {
  label: React.ReactNode; // On the typed definitions this JSX.Element
  value: string | number;
  children?: Array<Node>;
  className?: string;
  disabled?: boolean;
  icon?: JSX.Element;
  showCheckbox?: boolean;
  title?: string;
}

export interface CheckboxProps {
  nodes: Array<Node>;
  checked: Array<string | number>;
  expanded: Array<string | number>;
  onCheck: (checked: Array<string>) => void;
  onExpand: (expanded: Array<string>) => void;

  disabled?: boolean;
  expandDisabled?: boolean;
  expandOnClick?: boolean;
  icons?: Icons;
  lang?: Language;
  name?: string;
  nameAsArray?: boolean;
  nativeCheckboxes?: boolean;
  noCascade?: boolean;
  onlyLeafCheckboxes?: boolean;
  optimisticToggle?: boolean;
  showExpandAll?: boolean;
  showNodeIcon?: boolean;
  showNodeTitles?: boolean;
  onClick?: (event: { checked: boolean; value: string | number }) => void;
}

const defaultIcons = {
  check: <i className="fa fa-check-square" aria-hidden="true" />,
  uncheck: <i className="fa fa-square-o" aria-hidden="true" />,
  halfCheck: <span className="rct-icon rct-icon-half-check" />,
  expandClose: <i className="fa fa-plus" />,
  expandOpen: <i className="fa fa-minus" />,
  expandAll: <span className="rct-icon rct-icon-expand-all" />,
  collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
  parentClose: "",
  parentOpen: "",
  leaf: ""
};

export default class TreeSelector extends React.Component<CheckboxProps> {
  render() {
    const icons = this.props.icons ? this.props.icons : defaultIcons;
    return (
      <TreeSelectorWrapper>
        <CheckboxTree {...this.props} icons={icons} />
      </TreeSelectorWrapper>
    );
  }
}
