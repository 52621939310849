import styled from "styled-components";
import { ToggleButtonGroup as BootstrapToggleButtonGroup } from "react-bootstrap";
import * as AceColors from "../AceColors";

export default styled(BootstrapToggleButtonGroup)`
  .btn.btn-default {
    background-color: transparent;
    border-color: transparent;
    border-radius: 0px;
    border: 0px solid transparent;
    border-bottom-width: 4px;
    color: black;
    line-height: 1.5;
    margin-right: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .btn.btn-default:hover {
    border-color: ${AceColors.gray500};
  }
  .btn.btn-default.active {
    border-color: ${AceColors.gray700};
    box-shadow: none;
  }
  .btn.btn-default:active {
    box-shadow: none;
  }

  .btn.btn-default.active:after,
  .btn.btn-default.active:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .btn.btn-default.active:after {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: ${AceColors.gray700};
    border-width: 8px;
    margin-left: -8px;
    margin-top: -13px;
  }
`;
