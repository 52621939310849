import { Button, Icon, Modal } from "hcss-components";
import React from "react";
import { useSmartDriveContext } from "../smartdrive-context";

export const SmartDriveDeleteAllFilesConfirmation = () => {
  const {
    api: { deleteFile, files },
    allSelectedRowsOperations: { selectedRowIds, setSelectedRowIds },
    delModalOperations: { modalShowUp, setModalShowUp }
  } = useSmartDriveContext();

  const showModal = modalShowUp;

  return (
    <Modal show={showModal} onHide={() => setModalShowUp(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Delete File(s)?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showModal &&
          `Are you sure you want to delete ${
            selectedRowIds && selectedRowIds.length > 0
              ? selectedRowIds
                  .map(id => files.filter(file => file.id === id)[0].fileName)
                  .join(", ")
              : files.map(file => file.fileName).join(", ")
          } ?`}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            if (selectedRowIds && selectedRowIds.length > 0) {
              selectedRowIds.forEach(id => {
                deleteFile(id).catch(err => {
                  console.error(err);
                });
              });
            } else {
              files.forEach(file =>
                deleteFile(file.id).catch(err => {
                  console.error(err);
                })
              );
            }
            setSelectedRowIds([]);
            setModalShowUp(false);
          }}
          hcssStyle="Delete"
        >
          <Icon name="trash-o" />
          &nbsp;Confirm Delete
        </Button>
        <Button hcssStyle="ThemeInverted" onClick={() => setModalShowUp(false)}>
          <Icon name="times" />
          &nbsp;Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
