import * as React from "react";
import { Icon } from "../Icon";
import styled from "styled-components";
import classNames from "classnames";
import { gray600 } from "../../AceColors";

const MenuItemStlying = styled.li<{ color?: string }>`
  &.hamburgermenu-item {
    a {
      cursor: pointer;
      padding: 4px 12px;
      .product-name {
        color: ${props => props.color || "#000"};
        font-size: 18pt;
        font-family: "ProximaNova";
        line-height: 0.1;
        & > i {
          margin-right: 10px;
        }
      }
      .subtitle {
        font-size: 13px;
        color: ${gray600};
      }
    }
  }
`;
export interface HamburgerMenuItemProps extends React.HTMLProps<HTMLLIElement> {
  iconName: string;
  subtitle?: string;
  color?: string;
  productBrandBold: string;
  productBrandLight: string;
}
export const HamburgerMenuItem = (props: HamburgerMenuItemProps) => {
  let {
    iconName,
    productBrandBold,
    productBrandLight,
    subtitle,
    className,
    href,
    ref,
    ...others
  } = props;
  return (
    <MenuItemStlying
      className={classNames("hamburgermenu-item", className)}
      {...(others as any)}
    >
      <a href={href} target="_blank" rel="noopener noreferrer">
        <div className="product-name">
          <Icon name={iconName} />
          {productBrandLight}
          <strong>{productBrandBold}</strong>
        </div>
        <p className="subtitle">{subtitle}</p>
      </a>
    </MenuItemStlying>
  );
};
export default HamburgerMenuItem;
