import { default as WizardProgress } from "./WizardProgress/";
import { default as ProgressStep } from "./WizardProgress/ProgressStep";
import { default as ProgressTrack } from "./WizardProgress/ProgressTrack";
import { default as StepContainer } from "./StepContainer";

export { default as Wizard } from "./Wizard";

export const WizardComponents = {
  Progress: WizardProgress,
  ProgressStep: ProgressStep,
  ProgressTrack: ProgressTrack,
  StepContainer: StepContainer
};
