import * as React from "react";
import {
  BaseDataSelectorProps,
  BaseDataSelectorState,
  IDataSelector
} from "../Models";

export interface SimpleTableSelectorProps extends BaseDataSelectorProps {}

export interface SimpleTableSelectorState extends BaseDataSelectorState {}

/**
 * A Simple Data Selector component for the SelectModal component to be able
 * to offer an out of the box selection, it simply shows all the data on a Table,
 * no paging, sorting, filtering or any of that functionalities yet which makes this
 * Selector not very useful unless is used with small amount of data but perhaps
 * we could improve this selector so that the SelectModal can offer a decent out of the box
 * functionality without the caller needing to create it's own DataSelector component
 */
export class SimpleTableSelector
  extends React.Component<SimpleTableSelectorProps, SimpleTableSelectorState>
  implements IDataSelector {
  state: Readonly<SimpleTableSelectorState> = {
    selectedItems: []
  };
  columnKey: string;

  constructor(props: SimpleTableSelectorProps) {
    super(props);
    this.columnKey = props.columnKey;
    if (props.initialSelectedIds) {
      const selectedItems = props.initialSelectedIds.map(id => {
        return props.rows.find(r => r[props.columnKey] === id);
      });
      this.state = {
        selectedItems
      };
    }
  }

  onRowClick = (selectedItem: any) => {
    if (this.state.selectedItems.indexOf(selectedItem) === -1) {
      this.addItem(selectedItem);
    } else {
      this.removeItem(selectedItem);
    }
  };

  addItem = (selectedItem: any) => {
    this.setState(state => {
      const selectedItems = [...state.selectedItems, selectedItem];
      return {
        selectedItems
      };
    });
  };

  removeItem = (selectedItem: any) => {
    this.setState(state => {
      const selectedItems = state.selectedItems.filter(
        item => item[this.columnKey] !== selectedItem[this.columnKey]
      );
      return {
        selectedItems
      };
    });
  };

  getItemsSelected = () => {
    return this.state.selectedItems;
  };

  render() {
    return (
      <table className="table">
        <thead>
          <tr>
            {this.props.columns.map(c => {
              return <th key={c.name}>{c.title}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {this.props.rows.map(r => {
            const isSelected = this.state.selectedItems.indexOf(r) !== -1;
            return (
              <tr
                key={r[this.columnKey]}
                onClick={() => this.onRowClick(r)}
                className={isSelected ? "active" : ""}
              >
                {this.props.columns.map(c => {
                  return <td key={c.name}>{r[c.name]}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}
