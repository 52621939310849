import {
  TableColumnVisibility as DTableColumnVisibility,
  TableColumnVisibilityProps
} from "@devexpress/dx-react-grid-bootstrap3";
import * as React from "react";
import { useTableContext } from "../table-context";

export const TableColumnVisibility = (
  props: Partial<TableColumnVisibilityProps>
) => {
  const {
    visibility: { hiddenColumns, setHiddenColumns },
    columnLookup
  } = useTableContext();

  return React.useMemo(() => {
    // console.log("visibility");
    return (
      <DTableColumnVisibility
        hiddenColumnNames={hiddenColumns.filter(
          col => columnLookup[col]?.hidingEnabled
        )}
        onHiddenColumnNamesChange={setHiddenColumns}
        {...props}
      />
    );
  }, [hiddenColumns, setHiddenColumns, props, columnLookup]);
};

export const ColumnVisibility = TableColumnVisibility;
