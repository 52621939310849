import {
  TableFixedColumns as DTableFixedColumns,
  TableFixedColumnsProps,
  TableGroupRow
} from "@devexpress/dx-react-grid-bootstrap3";
import * as React from "react";

export const TableFixedColumns = ({
  leftColumns = [],
  ...props
}: Partial<TableFixedColumnsProps>) => {
  return React.useMemo(() => {
    const columnsWithGroup: any[] = [TableGroupRow.COLUMN_TYPE, ...leftColumns];
    return (
      <DTableFixedColumns
        leftColumns={columnsWithGroup}
        cellComponent={CellComponent}
        {...props}
      />
    );
  }, [leftColumns, props]);
};

export const CellComponent = ({ style, ...props }: any) => {
  const isGroupRow = props.tableRow.type === TableGroupRow.ROW_TYPE;
  return (
    <DTableFixedColumns.Cell
      {...props}
      showRightDivider={isGroupRow ? false : props.showRightDivider}
      style={{
        backgroundColor: isGroupRow ? "inherit" : "#FFF"
      }}
    />
  );
};
