import React from "react";
import { OAuthConfiguration } from "../models";
import { getDescriptionForOAuthProvider, getLogoForOAuthProvider } from "../oauth-providers";
import { OAuthSignInLink } from "./oauth-signin-link";
import styled from "styled-components";

// tslint:disable-next-line:no-empty-interface
interface OAuthOptionProps extends OAuthConfiguration {}

export class OAuthOption extends React.PureComponent<OAuthOptionProps> {
  render() {
    const providerDesc = getDescriptionForOAuthProvider(this.props.provider);
    const providerLogo = getLogoForOAuthProvider(this.props.provider);

    return (
      <ProviderListItem className="email-provider-list-item">
        <OAuthSignInLink
          userId={this.props.userId}
          baseApiUrl={this.props.baseApiUrl}
          baseOutboxAuthUrl={this.props.baseOutboxAuthUrl}
          provider={this.props.provider}
          getAccessToken={this.props.getAccessToken}
          onUpdate={this.props.onUpdate}>
          <div className="email-provider-signin-link">
            <div className="email-provider-info">
              <div className="email-provider-signin-action">
                Sign in with {this.props.provider.toString()}
              </div>
              <div className="email-provider-description">{providerDesc}</div>
            </div>
            <div className="email-provider-logo">{providerLogo}</div>
          </div>
        </OAuthSignInLink>
      </ProviderListItem>
    );
  }
}

const ProviderListItem = styled("li")`
  min-height: 48px;
  padding: 8px;
  border-bottom: 1px solid #ececec;

  & a:hover {
    text-decoration: none !important;
  }

  & .email-provider-signin-link {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: space-between;
    padding: 16px 12px;

    &:hover {
      cursor: pointer;
      & > .email-provider-info > .email-provider-signin-action {
        color: #0370f5;
      }
    }

    & .email-provider-info {
      text-align: left;

      & > .email-provider-signin-action {
        font-size: 16px;
        color: #000000;
      }
      & > .email-provider-description {
        font-size: 13px;
        color: #707070;
      }
    }

    & .email-provider-logo {
      text-align: right;
      height: 21px;
      margin-top: 2px;
    }
  }
`;
