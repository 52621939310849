import * as React from "react";
import { ValidationStateType } from "./models";

export interface InputFieldContextType extends ValidationStateType {
  required?: boolean;
}

export const InputFieldContext = React.createContext<InputFieldContextType>({
  validationState: null,
  required: false
});
