import createContainer from "constate";
import { keyBy } from "lodash";
import * as React from "react";
import { useColoring } from "./hooks/useColoring";
import { useColumnOrdering } from "./hooks/useColumnOrdering";
import { useColumnResizing } from "./hooks/useColumnResizing";
import { useColumnVisibility } from "./hooks/useColumnVisibility";
import { useFiltering } from "./hooks/useFiltering";
import { useFixedColumns } from "./hooks/useFixedColumns";
import { useGrouping } from "./hooks/useGrouping";
import { usePagination } from "./hooks/usePaging";
import { useSearch } from "./hooks/useSearch";
import { useSelection } from "./hooks/useSelection";
import { useSorting } from "./hooks/useSorting";
import { useSummary } from "./hooks/useSummary";
import { TypedDataColumn, TableSection, Grouping } from "./models";
import { useVirtualColumns } from "./grouping/use-virtual-columns";
import {
  Sorting,
  TableColumnWidthInfo,
  SummaryItem
} from "@devexpress/dx-react-grid";

export interface TableContextHookOverrides {
  grouping?: Partial<ReturnType<typeof useGrouping>>;
  summary?: Partial<ReturnType<typeof useSummary>>;
  sorting?: Partial<ReturnType<typeof useSorting>>;
  order?: Partial<ReturnType<typeof useColumnOrdering>>;
  visibility?: Partial<ReturnType<typeof useColumnVisibility>>;
  paging?: Partial<ReturnType<typeof usePagination>>;
  sizing?: Partial<ReturnType<typeof useColumnResizing>>;
  fixedColumns?: Partial<ReturnType<typeof useFixedColumns>>;
  search?: Partial<ReturnType<typeof useSearch>>;
  selection?: Partial<ReturnType<typeof useSelection>>;
  filtering?: Partial<ReturnType<typeof useFiltering>>;
  coloring?: Partial<ReturnType<typeof useColoring>>;
}

export interface TableContextDefaults {
  visibility?: Partial<{ defaultHiddenColumns: string[] }>;
  sorting?: Partial<{ defaultSorting: Sorting[] }>;
  sizing?: Partial<{ defaultSizing: TableColumnWidthInfo[] }>;
  summary?: Partial<{ defaultSummary: SummaryItem[] }>;
  grouping?: Partial<{
    defaultGrouping: Grouping[];
    defaultExpandedGroups: string[];
  }>;
}

export interface TableContextProps<T = any> {
  sections?: TableSection[];
  columns: TypedDataColumn[];
  templateName: string;
  overrides?: TableContextHookOverrides;
  defaults?: TableContextDefaults;
  passthrough?: T;
}

export function useTable<T>({
  columns: passedColumns,
  sections = [],
  templateName,
  overrides = {},
  defaults = {},
  passthrough
}: TableContextProps<T>) {
  const columns = React.useMemo(() => normalizeColumns(passedColumns), [
    passedColumns
  ]);
  const grouping = combineOverrides(
    useGrouping(templateName, defaults.grouping),
    overrides.grouping
  );
  const visibility = combineOverrides(
    useColumnVisibility(templateName, defaults.visibility),
    overrides.visibility
  );

  const summary = combineOverrides(
    useSummary(templateName, defaults.summary),
    overrides.summary
  );

  const order = combineOverrides(
    useColumnOrdering(templateName),
    overrides.order
  );
  const sorting = combineOverrides(
    useSorting(templateName, defaults.sorting),
    overrides.sorting
  );

  const paging = combineOverrides(
    usePagination(templateName),
    overrides.paging
  );
  const coloring = combineOverrides(
    useColoring(templateName),
    overrides.coloring
  );
  const search = combineOverrides(useSearch(templateName), overrides.search);
  const selection = combineOverrides(useSelection(), overrides.selection);
  const filtering = combineOverrides(useFiltering(), overrides.filtering);

  const [showModal, setShowModal] = React.useState(false);
  const [showProjectsModal, setShowProjectsModal] = React.useState(false);

  const { virtualColumns, extendedGroups } = useVirtualColumns(
    columns,
    grouping.grouping
  );

  const allColumns = React.useMemo(() => [...columns, ...virtualColumns], [
    columns,
    virtualColumns
  ]);

  const sizing = combineOverrides(
    useColumnResizing(templateName, allColumns, defaults.sizing),
    overrides.sizing
  );

  const columnLookup = React.useMemo(() => keyBy(allColumns, "name"), [
    allColumns
  ]);

  return {
    templateName,
    columns: allColumns,
    virtualColumns,
    columnLookup,
    grouping,
    extendedGroups,
    sorting,
    summary,
    paging,
    sizing,
    order,
    visibility,
    search,
    selection,
    filtering,
    sections,
    coloring,
    columnSelect: {
      showModal,
      setShowModal
    },
    highlightProjects: {
      showProjectsModal,
      setShowProjectsModal
    },
    passthrough
  };
}

function normalizeColumns(columns: TypedDataColumn[]): TypedDataColumn[] {
  return columns.map(col => ({
    ...col,
    groupingEnabled: col.groupingEnabled ?? true,
    hidingEnabled: col.hidingEnabled ?? true
  }));
}

function combineOverrides<T>(original: T, override: Partial<T> = {}): T {
  return { ...original, ...override } as T;
}

export const useTableContext = createContainer((props: TableContextProps) =>
  useTable(props)
);
