// tslint:disable:no-unused-expression

import React from "react";
import debounce from "lodash.debounce";
import styled from "styled-components";
import { ContentLoadingOverlay } from "./loaders";
import { OAuthProvider, OutboxConfiguration, OAuthInfoModel } from "../models";
import { getDescriptionForOAuthProvider, getLogoForOAuthProvider } from "../oauth-providers";
import { OAuthOption } from "./oauth-option";
import { OAuthSignInLink } from "./oauth-signin-link";
import { OutboxAxiosInstance } from "../api";

export interface OAuthConfigurationWidgetProps extends OutboxConfiguration {
  onGetUserOAuthInfoFailed?: (error?: any) => void;
  onSendTestMessageSuccess?: () => void;
  onSendTestMessageFailure?: (error?: any) => void;
}

export interface OAuthConfigurationWidgetState {
  settings?: OAuthInfoModel;
  isLoading: boolean;
  isSendingTestMessage: boolean;
  differentAccountRequested: boolean;
  providerListStatus: "showing" | "visible" | "hiding" | "hidden";
}

export class OAuthConfigurationWidget extends React.PureComponent<
  OAuthConfigurationWidgetProps,
  OAuthConfigurationWidgetState
> {
  client: OutboxAxiosInstance;

  constructor(props: OAuthConfigurationWidgetProps) {
    super(props);

    const accessToken = props.getAccessToken();
    this.client = new OutboxAxiosInstance(props.baseApiUrl, accessToken);

    this.state = {
      isLoading: true,
      isSendingTestMessage: false,
      differentAccountRequested: false,
      providerListStatus: "hidden"
    };
  }

  async componentDidMount() {
    try {
      const response = await this.client.getUserOAuthInfo();
      const result = response.data;

      this.setState({
        settings: result,
        providerListStatus: result ? "hidden" : "visible",
        isLoading: false
      });
    } catch (error) {
      this.props.onGetUserOAuthInfoFailed && this.props.onGetUserOAuthInfoFailed(error);

      this.setState({
        providerListStatus: "visible",
        isLoading: false
      });
    }
  }

  handleUpdate = (updatedSettings: OAuthInfoModel) => {
    if (this.props.onUpdate) {
      this.props.onUpdate(updatedSettings);
    }

    this.setState(
      {
        isLoading: true,
        settings: updatedSettings,
        providerListStatus: updatedSettings ? "hidden" : "visible"
      },
      () =>
        this.setState({
          isLoading: false
        })
    );
  };

  showProviderList = (delayedCallback?: () => void) => {
    this.setState(
      { providerListStatus: "showing" },
      debounce(() => {
        this.setState({ providerListStatus: "visible" });
        if (delayedCallback) {
          delayedCallback();
        }
      }, 525)
    );
  };

  hideProviderList = (delayedCallback?: () => void) => {
    this.setState(
      { providerListStatus: "hiding" },
      debounce(() => {
        this.setState({ providerListStatus: "hidden" });
        if (delayedCallback) {
          delayedCallback();
        }
      }, 525)
    );
  };

  handleTestMessageRequested = () => {
    if (this.state.settings) {
      this.setState({ isSendingTestMessage: true }, this.sendTestMessage);
    }
  };

  sendTestMessage = async () => {
    try {
      await this.client.sendTestOAuthMessage();
      this.props.onSendTestMessageSuccess && this.props.onSendTestMessageSuccess();
    } catch (error) {
      this.props.onSendTestMessageFailure && this.props.onSendTestMessageFailure(error);
    }

    this.setState({ isSendingTestMessage: false });
  };

  handleDifferentAccountRequested = () => {
    this.setState({ differentAccountRequested: true }, this.showProviderList);
  };

  handleDifferentAccountRequestCanceled = () => {
    this.hideProviderList(() => this.setState({ differentAccountRequested: false }));
  };

  renderCurrentSettings = () => {
    if (!this.state.settings) {
      return null;
    }

    const { needsRefresh, email } = this.state.settings;

    // These should be coming from current settings state
    // when other oauth options are implemented.
    const providerDesc = getDescriptionForOAuthProvider(OAuthProvider.Microsoft);
    const providerLogo = getLogoForOAuthProvider(OAuthProvider.Microsoft);

    return (
      <CurrentSettingsSection
        className={`user-email-settings-details ${needsRefresh ? "expired" : "connected"}`}>
        <div className="gray-background-extension" />
        <div className="user-email-details-container">
          {this.state.isSendingTestMessage && (
            <ContentLoadingOverlay message="Sending test message..." />
          )}
          <div className="email-details-row">
            <div className="email-info">
              <div className="email-address">{email || ""}</div>
              <div className="email-provider-description">{providerDesc}</div>
            </div>
            <div className="email-provider-logo">{providerLogo}</div>
          </div>
          <div className="email-status-row">
            <div className="email-status-message">{this.renderStatusMessage()}</div>
            <div className="email-status-action">{this.renderStatusAction()}</div>
          </div>
        </div>
        <div className="use-different-account">
          <a className="different-account-link" onClick={this.handleDifferentAccountRequested}>
            <span>Use a different account</span>
            <i className="fa fa-chevron-right" />
          </a>
        </div>
      </CurrentSettingsSection>
    );
  };

  renderStatusMessage = () => {
    if (this.state.settings && this.state.settings.needsRefresh) {
      return (
        <OAuthStatusMessage className="oauth-status-message oauth-expired-message">
          <i className="fa fa-exclamation-triangle" />
          <span>Login expired.</span>
        </OAuthStatusMessage>
      );
    } else {
      return (
        <OAuthStatusMessage className="oauth-status-message oauth-connected-message">
          <i className="fa fa-check-circle" />
          <span>Connected</span>
        </OAuthStatusMessage>
      );
    }
  };

  renderStatusAction = () => {
    if (this.state.settings && this.state.settings.needsRefresh) {
      return (
        <OAuthSignInLink
          baseApiUrl={this.props.baseApiUrl}
          userId={this.props.userId}
          baseOutboxAuthUrl={this.props.baseOutboxAuthUrl}
          provider={OAuthProvider.Microsoft}
          getAccessToken={this.props.getAccessToken}
          onUpdate={this.props.onUpdate}>
          Sign in again to connect.
        </OAuthSignInLink>
      );
    }

    return <a onClick={this.handleTestMessageRequested}>Send a test message</a>;
  };

  render() {
    return (
      <SettingsContainer className="user-email-settings">
        {this.state.isLoading && <ContentLoadingOverlay />}
        <OAuthProviderList
          className="oauth-email-provider-list"
          data-provider-list-display-state={this.state.providerListStatus}>
          {this.state.differentAccountRequested && (
            <li className="cancel-different-account-requested">
              <a onClick={this.handleDifferentAccountRequestCanceled}>
                <i className="fa fa-chevron-left" />
                Back to current settings
              </a>
            </li>
          )}
          <OAuthOption
            userId={this.props.userId}
            baseApiUrl={this.props.baseApiUrl}
            baseOutboxAuthUrl={this.props.baseOutboxAuthUrl}
            provider={OAuthProvider.Microsoft}
            getAccessToken={this.props.getAccessToken}
            onUpdate={this.handleUpdate}
          />
        </OAuthProviderList>
        {this.state.settings && this.renderCurrentSettings()}
      </SettingsContainer>
    );
  }
}

const SettingsContainer = styled.div`
  position: relative;
  max-width: 540px;
  height: 100%;
  overflow-x: hidden;
`;

const OAuthProviderList = styled.ul`
  position: absolute;
  list-style-type: none;
  margin: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-left-color: #cccccc;
  border-left-style: solid;
  z-index: 3;

  &[data-provider-list-display-state="showing"] {
    left: 0px;
    border-left-width: 1px;
    transition: 0.5s ease-out;
  }
  &[data-provider-list-display-state="visible"] {
    left: 0px;
    border-left-width: 0px;
    transition: 0s;
  }
  &[data-provider-list-display-state="hiding"] {
    left: 600px;
    border-left-width: 1px;
    transition: 0.5s ease-in;
  }
  &[data-provider-list-display-state="hidden"] {
    left: 600px;
    border-left-width: 0px;
    transition: 0s;
  }

  & > .cancel-different-account-requested {
    text-align: left;
    padding: 16px 20px;
    border-bottom: 1px solid #ececec;

    & > a {
      font-size: 13.5px;
      color: #0370f5;
      &:hover {
        color: #005bca;
      }
      &:active {
        color: #003e8a;
      }
      cursor: pointer;

      & > i {
        font-size: 12px;
        margin-right: 8px;
      }
    }
  }
`;

const CurrentSettingsSection = styled.div`
  display: relative;
  width: 100%;

  a {
    color: #0370f5;
    &:hover {
      color: #005bca;
    }
    &:active {
      color: #003e8a;
    }
    cursor: pointer;
  }

  &.connected > .user-email-details-container {
    border: 2px solid #ececec;
  }

  &.expired > .user-email-details-container {
    border: 2px solid #f9aa00;

    & > .email-details-row {
      padding-bottom: 12px;
    }

    & > .email-status-row {
      position: absolute;
      left: 50%;
      margin-top: 5px;
      margin-left: -155px;
      padding: 0 12px;
      background: #ffffff;

      & > .email-status-message {
        margin-right: 4px;
      }

      & > .email-status-action {
        font-weight: 600;
      }
    }
  }

  & > .gray-background-extension {
    position: absolute;
    width: 100%;
    height: 48px;
    background: #f6f6f6;
    z-index: 0;
  }

  & > .user-email-details-container {
    position: relative;
    margin: 0 16px;
    padding: 16px 20px;
    border-radius: 6px;
    background: #ffffff;
    z-index: 1;

    & > .email-details-row,
    & > .email-status-row {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    & > .email-details-row {
      align-items: stretch;
      padding-bottom: 24px;

      & > .email-info {
        & > .email-address {
          max-width: 340px;
          font-size: 18px;
          color: #1e1e1e;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        & > .email-provider-description {
          font-size: 13px;
          color: #707070;
        }
      }

      & > .email-provider-logo {
        padding-top: 2px;
        text-align: right;
      }
    }

    & > .email-status-row {
      align-items: center;
      font-size: 14px;

      & > .email-status-message {
        text-align: left;
      }

      & > .email-status-action {
        text-align: right;
      }
    }
  }

  & > .use-different-account {
    text-align: center;
    margin-top: 64px;

    & > .different-account-link {
      font-size: 13.5px;

      & > i {
        margin-left: 8px;
        font-size: 12px;
      }
    }
  }
`;

const OAuthStatusMessage = styled.div`
  & > i {
    margin-right: 6px;
  }
  & > span {
    font-weight: 600;
  }

  &.oauth-connected-message {
    color: #707070;
    & > i {
      color: #14af80;
    }
  }

  &.oauth-expired-message {
    color: #1e1e1e;
    & > i {
      color: #f9aa00;
    }
  }
`;
