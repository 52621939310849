import * as React from "react";
import styled, { withTheme } from "styled-components";
import { DropdownButton as BootstrapDropdownButton } from "react-bootstrap";
import { Theme } from "../themes";
import * as ConcreteColors from "../ConcreteColors";

export type DropdownButtonProps = {
  color?: string;
  inverted?: boolean;
  theme?: Theme;
  testId?: string;
} & BootstrapDropdownButton.DropdownButtonProps;

const StyledDropdownButton = styled(
  ({ backgroundColor, foregroundColor, borderColor, baseTheme, ...rest }) => (
    <BootstrapDropdownButton {...rest} />
  )
)<{
  backgroundColor: string;
  foregroundColor: string;
  borderColor: string;
  baseTheme: string;
}>`
  border-radius: ${props => (props.baseTheme === "Concrete" ? "32px" : "0px")};

  &,
  &:hover,
  &:focus,
  &:active,
  &:active:focus,
  .open > &.dropdown-toggle.btn-default,
  .open > &.dropdown-toggle.btn-default:focus {
    color: ${props => props.foregroundColor};
    border-color: ${props => props.borderColor};
    background-color: ${props => props.backgroundColor};
  }
  :hover:not(:disabled) {
    box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
      rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  }
  &.btn .caret {
    margin-left: 5px;
  }
  &:disabled,
  &:disabled:hover {
    background-color: #f9f9f9;
    color: #696969;
    border-color: #ccc;
    opacity: 1;
    box-shadow: none;
  }
`;

export function DropdownButton({
  children,
  color,
  inverted = false,
  testId,
  ...props
}: DropdownButtonProps) {
  let buttonBackgroundColor = ConcreteColors.gray700;
  let buttonForegroundColor = "white";
  let buttonBorderColor = ConcreteColors.gray700;
  let defaultTestId = "DropdownButton";

  if (color) {
    buttonBackgroundColor = color;
    buttonBorderColor = color;
  } else if (props.theme && props.theme.buttonColor) {
    buttonBackgroundColor = props.theme.buttonColor;
    buttonBorderColor = props.theme.buttonColor;
  }

  if (inverted) {
    buttonForegroundColor = buttonBackgroundColor;
    buttonBackgroundColor = "white";
  }

  let baseTheme = "";
  if (props.theme) {
    baseTheme = props.theme.baseTheme;
  }

  if (testId) {
    defaultTestId = testId;
  }

  return (
    <>
      <StyledDropdownButton
        backgroundColor={buttonBackgroundColor}
        foregroundColor={buttonForegroundColor}
        borderColor={buttonBorderColor}
        baseTheme={baseTheme}
        data-testid={defaultTestId}
        {...(props as any)}
      >
        {children}
      </StyledDropdownButton>
    </>
  );
}

var ThemedDropdownButton = withTheme(DropdownButton);

export default ThemedDropdownButton;
