import {
  TableSummaryRow as DTableSummaryRow,
  TableSummaryRowProps
} from "@devexpress/dx-react-grid-bootstrap3";
import numeral from "numeral";
import * as React from "react";
import { SummaryType } from "../models";

type ExtendedMessages = TableSummaryRowProps["messages"] & {
  sumDefaultZero?: string;
  filteredSum?: string;
};

export const TableSummaryRow = ({
  messages,
  ...other
}: Omit<TableSummaryRowProps, "messages"> & {
  messages?: ExtendedMessages;
}) => {
  const mergedMessages = {
    filteredSum: "Sum",
    [SummaryType.Sum]: "Sum",
    [SummaryType.Avg]: "Avg",
    [SummaryType.Count]: "Count",
    ...messages
  };
  return (
    <DTableSummaryRow
      messages={mergedMessages as any}
      itemComponent={CellItemComponent}
      formatlessSummaryTypes={["filteredSum"]}
      {...other}
    />
  );
};

const CellItemComponent = (props: DTableSummaryRow.ItemProps) => {
  if (props.type === "filteredSum") {
    return (
      <div>
        <strong>{numeral(props.value).format("$0,0.00")}</strong>
      </div>
    );
  }

  const DefaultItem: any = DTableSummaryRow.Item;
  return <DefaultItem {...props} />;
};
