export interface OutboxConfiguration {
  userId: string;
  baseApiUrl: string;
  baseOutboxAuthUrl: string;
  getAccessToken: () => string;
  onUpdate?: (updatedSettings: OAuthInfoModel) => void;
}

export interface OAuthConfiguration extends OutboxConfiguration {
  provider: OAuthProvider;
}

export interface OAuthInfoModel {
  email?: string;
  needsRefresh: boolean;
}

export enum OAuthProvider {
  Microsoft = "Microsoft",
  Google = "Google",
  Yahoo = "Yahoo",
  AOL = "AOL"
}
