import { IntegratedSorting as DIntegratedSorting } from "@devexpress/dx-react-grid";
import * as React from "react";
import { DataColumnType, TypedDataColumn } from "../models";
import { useTableContext } from "../table-context";
import {
  booleanCompare,
  checkListCompare,
  dateCompare,
  dateTimeCompare,
  linkCompare,
  numberCompare,
  stringCompare,
  listCompare,
  defaultCustomCompare
} from "./sorting-functions";

export const IntegratedSorting = () => {
  const { columns } = useTableContext();

  return React.useMemo(() => {
    // console.log("sorting extensions");
    const extensions = generateSortingExtensions(columns);
    return <DIntegratedSorting columnExtensions={extensions} />;
  }, [columns]);
};

export function generateSortingExtensions(
  columns: TypedDataColumn[]
): DIntegratedSorting.ColumnExtension[] {
  return columns.map(
    column =>
      ({
        columnName: column.name,
        compare: getComparer(column)
      } as DIntegratedSorting.ColumnExtension)
  );
}

function getComparer(column: TypedDataColumn) {
  if (
    column.type === DataColumnType.Number ||
    column.type === DataColumnType.Currency
  ) {
    return numberCompare;
  }

  if (column.type === DataColumnType.Date) {
    return dateCompare;
  }

  if (column.type === DataColumnType.DateTime) {
    return dateTimeCompare;
  }

  if (column.type === DataColumnType.Boolean) {
    return booleanCompare;
  }

  if (column.type === DataColumnType.Links) {
    return linkCompare;
  }

  if (column.type === DataColumnType.CheckList) {
    return checkListCompare;
  }

  if (column.type === DataColumnType.List) {
    return listCompare.bind(null, column.config!);
  }

  if (column.type === DataColumnType.Custom) {
    if (column.config) {
      return column.config?.sortingFunction;
    }

    return defaultCustomCompare;
  }

  return stringCompare;
}
