import * as React from "react";
import styled from "styled-components";
import AsideSection from "./AsideSection";
//need to import ace-elements and ace-extra
import * as ConcreteColors from "../../ConcreteColors";
import * as AceColors from "../../AceColors";

const AsideWrapper = styled("div")`
  position: absolute;
  z-index: 998;
  right: 0;
  top: auto;
`;

const AsideContent = styled.div<{ maxHeight: number; maxWidth?: string }>`
  display: block;
  background-color: white;
  border: 0px solid
    ${(props: any) =>
      props.theme.baseTheme === "Ace"
        ? AceColors.gray300
        : ConcreteColors.gray300};
  box-shadow: -2px 1px 2px 0 rgba(0, 0, 0, 0.15);
  border-radius: 0px;
  overflow-y: auto;
  width: auto;
  max-width: 0;
  max-height: 0;
  float: left;
  transition: max-width 0.25s linear 0s, max-height 0s linear 0.25s,
    padding 0s linear 0.25s, border-width 0s linear 0.25s;

  &.show {
    max-width: ${props => (props.maxWidth ? props.maxWidth : "420px")};
    max-height: ${props => props.maxHeight}px;
    transition-delay: 0s;
  }
`;

const AsideHeader = styled("div")`
  font-size: 1.5em;
  background-color: ${(props: any) =>
    props.theme.baseTheme === "Ace" ? props.theme.navbarColor : "white"};
  color: ${(props: any) =>
    props.theme.baseTheme === "Ace" ? "white" : "black"};
  height: 40px;
  font-weight: 600;
  line-height: 1.8;
  padding-left: 0.5em;
  white-space: nowrap;
  overflow: hidden;

  & > button {
    border: 0px;
    background: none;
    opacity: 0.75;
    height: 40px;
    color: white;
    cursor: pointer;
    outline: none;
    font-size: 1.5em;
    line-height: 0.5;
    float: right;

    &:hover {
      opacity: 1;
    }
  }
`;

const AsideBody = styled("div")`
  overflow: hidden;
`;

const ModalButton = styled("button")`
  width: 42px;
  height: 40px;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 6px 0px 0px 6px;
  padding: 6px 0 8px;
  line-height: 1.7;
  position: relative;
  background: repeat-x
    ${(props: any) =>
      props.theme.baseTheme === "Ace"
        ? "#ffb44b"
        : ConcreteColors.blue200} !important;
  z-index: 999;
  position: relative;
  float: left;
  outline: 0;
`;

export interface AsideProps extends React.HTMLProps<HTMLInputElement> {
  /** Title of the modal aside */
  title?: string;
  /** The icon used to close the modal aside */
  closeIcon?: string;
  /** The icon used to open the modal aside. If you want to set the same icon to both close and open state then pass this prop only */
  openIcon?: string;
  /** (Optional) The maximum width for the panel (for example, "50vw" or "500px") */
  panelMaxWidth?: string;
  /** (Optional) Set to true if the panel should load in the open position (💙 HJW). Ignored if you set 'open' */
  defaultOpen?: boolean;
  /** (Optional) Use this prop if you want to manually open and close the aside. If this is set, the open and close buttons will use callback functions instead of setting the state themselves.  */
  open?: boolean;
  /** (Optional) Only fires when the aside is controlled using the "open" prop*/
  onOpen?: () => void;
  /** (Optional) Only fires when the aside is controlled using the "open" prop*/
  onClose?: () => void;
}

export interface AsideState {
  show: boolean;
}

// This needs to be a class so that it can export the AsideSection as a static property
export default class ModalAside extends React.Component<
  AsideProps,
  AsideState
> {
  constructor(props: AsideProps) {
    super(props);

    if (props.defaultOpen && typeof props.open !== "boolean") {
      this.state = { show: true };
    } else {
      this.state = { show: false };
    }
  }

  handleOpen = () => {
    // if we actually set the 'open' prop
    if (typeof this.props.open === "boolean" && this.props.onOpen) {
      // controlled behavior
      this.props.onOpen();
    } else {
      // uncontrolled behavior
      this.setState({ show: true });
    }
  };

  handleClose = () => {
    // if we actually set the 'open' prop
    if (typeof this.props.open === "boolean" && this.props.onClose) {
      // controlled behavior
      this.props.onClose();
    } else {
      // uncontrolled behavior
      this.setState({ show: false });
    }
  };

  toggleClick = () => {
    if (this.state.show === true) {
      this.handleClose();
    } else {
      this.handleOpen();
    }
  };

  componentDidUpdate() {
    if (this.props.open === true && this.state.show === false) {
      this.setState({ show: true });
    }
    if (this.props.open === false && this.state.show === true) {
      this.setState({ show: false });
    }
  }

  render() {
    const maxHeight = Math.floor(window.innerHeight * 0.9);
    const open = this.props.openIcon ?? "fa fa-plus";
    const close = this.props.closeIcon ?? "fa fa-minus";

    return (
      <AsideWrapper className="modal-aside-wrapper">
        <ModalButton onClick={this.toggleClick} className="modal-aside-button">
          <i className={this.state.show ? close : open} />
        </ModalButton>

        <AsideContent
          maxHeight={maxHeight}
          className={(this.state.show ? "show" : "") + " modal-aside-content"}
          maxWidth={this.props.panelMaxWidth}
        >
          <AsideHeader className="modal-aside-header">
            {this.props.title}
            <button type="button" onClick={this.handleClose}>
              <span className="white">&times;</span>
            </button>
          </AsideHeader>

          <AsideBody className="modal-aside-body">
            {this.props.children}
          </AsideBody>
        </AsideContent>
      </AsideWrapper>
    );
  }

  static Section = AsideSection;
}
