import constate from "constate";
import { useState, useCallback, useRef, useLayoutEffect } from "react";

function usePrint() {
  const [isPrinting, _setIsPrinting] = useState(false);
  const resolve = useRef<any>();

  // the reason for the resolve ref and useLayoutEffect is so that we prevent the print preview window
  // from loading until the dom is adjusted for any conditional logic related to printing

  const setIsPrinting = useCallback((isPrinting: boolean, cb?: any) => {
    if (cb) {
      resolve.current = cb;
    }
    _setIsPrinting(isPrinting);
  }, []);
  const contentRef = useRef<any>();

  useLayoutEffect(() => {
    if (isPrinting && resolve.current) {
      resolve.current();
      resolve.current = null;
    }
  }, [isPrinting]);

  return { isPrinting, setIsPrinting, contentRef };
}

export const [PrintProvider, usePrintContext] = constate(usePrint);
