import { DashboardModal } from "@uppy/react";
import React from "react";
import { useSmartDriveContext } from "../smartdrive-context";

export const SmartDriveUploadModal: React.FC = () => {
  const {
    uppy: { instance, ui }
  } = useSmartDriveContext();

  if (!instance) {
    return null;
  }

  return (
    <>
      <UppyDummyTrigger />
      <DashboardModal
        trigger="#uppy-select-files"
        uppy={instance}
        open={ui.showUploadModal}
        onRequestClose={() => ui.toggleFileModal(false)}
        locale={{
          strings: {
            dropPaste: "Drop files here, or %{browse}"
          }
        }}
      />
    </>
  );
};

// This is just so we don't get the console error from uppy
const UppyDummyTrigger = () => (
  <div style={{ display: "none" }} id="uppy-select-files" />
);
