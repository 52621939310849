import * as React from "react";
import styled from "styled-components";
const DropdownGroup = styled.ul`
  list-style: none;
  margin: 0;
  -webkit-padding-start: 0;
`;

const DropdownToggle = styled.a.attrs(() => ({ href: "#" }))`
  display: block;
  border: none;
  color: white;
  height: 100%;
  padding: 0 8px;
  text-align: left;
  &:focus {
    outline-color: transparent;
  }
`;

const DropdownButton = styled(DropdownToggle)`
  background-color: ${props => props.theme.navDropdownButtonColor};
  &:hover {
    background-color: ${props => props.theme.navDropdownButtonHoverColor};
  }
`;

const DropdownIcon = styled.i`
  font-size: 36px;
`;

export type DropdownProps = {
  className?: string;
  children: any;
};

export type DropdownState = {
  isOpen: boolean;
};

class UnstyledDropdown extends React.Component<DropdownProps, DropdownState> {
  private dropdownToggleRef: React.RefObject<any> = React.createRef();
  private dropdownMenuRef: React.RefObject<any> = React.createRef();

  constructor(props: DropdownProps) {
    super(props);
    this.state = { isOpen: false };
    if (props.children.length !== 2) {
      throw new Error("Dropdown requires dropdown toggle and dropdown menu");
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.toggleClose);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.toggleClose);
  }

  toggleClose = (event: any) => {
    if (
      this.dropdownToggleRef.current &&
      !this.dropdownToggleRef.current.contains(event.target) &&
      this.dropdownMenuRef.current &&
      !this.dropdownMenuRef.current.contains(event.target)
    ) {
      this.setState((prevState, props) => {
        return { isOpen: false };
      });
    }
  };

  handleToggleFromMenu = (event: any) => {
    this.setState((prevState, props) => {
      return { isOpen: !prevState.isOpen };
    });
  };

  handleToggle = (event: any) => {
    event.preventDefault();
    this.setState((prevState, props) => {
      return { isOpen: !prevState.isOpen };
    });
  };

  render() {
    //inner ref is used for styled components and ref should be used if dom element
    //currently only supporting inner ref as there isn't a use case yet for ref
    const dropdownToggle = React.cloneElement(
      this.props.children[0],
      {
        onClick: this.handleToggle,
        ref: this.dropdownToggleRef
      },
      this.props.children[0].props.children
    );

    const dropdownMenu = React.cloneElement(
      this.props.children[1],
      {
        isOpen: this.state.isOpen,
        onClick: this.handleToggleFromMenu,
        ref: this.dropdownMenuRef
      },
      this.props.children[1].props.children
    );

    return (
      <li className={this.props.className}>
        {dropdownToggle}
        {dropdownMenu}
      </li>
    );
  }
}

const StyledDropdown = styled(UnstyledDropdown)`
  float: left;
  line-height: 45px;
  height: 45px;
  margin-left: 2px;
  position: relative;
`;

export default class Dropdown extends React.Component<DropdownProps> {
  static Group = DropdownGroup;
  static Toggle = DropdownToggle;
  static ToggleButton = DropdownButton;
  static Icon = DropdownIcon;
  render() {
    const { children, ...props } = this.props;
    return <StyledDropdown {...props}>{children}</StyledDropdown>;
  }
}
