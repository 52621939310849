import { TableEditColumn } from "@devexpress/dx-react-grid-bootstrap3";
import { Button, Icon } from "hcss-components";
import * as React from "react";
import styled from "styled-components";

export const commandComponentProps: Record<
  string,
  { icon: string; text: string; hint: string; isDanger?: boolean }
> = {
  add: {
    icon: "plus",
    text: "Add",
    hint: "Add Entry"
  },
  edit: {
    text: "",
    icon: "pencil",
    hint: "Edit Entry"
  },
  delete: {
    icon: "trash-o",
    hint: "Delete Entry",
    isDanger: true,
    text: ""
  },
  commit: {
    icon: "save",
    text: "",
    hint: "Save changes"
  },
  cancel: {
    icon: "undo",
    hint: "Cancel changes",
    text: ""
  }
};

export interface CommandButtonProps extends TableEditColumn.CommandProps {
  icon?: string;
  hint?: string;
  isDanger?: boolean;
  validationFunction?: (tableRow: any) => boolean;
  tableRow?: any;
  disabled?: boolean;
}

export const CommandButton = ({
  onExecute,
  icon,
  text,
  hint,
  isDanger,
  id,
  disabled = false,
  ...props
}: CommandButtonProps) => {
  let children;
  if (id === "add") {
    children = (
      <TableAddButton onClick={onExecute} title={hint} disabled={disabled}>
        {icon && <Icon name={icon} margin="right" />}
        {text}
      </TableAddButton>
    );
  } else if (id === "commit") {
    children = (
      <RowCommandButton
        className={isDanger ? "text-danger" : undefined}
        onClick={e => {
          if (props.validationFunction) {
            const valid = props.validationFunction(props.tableRow);
            if (!valid) {
              return;
            }
          }
          onExecute();
          e.stopPropagation();
        }}
        title={hint}
      >
        {icon && <Icon name={icon} margin={text ? "right" : undefined} />}
      </RowCommandButton>
    );
  } else {
    children = (
      <RowCommandButton
        className={isDanger ? "text-danger" : undefined}
        onClick={!disabled ? onExecute : undefined}
        title={hint}
      >
        {icon && (
          <Icon
            name={icon}
            margin={text ? "right" : undefined}
            style={{ color: disabled ? "#CCCCCC" : "inherit" }}
          />
        )}
      </RowCommandButton>
    );
  }
  return children;
};

export const CommandComponent: React.FunctionComponent<any> = props => {
  return <CommandButton {...props} {...commandComponentProps[props.id]} />;
};

export const EditCellComponent = (props: TableEditColumn.CellProps) => {
  return (
    <td>
      <WrapperDiv>
        <InnerDiv>
          {React.Children.map(props.children as any, (child: any) =>
            React.isValidElement(child)
              ? React.cloneElement<any>(child, {
                  ...(child.props as object),
                  tableRow: props.tableRow
                } as any)
              : child
          )}
        </InnerDiv>
      </WrapperDiv>
    </td>
  );
};

const TableAddButton = styled(Button)`
  &.btn {
    padding: 4px 12px;
  }
`;

export const EditCellHeaderComponent = (
  props: TableEditColumn.HeaderCellProps
) => {
  return (
    <th style={{ padding: "4px" }}>
      <WrapperDiv>
        <InnerDiv>{props.children}</InnerDiv>
      </WrapperDiv>
    </th>
  );
};

const RowCommandButton = styled.span`
  padding: 5px 10px;
  cursor: pointer;
`;

const WrapperDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

const InnerDiv = styled.div`
  width: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: visible;
  text-overflow: ellipsis;
`;
