import { Button, Dropdown, Icon, MenuItem } from "hcss-components";
import * as React from "react";
import styled from "styled-components";

export interface ToolbarIconButtonProps {
  iconName: string;
  onClick?: () => any;
  className?: string;
  title?: string;
  margin?: "left" | "right";
  dropdown?: boolean;
  options?: Array<{ name: string; onClick: () => any }>;
  children?: any;
}

export class ToolbarIconButton extends React.PureComponent<
  ToolbarIconButtonProps
> {
  render() {
    const {
      iconName,
      onClick,
      className,
      title,
      margin,
      dropdown,
      options
    } = this.props;

    if (dropdown) {
      return (
        <Dropdown id={`tableoptions-${iconName}`} pullRight>
          <Dropdown.Toggle bsStyle="link" noCaret>
            <StyledIcon name={iconName} margin={margin} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {options!.map((option, index) => (
              <MenuItem
                key={option.name}
                eventKey={index}
                onClick={option.onClick}
              >
                {option.name}
              </MenuItem>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    return (
      <Button
        className={className}
        title={title}
        bsStyle="link"
        onClick={onClick}
      >
        <StyledIcon name={iconName} margin={margin} />
      </Button>
    );
  }
}

const StyledIcon = styled(Icon)`
  color: #333;
`;
