import { useLocalStorage } from "hcss-hooks";

export function useColoring(templateName: string) {
  const [coloringColumnName, setColoringColumnName] = useLocalStorage<string>(
    `${templateName}.coloring`,
    ""
  );

  return { coloringColumnName, setColoringColumnName };
}
