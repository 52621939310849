import * as React from "react";

export interface TextFieldProps extends React.HTMLProps<HTMLInputElement> {
  /** Specify number of pixels of padding for the left side of the field. Omit to use the default */
  leftUnit?: any;
  /** Specify number of pixels of padding for the right side of the field. Omit to use the default */
  rightUnit?: any;
}

export type TextFieldState = {
  paddingStyle: Object;
};

export default class TextField extends React.Component<
  TextFieldProps,
  TextFieldState
> {
  private leftLabel: HTMLLabelElement | null = null;
  private rightLabel: HTMLLabelElement | null = null;

  constructor(props: TextFieldProps) {
    super(props);
    this.state = {
      paddingStyle: {}
    };
  }

  componentDidMount() {
    let leftPadding =
      this.props.leftUnit && this.leftLabel
        ? this.leftLabel.offsetWidth + 10 + "px"
        : "4px";
    let rightPadding =
      this.props.rightUnit && this.rightLabel
        ? this.rightLabel.offsetWidth + 10 + "px"
        : "4px";
    let fieldWidth =
      this.props.leftUnit || this.props.rightUnit ? "180px" : "auto";

    if (this.props.leftUnit || this.props.rightUnit) {
      this.setState({
        paddingStyle: {
          paddingLeft: leftPadding,
          paddingRight: rightPadding,
          width: fieldWidth
        }
      });
    }
  }

  render() {
    let { leftUnit, rightUnit, ...other } = this.props;
    var base = <input type="text" style={this.state.paddingStyle} {...other} />;
    var left = this.props.leftUnit ? (
      <label ref={ref => (this.leftLabel = ref)} className="input-group-unit">
        {leftUnit}
      </label>
    ) : null;
    var right = this.props.rightUnit ? (
      <label ref={ref => (this.rightLabel = ref)} className="input-group-unit">
        {rightUnit}
      </label>
    ) : null;

    if (this.props.rightUnit || this.props.leftUnit) {
      return (
        <div className="input-group input-group-sm">
          {left}
          {base}
          {right}
        </div>
      );
    }

    return base;
  }
}
