import * as React from "react";
import { Plugin, Getter } from "@devexpress/dx-react-core";

export const AllRows = () => {
  return (
    <Plugin>
      <Getter
        name="allRows"
        computed={({ rows, isGroupRow, getCollapsedRows }) => {
          const detailRows = [];
          const groupRows = [];
          let rowsToSearch = [...rows];

          while (rowsToSearch.length > 0) {
            const row = rowsToSearch.shift();
            if (isGroupRow(row)) {
              groupRows.push(row);
              const newRows = getCollapsedRows(row);
              if (newRows && newRows.length > 0) {
                rowsToSearch = newRows.concat(rowsToSearch);
              }
            } else {
              detailRows.push(row);
            }
          }

          return { detailRows, groupRows };
        }}
      />
    </Plugin>
  );
};
