import * as React from "react";
import styled from "styled-components";
import Dropdown from "./Dropdown";
import Icon from "../Icon";
import { gray600, gray400 } from "../../AceColors";

export type HamburgerMenuProps = {
  children: any;
};

const DropdownButton = styled(Dropdown.Toggle)`
  background: inherit;
  padding: 0 20px 0 8px;
  &:hover {
    opacity: 0.7;
  }
`;
const HamburgerMenuDropdownIcon = styled(Dropdown.Icon)`
  font-size: 17pt;
  margin-top: 8px;
`;

const HackedDropdownMenu = styled.ul`
  z-index: 1031;
  top: 99%;
  position: absolute;
  list-style: none;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  -webkit-padding-start: 0;
  margin-top: 0;
  & > li p {
    white-space: normal;
  }

  & > li:hover > a {
    background: unset;
    color: unset;
  }
  &#dropdownList > li > a:hover {
    background-color: rgb(245, 245, 245);
  }
  display: ${(props: any) => (props.isOpen ? "block" : "none")};

  .btn-home {
    margin: 15px 0;
    div {
      text-align: center;
      a {
        color: ${gray600};
        border: 1px solid ${gray400};
        padding: 10px 15px;
        font-size: 12px;
        border-radius: 5px;
        i {
          margin-right: 2px;
        }
      }
    }
  }
`;

/** NEEDS SOME WORK: Currently hacking it */
export default class HamburgerMenu extends React.Component<
  HamburgerMenuProps,
  {}
> {
  getHomeLink = () => {
    let loc = window.location.href;
    if (loc.includes("dev")) return "https://dev.hcssapps.com";
    else if (loc.includes("staging")) return "https://staging.hcssapps.com";
    else return "https://hcssapps.com";
  };

  render() {
    let homeUrl: string = this.getHomeLink();

    return (
      <Dropdown.Group>
        <Dropdown>
          <DropdownButton>
            <HamburgerMenuDropdownIcon className="fa fa-bars" />
          </DropdownButton>
          <HackedDropdownMenu
            id="dropdownList"
            className="user-menu dropdown-menu-left dropdown-menu"
          >
            {this.props.children}
            <li className="btn-home">
              <div>
                <a href={homeUrl}>
                  <Icon name="home" />
                  HCSS Apps Home
                </a>
              </div>
            </li>
          </HackedDropdownMenu>
        </Dropdown>
      </Dropdown.Group>
    );
  }
}
