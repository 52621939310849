import * as React from "react";
import classNames from "classnames";
import styled from "styled-components";
import { WidgetContext } from "./Context";

export interface WidgetTitleProps extends React.HTMLProps<HTMLHeadingElement> {
  disabled?: boolean;
}

const Title = ({ className, ref, children, ...props }: WidgetTitleProps) => {
  const context = React.useContext(WidgetContext);
  return (
    <StyledWidgetTitle
      disabled={props.disabled}
      className={classNames(
        className,
        { collapse: context.handleToggle },
        { in: context.open }
      )}
      {...(props as any)}
      onClick={context.handleToggle}
    >
      {children}
    </StyledWidgetTitle>
  );
};

export default Title;

const StyledWidgetTitle = styled.h4<{ disabled?: boolean }>`
  font-weight: ${props => (props.disabled ? "normal" : "bold")};
  color: ${props => (props.disabled ? "#707070" : "inherit")};

  &.collapse {
    cursor: pointer;
    &::before {
      content: "\\f105";
      font-family: "FontAwesome";
      margin-right: 10px;
      width: 12px;
    }
  }
  &.collapse.in {
    &::before {
      content: "\\f107";
    }
    display: inline;
  }

  line-height: 36px;
  padding: 0;
  margin: 0;
  display: inline;

  & > i,
  & > .ace-icon {
    margin-right: 0.7em;
    font-weight: 400;
    display: inline-block;
  }
`;
