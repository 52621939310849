// Default Blues

export const blue400 = "#003E8A";
export const blue300 = "#005BCA";
export const blue200 = "#0370F5";
export const blue150 = "#DFEFFB";
export const blue100 = "#ECF5FE";

// Success Greens
export const green500 = "#072B21";
export const green400 = "#0C4B39";
export const green300 = "#11654D";
export const green200 = "#14AF80";
export const green100 = "#EEFBF5";

// Error Reds
export const red400 = "#6E0303";
export const red300 = "#9E0A0A";
export const red200 = "#DB1010";
export const red100 = "#FFEEEE";

// Concrete Greys
export const gray700 = "#1E1E1E";
// Formerly stoneGray = #3e3e3e
export const gray600 = "#404040";
export const gray500 = "#707070";
export const gray400 = "#CCCCCC";
export const gray300 = "#ECECEC";
export const gray200 = "#F0F0F0";
export const gray100 = "#F6F6F6";

//Highlight Yellows
export const yellow100 = "#FFF2BC";
//Used to highlight features that may be unexpectedly active (i.e. if search text is preserved when you come back to a table)
