import * as React from "react";
import { MenuItem, SubMenu } from "react-contextmenu";
import { DataColumnType, TypedDataColumn } from "../models";
import { MenuItemHeader } from "./styles";
import { useContextMenu } from "./use-context-menu";
import { Icon } from "hcss-components";

export interface HeaderMenuItemsProps {
  columnName: string;
  menuItems?: {
    showGrouping?: boolean;
    showSorting?: boolean;
    showVisibility?: boolean;
    showHighlight?: boolean;
  };
  messages?: {
    sortAscending?: string;
    sortDescending?: string;
    group?: string;
    dateGrouping?: {
      year?: string;
      month?: string;
      quarter?: string;
      week?: string;
      day?: string;
    };
    highlight?: {
      on?: string;
      off?: string;
    };
    hide?: string;
  };
}

export const HeaderMenuItems: React.FunctionComponent<HeaderMenuItemsProps> = ({
  columnName,
  messages,
  menuItems = {}
}) => {
  const {
    showGrouping = true,
    showSorting = true,
    showVisibility = true,
    showHighlight = true
  } = menuItems;
  const {
    handleGroup,
    handleHide,
    handleSort,
    groupingEnabled,
    nextSortingValue,
    getColumn,
    handleHighlight,
    isHighlightedColumn
  } = useContextMenu();
  const column = getColumn(columnName);

  if (!column) {
    return null;
  }

  const highlightable =
    column.type === DataColumnType.List ||
    column.type === DataColumnType.States;
  const highlightMenuLabel = isHighlightedColumn(columnName)
    ? messages?.highlight?.off ?? "Clear Highlight"
    : messages?.highlight?.on ?? "Highlight";

  const getSortString = () => {
    const sortValue = nextSortingValue(columnName);
    if (sortValue === "asc") {
      return messages?.sortAscending ?? "Sort Ascending";
    }

    return messages?.sortDescending ?? "Sort Descending";
  };

  return (
    <>
      <MenuItemHeader>{column.title}</MenuItemHeader>
      {showSorting && (
        <MenuItem onClick={handleSort}>{getSortString()}</MenuItem>
      )}
      {showGrouping && groupingEnabled(column.name) && (
        <GroupMenu
          handleGroup={handleGroup}
          column={column}
          messages={messages}
        />
      )}
      {showHighlight && highlightable && (
        <MenuItem onClick={handleHighlight}>{highlightMenuLabel}</MenuItem>
      )}
      {showVisibility && column.hidingEnabled && (
        <MenuItem onClick={handleHide}>{messages?.hide ?? `Hide`}</MenuItem>
      )}
    </>
  );
};

export interface GroupMenuProps {
  column: TypedDataColumn;
  handleGroup: any;
  messages: HeaderMenuItemsProps["messages"];
}
const GroupMenu = ({ column, handleGroup, messages }: GroupMenuProps) => {
  if (
    column.type === DataColumnType.DateTime ||
    column.type === DataColumnType.Date
  ) {
    return (
      // @ts-ignore
      <SubMenu
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline"
            }}
          >
            <span>{messages?.group ?? "Group"}</span>
            <Icon
              name="chevron-right"
              style={{ position: "relative", right: "-15px" }}
            />
          </div>
        }
      >
        <MenuItem
          onClick={(e: any, passed: any) =>
            handleGroup(e, passed, { dateGrouping: "year" })
          }
        >
          {messages?.dateGrouping?.year ?? "Year"}
        </MenuItem>
        <MenuItem
          onClick={(e: any, passed: any) => {
            handleGroup(e, passed, { dateGrouping: "quarter" });
          }}
        >
          {messages?.dateGrouping?.quarter ?? "Quarter"}
        </MenuItem>

        <MenuItem
          onClick={(e: any, passed: any) =>
            handleGroup(e, passed, { dateGrouping: "month" })
          }
        >
          {messages?.dateGrouping?.month ?? "Month"}
        </MenuItem>
        <MenuItem
          onClick={(e: any, passed: any) =>
            handleGroup(e, passed, { dateGrouping: "week" })
          }
        >
          {messages?.dateGrouping?.week ?? "Week"}
        </MenuItem>
        <MenuItem
          onClick={(e: any, passed: any) =>
            handleGroup(e, passed, { dateGrouping: "day" })
          }
        >
          {messages?.dateGrouping?.day ?? "Day"}
        </MenuItem>
      </SubMenu>
    );
  }
  return (
    <MenuItem onClick={handleGroup}>{messages?.group ?? `Group`}</MenuItem>
  );
};

// quick filter based on type (e.g. string "Add 'road' to current filters,
// date "show projects within 7 days of filter")
