import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { ProfileMenuItemProps } from "./Models";
import { Icon } from "../../../Icon";
import * as ConcreteColors from "../../../../ConcreteColors";
import styled, { css } from "styled-components";

const ProfileMenuItem: React.FC<ProfileMenuItemProps> = (
  props: ProfileMenuItemProps
) => {
  // Destructure props
  const { label, status, linkTo, onClick, testId, title, ...rest } = props;

  // Render the label of the menu item.
  const itemLabel = useMemo(() => {
    return (
      <MenuItemLabel
        className="profile-menu-item-label"
        data-testid="profile-menu-item-label"
      >
        {label}
      </MenuItemLabel>
    );
  }, [label]);

  // Render the current status of the setting this item is related
  // to, if provided a status
  const itemStatus = useMemo(() => {
    if (!!status)
      return (
        <MenuItemStatus
          className="profile-menu-item-status"
          data-testid="profile-menu-item-status"
        >
          {status}
        </MenuItemStatus>
      );
    return null;
  }, [status]);

  // Render a right-pointing caret as a click affordance, if a status
  // is provided (i.e. if this is "setting" item)
  const clickAffordance = useMemo(() => {
    if (!!status)
      return (
        <MenuItemClickAffordance
          className="profile-menu-item-click-affordance"
          data-testid="profile-menu-item-click-affordance"
        >
          <Icon name="angle-right" />
        </MenuItemClickAffordance>
      );
    return null;
  }, [status]);

  // Render the click target that houses the menu item.
  // -- If a value is provided for "onClick", this click target will be
  //    a "button" DOM node
  // -- If a value if provided for "linkTo" this click target will be an
  //    anchor DOM node
  // -- If a value is passed for both, an anchor tag will be rendered, but
  //    both the link (href) and "onClick" values will be passed to it.
  // -- If no value is passed for either, a static item will be rendered
  //    and a warning will be thrown.
  const clickTarget = useMemo(() => {
    const itemTypeClass = !!status ? "setting-item" : "standard-item";

    if (!linkTo && !onClick) {
      console.warn(
        `Warning: Each ProfileMenu item should have a value for either the linkTo or the onClick property
[HCSS Components Bundle | LayoutConcrete.ProfileMenu.ProfileMenuItem]`
      );
      return (
        <div
          className={`profile-menu-static-item static-item ${itemTypeClass}`}
          data-testid="profile-menu-item-click-target"
          title={title}
        >
          {itemLabel}
          {itemStatus}
          {clickAffordance}
        </div>
      );
    }
    if (linkTo)
      return (
        <Link
          className={`profile-menu-item link-item ${itemTypeClass}`}
          data-testid="profile-menu-item-click-target"
          to={linkTo || ""}
          onClick={onClick}
          title={title}
        >
          {itemLabel}
          {itemStatus}
          {clickAffordance}
        </Link>
      );
    return (
      <button
        className={`profile-menu-item button-item ${itemTypeClass}`}
        data-testid="profile-menu-item-click-target"
        onClick={onClick}
        title={title}
      >
        {itemLabel}
        {itemStatus}
        {clickAffordance}
      </button>
    );
  }, [status, linkTo, onClick, title, itemLabel, itemStatus, clickAffordance]);

  //
  //
  //
  return (
    <MenuItemWrapper
      className="profile-menu-item-wrapper"
      data-testid={testId || "profile-menu-item-wrapper"}
      {...rest}
    >
      <MenuItemClickTargetWrapper>{clickTarget}</MenuItemClickTargetWrapper>
    </MenuItemWrapper>
  );
};

export default ProfileMenuItem;

// Styled components
const MenuItemWrapper = styled.li`
  display: block;
  position: relative;
  padding: 0;
  width: 100%;
`;

const clickTargetBaseProps = css`
  display: block;
  position: relative;
  width: 100%;
  padding: 10px 20px;

  transition: 0.2s;

  &.setting-item {
    display: grid;
    grid-template-columns: 1fr 24px;
    grid-template-rows: auto auto;
    grid-template-areas:
      "label caret"
      "status caret";

    margin: -16px 0 16px;
    padding: 16px 20px;

    border-bottom: 1px solid ${ConcreteColors.gray300};

    & > .profile-menu-item-label {
      font-size: 1.2rem;
      font-weight: 600;
      letter-spacing: 0.02rem;
      text-transform: uppercase;
    }
  }

  &,
  &:active,
  &:focus {
    outline: none;
  }

  &,
  &:hover,
  &:active,
  &:focus,
  &:active:focus {
    text-decoration: none;
  }

  &:hover,
  &:active,
  &:focus,
  &:active:focus {
    background: ${ConcreteColors.blue150};
    cursor: pointer;

    &,
    & * {
      color: ${ConcreteColors.blue200};
    }
  }
`;

const cutoffText = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

// Simple fix in order to prevent an issue with styled-components
// when trying to created styled extension on top of the react-router-dom
// Link component
const MenuItemClickTargetWrapper = styled.div`
  & > .static-item,
  & > .link-item {
    ${clickTargetBaseProps}
    ${cutoffText}
  }

  & > .button-item {
    ${clickTargetBaseProps}
    background: none;
    border: 0;
    box-shadow: none;
    ${cutoffText}
    text-align: left;
  }
`;

// Content inside the menu item -- label, status, and click affordance
const MenuItemLabel = styled.div`
  grid-area: label;
  align-self: self-end;
  font-size: 1.5rem;

  color: ${ConcreteColors.gray600};
  ${cutoffText}
`;

const MenuItemStatus = styled.div`
  grid-area: status;
  align-self: self-start;
  margin-top: 2px;

  color: ${ConcreteColors.gray700};
  font-size: 1.5rem;
  ${cutoffText}
`;

const MenuItemClickAffordance = styled.div`
  grid-area: caret;
  align-self: center;
  justify-self: self-end;

  color: ${ConcreteColors.gray400};
  font-size: 2.2rem;

  & > i {
    transition: 0.2s;
  }
`;
