import { TableGroupRow } from "@devexpress/dx-react-grid-bootstrap3";
import * as React from "react";
import styled from "styled-components";

export const GroupCell = (props: TableGroupRow.CellProps) => {
  return (
    <StyledGroupCell {...props}>
      <Bold>{props.row.key}</Bold>
    </StyledGroupCell>
  );
};

const StyledGroupCell = styled(TableGroupRow.Cell)`
  position: sticky;
  left: 0px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 25%,
    rgba(255, 255, 255, 0) 25%
  );

  & span strong {
    font-weight: 400;
  }
`;

const Bold = styled.span`
  font-weight: 700;
  padding-right: 20px;
  background-color: #fff;
`;
