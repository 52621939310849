import * as React from "react";
import Link from "../Link";
import { LinkProps } from "react-router-dom";
import { LocationDescriptor } from "history";
import classNames from "classnames";

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
type ReducedLinkProps = Omit<LinkProps, "to">;

export interface BreadcrumbItemProps extends ReducedLinkProps {
  to?: LocationDescriptor;
}

export const BreadcrumbItem = (props: BreadcrumbItemProps) => {
  const { className, ...other } = props;
  if (other.to) {
    return (
      <li className="breadcrumb-item">
        <Link
          className={classNames("breadcrumb-link", props.className)}
          {...other}
        >
          {props.children}
        </Link>
      </li>
    );
  }

  return <li className="breadcrumb-item">{props.children}</li>;
};
