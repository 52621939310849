import { Theme } from "styled-system";

export const BaseTheme: Theme = {
  space: [
    0,
    4,
    8,
    12,
    16,
    24,
    32,
    48,
    64,
    96,
    128,
    192,
    256,
    384,
    512,
    640,
    768
  ],
  fontSizes: [12, 14, 16, 18, 20, 24, 32, 48],
  shadows: [
    "0 1px 3px hsla(0,0%,0%,.12), 0 1px 2px hsla(0,0%,0%,.24)",
    "0 3px 2px hsla(0,0%,0%,.15), 0 2px 4px hsla(0,0%,0%,.12)",
    "0 10px 20px hsla(0,0%,0%,.15), 0 3px 6px hsla(0,0%,0%,.10)",
    "0 15px 25px hsla(0,0%,0%,.15), 0 5px 10px hsla(0,0%,0%,.05)",
    "0 20px 40px hsla(0,0%,0%,.2)"
  ],
  colors: {
    neutral: [
      "#1E1E1E",
      "#404040",
      "#707070",
      "#CCCCCC",
      "#ECECEC",
      "#F0F0F0",
      "#F6F6F6",
      "#FFFFFF"
    ],
    red: ["#6E0303", "#9E0A0A", "#DB1010", "#FFEEEE"],
    blue: ["#003E8A", "#005BCA", "#0370F5", "#DFEFFB", "#ECF5FE"],
    green: ["#072B21", "#0C4B39", "#11654D", "#14AF80", "#EEFBF5"],
    yellow: ["#FFF2BC"],
    primary: "#0370F5"
  }
};
