import * as React from "react";
import styled, { withTheme } from "styled-components";
import { Button as BootstrapButton } from "react-bootstrap";
import { Theme } from "../themes";
import * as AceColors from "../AceColors";
import * as ConcreteColors from "../ConcreteColors";

// Concrete buttons have additional styling applied in ConcreteButton
const ButtonStyle = styled(
  ({ buttontextcolor, buttonbackgroundcolor, buttonbordercolor, ...rest }) => (
    <BootstrapButton {...rest} />
  )
)<{
  buttontextcolor: string;
  buttonbackgroundcolor: string;
  buttonbordercolor: string;
}>`
  border-radius: 0;
  border-width: 1px;
  border-color: ${props => props.buttonbordercolor};
  background-color: ${props => props.buttonbackgroundcolor};
  color: ${props => props.buttontextcolor};
  :hover:not(.btn-link) {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    background-color: ${props => props.buttonbackgroundcolor};
    border-color: ${props => props.buttonbordercolor};
    color: ${props => props.buttontextcolor};
  }
  :active {
    background-color: ${props => props.buttonbackgroundcolor};
    border-color: ${props => props.buttonbordercolor};
    box-shadow: none;
    color: ${props => props.buttontextcolor};
  }
  :focus {
    background-color: ${props => props.buttonbackgroundcolor};
    border-color: ${props => props.buttonbordercolor};
    color: ${props => props.buttontextcolor};
  }
  :active:focus {
    background-color: ${props => props.buttonbackgroundcolor};
    border-color: ${props => props.buttonbordercolor};
    color: ${props => props.buttontextcolor};
  }
  :disabled,
  :disabled:hover {
    background-color: #f9f9f9;
    color: #696969;
    border-color: #ccc;
    opacity: 1;
    box-shadow: none;
  }
`;

export interface ButtonProps extends BootstrapButton.ButtonProps {
  /** The style of the button you want to use */
  hcssStyle?:
    | "Theme"
    | "ThemeInverted"
    | "Delete"
    | "DeleteInverted"
    | "Success"
    | "SuccessInverted";
  theme?: Theme;
  testId?: string;
  backgroundColor?: string;
  color?: string;
}

export function Button({
  children,
  theme,
  testId,
  hcssStyle,
  backgroundColor,
  color,
  ...props
}: ButtonProps) {
  const buttonColor =
    theme && theme.buttonColor ? theme.buttonColor : AceColors.gray800;

  let buttontextcolor = "white";
  let buttonbackgroundcolor = buttonColor;
  let buttonbordercolor = buttonColor;
  let defaultTestId = "Button";

  if (theme && theme.baseTheme === "Ace") {
    switch (hcssStyle) {
      default:
      case "Theme":
        buttontextcolor = "white";
        buttonbackgroundcolor = buttonColor;
        buttonbordercolor = buttonColor;
        break;
      case "ThemeInverted":
        buttontextcolor = buttonColor;
        buttonbackgroundcolor = "white";
        buttonbordercolor = buttonColor;
        break;
      case "Delete":
        buttontextcolor = "white";
        buttonbackgroundcolor = AceColors.errorRed;
        buttonbordercolor = AceColors.errorRed;
        break;
      case "DeleteInverted":
        buttontextcolor = AceColors.errorRed;
        buttonbackgroundcolor = "white";
        buttonbordercolor = AceColors.errorRed;
        break;
      case "Success":
        buttontextcolor = "white";
        buttonbackgroundcolor = AceColors.successGreen;
        buttonbordercolor = AceColors.successGreen;
        break;
      case "SuccessInverted":
        buttontextcolor = AceColors.successGreen;
        buttonbackgroundcolor = "white";
        buttonbordercolor = AceColors.successGreen;
        break;
    }

    // override theme color if custom colors are sent
    if (backgroundColor) {
      buttonbackgroundcolor = backgroundColor;
    }
    if (color) {
      buttontextcolor = color;
    }

    if (testId) {
      defaultTestId = testId;
    }
  }

  return (
    <>
      {props.bsStyle ? (
        <ButtonStyle data-testid={defaultTestId} {...(props as any)}>
          {children}
        </ButtonStyle>
      ) : theme && theme.baseTheme === "Concrete" ? (
        <ConcreteButton
          hcssStyle={hcssStyle}
          backgroundColor={backgroundColor}
          textColor={color}
          data-testid={defaultTestId}
          {...(props as any)}
        >
          {children}
        </ConcreteButton>
      ) : (
        <ButtonStyle
          buttontextcolor={buttontextcolor}
          buttonbackgroundcolor={buttonbackgroundcolor}
          buttonbordercolor={buttonbordercolor}
          data-testid={defaultTestId}
          {...(props as any)}
        >
          {children}
        </ButtonStyle>
      )}
    </>
  );
}

export type ConcreteButtonProps = {
  backgroundColor?: string;
  textColor?: string;
  hcssStyle?:
    | "Theme" // considered as default
    | "ThemeInverted" // considered as default inverted
    | "Success"
    | "SuccessInverted"
    | "Delete"
    | "DeleteInverted";
} & BootstrapButton.ButtonProps;

const StyledConcreteButton = styled(BootstrapButton)`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  border-radius: 100px;
  font-size: 1.25rem;
  letter-spacing: 0.06rem;
  line-height: normal;
  text-transform: uppercase;
  padding: 5px 20px;

  /*
    Standard (pre-interaction) colors
    - If hcssStyle prop is supplied a value, it takes precedence
    - Otherwise, use the custom color values, if supplied, or use the default accent blue and default white text color
  */
  &:not(:disabled) {
    font-weight: 800;
    background-color: ${(props: {
      backgroundcolor: string;
      textcolor: string;
      hcssstyle: string;
    }) => {
      if (props.hcssstyle) {
        if (props.hcssstyle.endsWith("Inverted")) return "white";
        else if (props.hcssstyle === "Theme") return ConcreteColors.blue200;
        else if (props.hcssstyle === "Success") return ConcreteColors.green300;
        else if (props.hcssstyle === "Delete") return ConcreteColors.red200;
      }
      return props.backgroundcolor || ConcreteColors.blue200;
    }};
    border-color: ${(props: {
      backgroundcolor: string;
      textcolor: string;
      hcssstyle: string;
    }) => {
      if (props.hcssstyle) {
        if (props.hcssstyle.startsWith("Theme")) return ConcreteColors.blue200;
        else if (props.hcssstyle.startsWith("Success"))
          return ConcreteColors.green300;
        else if (props.hcssstyle.startsWith("Delete"))
          return ConcreteColors.red200;
      }
      return props.backgroundcolor || ConcreteColors.blue200;
    }};
    color: ${(props: {
      backgroundcolor: string;
      textcolor: string;
      hcssstyle: string;
    }) => {
      if (props.hcssstyle) {
        if (!props.hcssstyle.endsWith("Inverted")) return "white";
        else if (props.hcssstyle === "ThemeInverted")
          return ConcreteColors.blue200;
        else if (props.hcssstyle === "SuccessInverted")
          return ConcreteColors.green300;
        else if (props.hcssstyle === "DeleteInverted")
          return ConcreteColors.red200;
      }
      return props.textcolor || "white";
    }};
  }

  /* Hover-state colors */
  &:not(:disabled):hover {
    background-color: ${(props: {
      backgroundcolor: string;
      textcolor: string;
      hcssstyle: string;
    }) => {
      if (props.hcssstyle) {
        if (props.hcssstyle === "Theme") return ConcreteColors.blue300;
        else if (props.hcssstyle === "ThemeInverted")
          return ConcreteColors.blue100;
        else if (props.hcssstyle === "Success") return ConcreteColors.green400;
        else if (props.hcssstyle === "SuccessInverted")
          return ConcreteColors.green100;
        else if (props.hcssstyle === "Delete") return ConcreteColors.red300;
        else if (props.hcssstyle === "DeleteInverted")
          return ConcreteColors.red100;
      }
      return props.backgroundcolor || ConcreteColors.blue300;
    }};
    border-color: ${(props: {
      backgroundcolor: string;
      textcolor: string;
      hcssstyle: string;
    }) => {
      if (props.hcssstyle) {
        if (props.hcssstyle.startsWith("Theme")) return ConcreteColors.blue300;
        else if (props.hcssstyle.startsWith("Success"))
          return ConcreteColors.green400;
        else if (props.hcssstyle.startsWith("Delete"))
          return ConcreteColors.red300;
      }
      return props.backgroundcolor || ConcreteColors.blue300;
    }};
    color: ${(props: {
      backgroundcolor: string;
      textcolor: string;
      hcssstyle: string;
    }) => {
      if (props.hcssstyle) {
        if (!props.hcssstyle.endsWith("Inverted")) return "white";
        else if (props.hcssstyle === "ThemeInverted")
          return ConcreteColors.blue300;
        else if (props.hcssstyle === "SuccessInverted")
          return ConcreteColors.green400;
        else if (props.hcssstyle === "DeleteInverted")
          return ConcreteColors.red300;
      }
      return props.textcolor || "white";
    }};
  }

  /* Active-state and focus-state colors */
  &:not(:disabled):active,
  &:not(:disabled):focus {
    outline: none;
    background-color: ${(props: {
      backgroundcolor: string;
      textcolor: string;
      hcssstyle: string;
    }) => {
      if (props.hcssstyle) {
        if (props.hcssstyle === "Theme") return ConcreteColors.blue400;
        else if (props.hcssstyle === "ThemeInverted")
          return ConcreteColors.blue100;
        else if (props.hcssstyle === "Success") return ConcreteColors.green500;
        else if (props.hcssstyle === "SuccessInverted")
          return ConcreteColors.green100;
        else if (props.hcssstyle === "Delete") return ConcreteColors.red400;
        else if (props.hcssstyle === "DeleteInverted")
          return ConcreteColors.red100;
      }
      return props.backgroundcolor || ConcreteColors.blue400;
    }};
    border-color: ${(props: {
      backgroundcolor: string;
      textcolor: string;
      hcssstyle: string;
    }) => {
      if (props.hcssstyle) {
        if (props.hcssstyle.startsWith("Theme")) return ConcreteColors.blue400;
        else if (props.hcssstyle.startsWith("Success"))
          return ConcreteColors.green500;
        else if (props.hcssstyle.startsWith("Delete"))
          return ConcreteColors.red400;
      }
      return props.backgroundcolor || ConcreteColors.blue400;
    }};
    color: ${(props: {
      backgroundcolor: string;
      textcolor: string;
      hcssstyle: string;
    }) => {
      if (props.hcssstyle) {
        if (!props.hcssstyle.endsWith("Inverted")) return "white";
        else if (props.hcssstyle === "ThemeInverted")
          return ConcreteColors.blue400;
        else if (props.hcssstyle === "SuccessInverted")
          return ConcreteColors.green500;
        else if (props.hcssstyle === "DeleteInverted")
          return ConcreteColors.red400;
      }
      return props.textcolor || "white";
    }};
  }

  &,
  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
    cursor: pointer;
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:active &:disabled:focus {
    background: ${ConcreteColors.gray100};
    border-color: ${ConcreteColors.gray400};
    color: ${ConcreteColors.gray500};
    font-weight: 600;
    opacity: 1;
    cursor: not-allowed;
  }

  &.btn-sm {
    font-size: 1.1rem;
    padding: 4px 16px;
  }

  &.btn-lg {
    font-size: 1.6rem;
    padding: 8px 50px;
  }

  & > i {
    height: 15px;
    font-size: 1.22rem;
  }
`;

class ConcreteButton extends React.Component<ConcreteButtonProps> {
  render() {
    const { hcssStyle, textColor, backgroundColor, ...rest } = this.props;
    return (
      <StyledConcreteButton
        hcssstyle={hcssStyle}
        textcolor={textColor}
        backgroundcolor={backgroundColor}
        {...(rest as any)}
      >
        {this.props.children}
      </StyledConcreteButton>
    );
  }
}

var stupidVariable = withTheme(Button);
export default stupidVariable;
