import { createContext } from "react";

export interface WidgetContextProps {
  open?: boolean;
  handleToggle?: () => void;
}

export const WidgetContext = createContext<WidgetContextProps>({
  open: undefined,
  handleToggle: undefined
});
