import React from "react";
import { ProgressStepProps as ComponentProps } from "./Models";
import StepCompleteIndicator from "./StepCompleteIndicator";
import * as ConcreteColors from "../../../ConcreteColors";
import styled from "styled-components";

const ProgressStep: React.FC<ComponentProps> = (props: ComponentProps) => {
  // Destructure props
  const {
    index,
    name,
    onClick,
    isClickable,
    isComplete,
    isCurrentStep,
    stepCompleteIndicatorComponent,
    containerBackground
  } = props;

  const handleStepRequested = () => {
    if (isClickable) onClick(index);
  };

  const useCustomBackgroundClear = () => {
    if (containerBackground)
      return {
        background: containerBackground,
        boxShadow: `0 0 0 8px ${containerBackground}`
      };
    return undefined;
  };

  const getButtonClassName = () => {
    let value = "wizard-progress-step-button";
    if (isClickable) value += " is-clickable";
    if (isCurrentStep) value += " is-current";
    return value;
  };

  //
  //
  //
  const renderStepCompleteIndicator = () => {
    const Indicator = stepCompleteIndicatorComponent || StepCompleteIndicator;
    if (isComplete)
      return (
        <Indicator index={index} containerBackground={containerBackground} />
      );
    return null;
  };

  return (
    <ProgressStepWrapper
      key={`step_${index + 1}`}
      className="wizard-progress-step"
      data-testid={`progress-step-${index + 1}`}
    >
      <ProgressStepButton
        className={getButtonClassName()}
        onClick={handleStepRequested}
        title={`Step ${index + 1} — ${name}`}
        data-testid={`progress-step-${index + 1}-button`}
        disabled={!isClickable}
      >
        <div
          className="step-number"
          data-testid={`progress-step-${index + 1}-number`}
          style={useCustomBackgroundClear()}
        >
          <span>{index + 1}</span>
        </div>
        <div
          className="step-name"
          data-testid={`progress-step-${index + 1}-name`}
        >
          {name}
        </div>
        {renderStepCompleteIndicator()}
      </ProgressStepButton>
    </ProgressStepWrapper>
  );
};
export default ProgressStep;

// Styled components
const ProgressStepWrapper = styled.li`
  display: inline-block;
  position: relative;
  padding: 16px 20px 6px;
`;

const ProgressStepButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  background: none;
  border: none;
  padding: 0;

  transition: 0.3s;

  & > .step-number,
  & > .step-name {
    text-align: center;
    width: 64px;
    cursor: default;
  }

  & > .step-number {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    margin-bottom: 8px;
    transition: 0.3s;

    & > span {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;

      background: #ffffff;
      box-shadow: 0 0 0 8px #ffffff;
      border: 2px solid ${ConcreteColors.gray100};
      border-radius: 96px;

      color: ${ConcreteColors.gray400};
      font-size: 1.8rem;
      font-weight: 600;
      transition: 0.3s;
    }
  }

  & > .step-name {
    width: 80px;
    height: 60px;

    color: ${ConcreteColors.gray500};
    font-size: 1.3rem;
    letter-spacing: 0.02em;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;

    transition: color 0.3s;
  }

  &.is-current {
    & > .step-number {
      & > span {
        border: 2px solid ${ConcreteColors.blue200};
        font-weight: 800;
        color: ${ConcreteColors.gray700};
      }
    }

    & > .step-name {
      color: ${ConcreteColors.gray700};
      font-weight: 600;
    }
  }

  &.is-clickable:not(.is-current) {
    cursor: pointer;

    & > .step-number,
    & > .step-name {
      cursor: pointer;
    }

    & > .step-number {
      & > span {
        border: 2px solid ${ConcreteColors.gray400};
        color: ${ConcreteColors.gray500};
        font-weight: 600;
      }
    }

    & > .step-name {
      color: ${ConcreteColors.gray700};
      font-weight: 400;
    }

    &:hover {
      & > .step-name,
      & > .step-number > span {
        color: ${ConcreteColors.blue200};
      }
    }
  }

  :focus,
  :active:focus {
    outline: none;
  }
`;
