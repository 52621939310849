import moment from "moment";
import numeral from "numeral";
import { DataColumnType, ColumnConfig } from "../models";

export const nullUndefinedCompare = (a: any, b: any) => {
  const aIsEmpty = a === null || a === undefined;
  const bIsEmpty = b === null || b === undefined;

  if (aIsEmpty && bIsEmpty) {
    return { isNullOrUndefined: true, sortIndex: 0 };
  }

  if (aIsEmpty) {
    return { isNullOrUndefined: true, sortIndex: -1 };
  }

  if (bIsEmpty) {
    return { isNullOrUndefined: true, sortIndex: 1 };
  }

  return { isNullOrUndefined: false, sortIndex: 0 };
};

export const dateValidityCompare = (a: moment.Moment, b: moment.Moment) => {
  if (a.isValid() && !b.isValid()) {
    return 1;
  } else if (!a.isValid() && b.isValid()) {
    return -1;
  }
  return 0;
};

export const dateCompare = (
  a: string | undefined | null = null,
  b: string | undefined | null = null
) => {
  const nullUndefinedCheck = nullUndefinedCompare(a, b);

  if (nullUndefinedCheck.isNullOrUndefined) {
    return nullUndefinedCheck.sortIndex;
  }

  const dateA = moment(a!);
  const dateB = moment(b!);

  const validityCompare = dateValidityCompare(dateA, dateB);
  if (validityCompare !== 0) {
    return validityCompare;
  }

  dateA.startOf("day");
  dateB.startOf("day");

  if (dateA.isBefore(dateB)) {
    return -1;
  } else if (dateB.isBefore(dateA)) {
    return 1;
  }
  return 0;
};

export const dateTimeCompare = (
  a: string | undefined | null = null,
  b: string | undefined | null = null
) => {
  const nullUndefinedCheck = nullUndefinedCompare(a, b);

  if (nullUndefinedCheck.isNullOrUndefined) {
    return nullUndefinedCheck.sortIndex;
  }

  const dateA = moment(a!);
  const dateB = moment(b!);

  const validityCompare = dateValidityCompare(dateA, dateB);
  if (validityCompare !== 0) {
    return validityCompare;
  }

  dateA.startOf("minute");
  dateB.startOf("minute");

  if (dateA.isBefore(dateB)) {
    return -1;
  } else if (dateB.isBefore(dateA)) {
    return 1;
  }
  return 0;
};

export const stringCompare = (
  a: string | undefined | null = null,
  b: string | undefined | null = null
) => {
  const valueA = a === "" ? undefined : a;
  const valueB = b === "" ? undefined : b;

  const nullUndefinedCheck = nullUndefinedCompare(valueA, valueB);
  if (nullUndefinedCheck.isNullOrUndefined) {
    return nullUndefinedCheck.sortIndex;
  }

  return a!.localeCompare(b!, undefined, { sensitivity: "base" });
};

export const linkToString = (value: any) => {
  let retString = "";
  if (value) {
    value.forEach((link: any, index: number) => {
      retString += index > 0 ? ", " : "";
      retString += link.description || link.url;
    });
  }
  return retString === "" ? undefined : retString;
};

export const checklistToNumber = (value: any) => {
  if (!value) {
    return undefined;
  }

  let allCheckedCount = 0;
  value.forEach((list: any) => {
    allCheckedCount += list.checked ? 1 : 0;
  });

  return Math.abs(allCheckedCount / value.length) * 100;
};

export const linkCompare = (
  a: string | undefined | null = null,
  b: string | undefined | null = null
) => {
  const linkAString = linkToString(a);
  const linkBString = linkToString(b);

  const nullUndefinedCheck = nullUndefinedCompare(linkAString, linkBString);
  if (nullUndefinedCheck.isNullOrUndefined) {
    return nullUndefinedCheck.sortIndex;
  }
  return linkAString!.localeCompare(linkBString!, undefined, {
    sensitivity: "base"
  });
};

export const listCompare = (
  config: ColumnConfig<DataColumnType.List> = { listValues: [] },
  a: any,
  b: any
) => {
  if (!config.customSort) {
    if (config.showLabelValueInTable) {
      const labelA = config.listValues.find(opt => opt.value === a)?.display;
      const labelB = config.listValues.find(opt => opt.value === b)?.display;

      if (config.fallbackSortFunction && (!labelA || !labelB)) {
        return config.fallbackSortFunction(labelA, labelB);
      }

      return stringCompare(
        config.listValues.find(opt => opt.value === a)?.display ?? a,
        config.listValues.find(opt => opt.value === b)?.display ?? b
      );
    }
    return config.fallbackSortFunction?.(a, b) ?? stringCompare(a, b);
  }

  if (a === "") a = undefined;
  if (b === "") b = undefined;

  const nullUndefinedCheck = nullUndefinedCompare(a!, b!);
  if (nullUndefinedCheck.isNullOrUndefined) {
    return nullUndefinedCheck.sortIndex;
  }

  let indexA = config.listValues.findIndex(opt => opt.value === a);
  let indexB = config.listValues.findIndex(opt => opt.value === b);

  if (indexA === -1 && indexB === -1) {
    return config.fallbackSortFunction?.(a, b) ?? stringCompare(a, b);
  }

  if (indexA === indexB) {
    return 0;
  }

  if (indexA === -1) {
    indexA = config.listValues.length;
  }

  if (indexB === -1) {
    indexB = config.listValues.length;
  }

  return indexA < indexB ? -1 : 1;
};

export const checkListCompare = (
  a: string | undefined | null = null,
  b: string | undefined | null = null
) => {
  const checkListANumber = checklistToNumber(a);
  const checkListBNumber = checklistToNumber(b);

  return numberCompare(checkListANumber, checkListBNumber);
};

export const booleanCompare = (
  a: string | boolean | undefined | null = null,
  b: string | boolean | undefined | null = null
) => {
  const booleanA = typeof a === "boolean" && a;
  const booleanB = typeof b === "boolean" && b;
  if (!booleanA && booleanB) {
    return -1;
  }
  if (booleanA && !booleanB) {
    return 1;
  }
  return 0;
};

export const numberCompare = (
  a: string | number | null | undefined = null,
  b: string | number | null | undefined = null
) => {
  const nullUndefinedCheck = nullUndefinedCompare(a, b);
  if (nullUndefinedCheck.isNullOrUndefined) {
    return nullUndefinedCheck.sortIndex;
  }

  const numberA = numeral(a).value();
  const numberB = numeral(b).value();
  if (numberA === numberB) {
    return 0;
  }
  if (isNaN(numberA) || isNaN(numberB)) {
    return !isNaN(numberA) ? -1 : 1;
  }
  return numberA < numberB ? -1 : 1;
};

export const defaultCustomCompare = (a: any, b: any) => {
  return 0;
};
