import { LocalizationMessages } from "./SelectModal";

export const isFunction = (functionToCheck: any) => {
  const getType = {};
  return (
    functionToCheck &&
    getType.toString.call(functionToCheck) === "[object Function]"
  );
};

export const getDefaultSelectModalMessages = (): LocalizationMessages => {
  return {
    ok: "Ok",
    cancel: "Cancel"
  };
};
