import {
  TableHeaderRow as DTableHeaderRow,
  TableHeaderRowProps as DTableHeaderRowProps
} from "@devexpress/dx-react-grid-bootstrap3";
import * as React from "react";
import {
  HeaderContextMenu,
  HeaderContextMenuProps
} from "../context-menus/header-context-menu";
import { TableHeaderCell } from "./table-header-cell";

export type TableHeaderRowProps = DTableHeaderRowProps & HeaderContextMenuProps;

export const TableHeaderRow: React.FunctionComponent<TableHeaderRowProps> = ({
  headerMenuMessages,
  tableMenuMessages,
  tableMenuItems,
  headerMenuItems,
  ...other
}) => {
  return (
    <>
      <HeaderContextMenu
        headerMenuMessages={headerMenuMessages}
        tableMenuMessages={tableMenuMessages}
        tableMenuItems={tableMenuItems}
        headerMenuItems={headerMenuItems}
      />
      <DTableHeaderRow {...other} cellComponent={TableHeaderCell} />
    </>
  );
};
