// tslint:disable: max-classes-per-file

import React from "react";
import styled from "styled-components";

export interface LoadingOverlayProps {
  message?: string;
}

export class ContentLoadingOverlay extends React.PureComponent<LoadingOverlayProps, any> {
  render() {
    return (
      <ContentLoadingOverlayContainer className="content-loading-overlay">
        <div className="loading-message">
          <LoadingSpinner size="medium" />
          <div className="loading-message-text">{this.props.message || "Loading..."}</div>
        </div>
      </ContentLoadingOverlayContainer>
    );
  }
}

interface LoadingSpinnerProps {
  size: "large" | "medium" | "small";
}
class LoadingSpinner extends React.PureComponent<LoadingSpinnerProps, any> {
  renderSpinnerClass = (): string => {
    switch (this.props.size) {
      case "large":
        return "spinner-large-above";
      case "medium":
        return "spinner-medium-inline";
      case "small":
        return "spinner-small-inline";
      default:
        return "spinner-medium-inline";
    }
  };

  render() {
    return (
      <StyledLoadingSpinner className={`loading-message-spinner ${this.renderSpinnerClass()}`} />
    );
  }
}

const BaseLoadingOverlayContainer = styled.div`
  display: flex;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #ffffff;
  text-align: center;

  & > .loading-message {
    margin: auto;
    color: #404040;
  }
`;

const ContentLoadingOverlayContainer = styled(BaseLoadingOverlayContainer)`
  position: absolute;
  opacity: 0.98;
  z-index: 10;

  & > .loading-message > .loading-message-text {
    display: inline-block;
    margin: 0 0 0 16px;
    font-size: 16px;
    vertical-align: middle;
  }
`;
const StyledLoadingSpinner = styled.div`
  &.spinner-large-above {
    position: relative;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    margin: 60px auto;
    margin-top: -40px;
    font-size: 10px;
    text-indent: -9999em;
    border-top: 3px solid #cccccc;
    border-right: 3px solid #cccccc;
    border-bottom: 3px solid #cccccc;
    border-left: 3px solid #1e1e1e;
    transform: translateZ(0);
    animation: rollSpinner 1.5s infinite linear;

    &:after {
      border-radius: 50%;
    }
  }

  &.spinner-medium-inline {
    display: inline-block;
    position: relative;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    font-size: 10px;
    vertical-align: middle;
    text-indent: -9999em;
    border-top: 3px solid #cccccc;
    border-right: 3px solid #cccccc;
    border-bottom: 3px solid #cccccc;
    border-left: 3px solid #1e1e1e;
    transform: translateZ(0);
    animation: rollSpinner 1.5s infinite linear;

    &:after {
      border-radius: 50%;
    }
  }

  &.spinner-small-inline {
    display: inline-block;
    position: relative;
    width: 3em;
    height: 3em;
    color: #1e1e1e;
    font-size: 3px;
    text-indent: -9999em;
    animation: threeDotSpinner 1.8s infinite ease-in-out;
    animation-delay: -0.16s;
    animation-fill-mode: both;
    vertical-align: top;
    transform: translateZ(0);

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      width: 3em;
      height: 3em;
      animation: threeDotSpinner 1.8s infinite ease-in-out;
      animation-fill-mode: both;
    }
    &:before {
      left: -12px;
      animation-delay: -0.32s;
    }
    &:after {
      left: 12px;
    }
  }

  @keyframes rollSpinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes threeDotSpinner {
    0%,
    20%,
    80%,
    100% {
      box-shadow: 0 4em 0 -0.95em #ececec;
    }
    50% {
      box-shadow: 0 4em 0 -0.2em #aaaaaa;
    }
  }
`;
