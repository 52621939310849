import * as React from "react";
import styled from "styled-components";

export interface IconProps extends React.HTMLProps<HTMLElement> {
  /** The name of the Font Awesome or HCSS icon */
  name: string;
  /** Will place a 5px margin to the chosen side(s) of the icon*/
  margin?: "left" | "right" | "both";
}

/**
 * Use the standard FontAwesome v4.7 icon names [found here](https://fontawesome.com/v4.7.0/icons/) plus our [custom HCSS font names](https://hcss-pattern-library-test.azurewebsites.net/#!/elements/typography) (without the fa- prefix)
 * So, "name: info" not "name: fa-info"
 */

const StyledIcon = styled.i<IconProps>`
  padding-left: ${props =>
    props.margin === "left" || props.margin === "both" ? "5px" : 0};
  padding-right: ${props =>
    props.margin === "right" || props.margin === "both" ? "5px" : 0};
`;

export class Icon extends React.Component<IconProps> {
  render() {
    var { className, name, margin, ...other } = this.props;

    if (className === undefined || className === null) className = "";
    var iconClass = className + " fa fa-" + name;

    return (
      <StyledIcon
        className={iconClass}
        name={name}
        margin={margin}
        {...(other as any)}
      />
    );
  }
}

export default Icon;
