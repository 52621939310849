import {
  SearchState as DSearchState,
  SearchStateProps
} from "@devexpress/dx-react-grid";
import * as React from "react";
import { useTableContext } from "../table-context";

export const SearchState = (props: Partial<SearchStateProps>) => {
  const {
    search: { searchValue, setSearchValue }
  } = useTableContext();

  return React.useMemo(() => {
    // console.log("search");
    return (
      <DSearchState
        value={searchValue}
        onValueChange={setSearchValue}
        {...props}
      />
    );
  }, [searchValue, setSearchValue, props]);
};
