import {
  SortingState as DSortingState,
  SortingStateProps
} from "@devexpress/dx-react-grid";
import * as React from "react";
import { createGroupedSorting } from "../hooks/useSorting";
import { useTableContext } from "../table-context";

export const SortingState = ({
  sorting: passedSorting,
  ...other
}: Partial<SortingStateProps>) => {
  const {
    sorting: { sorting, setSorting },
    extendedGroups
  } = useTableContext();

  return React.useMemo(() => {
    // console.log("sorting");
    const groupedSorting = createGroupedSorting(
      extendedGroups,
      passedSorting ?? sorting
    );
    return (
      <DSortingState
        sorting={groupedSorting}
        onSortingChange={setSorting}
        {...other}
      />
    );
  }, [extendedGroups, passedSorting, sorting, setSorting, other]);
};
