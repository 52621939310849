import * as React from "react";
import ReactSelect from "react-select";
import styled from "styled-components";
import { Props } from "react-select/lib/Select";
import classNames from "classnames";
import { blue200, blue100, gray300, red100, red200 } from "../ConcreteColors";

const StyledSelect = styled(ReactSelect)`
  .react-select__control {
    &.react-select__control--is-focused {
      border-color: ${blue200};
    }
  }

  .react-select__option {
    &.react-select__option--is-focused:not(.react-select__option--is-selected) {
      background-color: ${blue100};
    }
    &.react-select__option--is-selected {
      background-color: ${blue200};
    }
  }

  .react-select__multi-value {
    background-color: ${gray300};
  }
  .react-select__multi-value__remove {
    :hover {
      color: ${red200};
      background-color: ${red100};
    }
  }
`;

export default class Select extends React.Component<Props> {
  render() {
    const { children, className, classNamePrefix, ...props } = this.props;
    return (
      <StyledSelect
        className={classNames("react-select", className)}
        classNamePrefix="react-select"
        {...props}
      >
        {children}
      </StyledSelect>
    );
  }
}
