import classNames from "classnames";
import * as React from "react";
import styled from "styled-components";

export type SidebarToggleProps = {
  /** @ignore */
  className?: string;
  sidebarExpanded: boolean;
  onToggle?: () => void;
};

const SidebarToggleContainer = styled.div`
  border-style: solid;
  border-width: 0 0 1px;
  text-align: center;
  padding: 3px 0;
  position: relative;
  border-color: #e0e0e0;
  &::before {
    content: "";
    display: block;
    height: 0;

    border-top: 1px solid;
    border-color: inherit;
    position: absolute;
    left: 15px;
    right: 15px;
    top: 13px;
  }
`;

const SidebarToggleIcon = styled.i`
  padding: 0 5px;
  line-height: 18px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 100%;
  position: relative;
  background-color: white;
  color: rgb(170, 170, 170);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(224, 224, 224);
  border-image: initial;
`;

export default class SidebarToggle extends React.Component<SidebarToggleProps> {
  toggleExistingLayoutContainer = (expand: any) => {
    const sidebarContainer = document.getElementById("sidebar-container");
    if (sidebarContainer === null) return;

    if (expand) sidebarContainer.classList.remove("menu-min");
    else sidebarContainer.classList.add("menu-min");
  };
  render() {
    return (
      <SidebarToggleContainer>
        <SidebarToggleIcon
          data-testid="sidebar-toggle-icon"
          onClick={this.props.onToggle}
          className={classNames({
            "fa fa-angle-double-left": this.props.sidebarExpanded,
            "fa fa-angle-double-right": !this.props.sidebarExpanded
          })}
        />
      </SidebarToggleContainer>
    );
  }
}
