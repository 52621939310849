import * as React from "react";
import Navbar from "./Navbar";
import HamburgerMenu from "./HamburgerMenu";
import HamburgerMenuItem from "./HamburgerMenuItem";
import HelpMenu from "./HelpMenu";
import ProfileMenu from "./ProfileMenu";
import Sidebar from "./Sidebar";
import styled from "styled-components";
import { Divider } from "./DropdownMenu";
import DropdownMenuItem from "./DropdownMenuItem";
import SidebarLinkMenuItem from "./SidebarLinkMenuItem";
import SidebarContainerMenuItem from "./SidebarContainerMenuItem";

export type LayoutProps = {
  navbar: any;
  sidebar?: any;
  mainContent: any;
  footer?: any;
};

const SidebarAndMainContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledMain = styled.main`
  width: 100%;
`;

const StyledAppContainer = styled.div`
  background-color: ${(props: any) => props.theme.pageColor};
`;

export default class Layout extends React.Component<LayoutProps> {
  render() {
    return (
      <StyledAppContainer>
        <div id="navbar-container">{this.props.navbar}</div>

        <SidebarAndMainContainer id="sidebar-and-main-container">
          <div id="sidebar-container">{this.props.sidebar}</div>
          <StyledMain>
            {this.props.mainContent}
            {this.props.footer}
          </StyledMain>
        </SidebarAndMainContainer>
      </StyledAppContainer>
    );
  }

  static Navbar = Navbar;
  static DropdownMenuItem = DropdownMenuItem;
  static DropdownDivider = Divider;
  static HamburgerMenu = HamburgerMenu;
  static HamburgerMenuItem = HamburgerMenuItem;
  static HelpMenu = HelpMenu;
  static ProfileMenu = ProfileMenu;
  static Sidebar = Sidebar;
  static SidebarLinkMenuItem = SidebarLinkMenuItem;
  static SidebarContainerMenuItem = SidebarContainerMenuItem;
}
