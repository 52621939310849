import { TypedDataColumn } from "../models";
import { Table } from "@devexpress/dx-react-grid-bootstrap3";
import { filterColumns } from "../util";
import memoizeOne from "memoize-one";

interface GenerateTableColumnExtensionOptions {
  align?: Table.ColumnExtension["align"];
  wordWrapEnabled?: Table.ColumnExtension["wordWrapEnabled"];
  width?: Table.ColumnExtension["width"];
}

export const generateTableColumnExtension = (
  column: TypedDataColumn,
  { align, width, wordWrapEnabled }: GenerateTableColumnExtensionOptions
) => {
  const columnExtension: Table.ColumnExtension = {
    columnName: column.name,
    align,
    wordWrapEnabled
  };
  if (width) {
    columnExtension.width = width;
  }
  return columnExtension;
};

export const generateTableColumnExtensions = memoizeOne(
  (columns: TypedDataColumn[]): Table.ColumnExtension[] => {
    const filteredColumns = filterColumns(columns);

    const numberColumns = filteredColumns.numberColumns.map(column =>
      generateTableColumnExtension(column, { align: "right" })
    );
    const booleanColumns = filteredColumns.booleanColumns.map(column =>
      generateTableColumnExtension(column, { align: "center", width: 140 })
    );

    return [...numberColumns, ...booleanColumns];
  }
);
