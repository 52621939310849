import React from "react";
import styled from "styled-components";
import { ModalPanel, ModalPanelProps } from "./side-modal";
import { OAuthConfigurationWidget, OAuthConfigurationWidgetProps } from "./oauth-configuration";

type IPanelProps = ModalPanelProps & OAuthConfigurationWidgetProps;
export const OAuthConfigurationPanel = ({ className, isActive, onHide, ...props }: IPanelProps) => {
  return (
    <StyledPanel className="email-settings-panel" isActive={isActive} onHide={onHide}>
      <ModalPanel.Header onHide={onHide} panelTitle="Connect Your E-mail Account" />
      <ModalPanel.Body>
        <OAuthConfigurationWidget {...props} />
      </ModalPanel.Body>
    </StyledPanel>
  );
};

const StyledPanel = styled(ModalPanel)`
  .modal-panel-content {
    .modal-panel-body {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      overflow: hidden;
      padding: 0;

      .panel-instructions {
        position: relative;
        margin: 10px 0;
        padding: 0 20px;
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 22px;
        color: #1e1e1e;
      }
    }
  }
`;
