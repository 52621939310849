import {
  FilteringState as DFilteringState,
  FilteringStateProps
} from "@devexpress/dx-react-grid";
import * as React from "react";
import { useTableContext } from "../table-context";

export const FilteringState = (props: Partial<FilteringStateProps>) => {
  const {
    filtering: { filters, setFilters }
  } = useTableContext();

  return React.useMemo(() => {
    // console.log("filtering");
    return (
      <DFilteringState
        filters={filters}
        onFiltersChange={setFilters}
        {...props}
      />
    );
  }, [filters, setFilters, props]);
};
