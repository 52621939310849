import * as React from "react";
import { ControlLabel, ControlLabelProps } from "react-bootstrap";
import styled from "styled-components";
import { InputFieldContext } from "./Context";
import { Icon } from "../Icon";
import * as AceColors from "../../AceColors";
import classNames from "classnames";
import { ValidationStateType } from "./models";

const LabelWrapper = styled(ControlLabel)`
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  margin-bottom: 4px;
  line-height: normal;
  .fa-asterisk {
    position: absolute;
    margin-left: 2px;
    margin-top: -1px;
    font-size: 9px;
    color: ${AceColors.errorRed};
  }

  &.has-error {
    color: ${AceColors.errorRed};
  }
  &.has-success {
    color: ${AceColors.successGreen};
  }
`;

export interface LabelProps extends ControlLabelProps, ValidationStateType {}

export const Label = ({ children, className, ...props }: LabelProps) => {
  const context = React.useContext(InputFieldContext);
  const classes = classNames(className, {
    "has-error":
      context.validationState === "error" || props.validationState === "error",
    "has-success":
      context.validationState === "success" ||
      props.validationState === "success"
  });
  return (
    <LabelWrapper {...(props as any)} className={classes}>
      {children}
      {(context.required || props.required) && <Icon name="asterisk" />}
    </LabelWrapper>
  );
};
