import * as React from "react";
import styled, { css } from "styled-components";
import Loading from "./Loading";
export type LoadingContainerProps = {
  /** The node that will be contained by the loading. */
  children: any;
  /** Is it loading? */
  isLoading: boolean;
  /** If you need to increase the size of the loading spinner, pass in a number to multiply the size by that number. ONLY works on whole numbers 2, 3, 4, 5. */
  size?: number;
  /** Any extra class to apply to the loader */
  className?: string;
  /** Allows the container to resize - smaller or larger - to fit the content it contains, instead of 100% */
  fitContentWidth?: boolean;
};

class UnstyledLoadingContainer extends React.Component<LoadingContainerProps> {
  static defaultProps = {
    isLoading: false,
    fitContentWidth: false
  };

  render() {
    return (
      <div className={this.props.className}>
        {this.props.children}
        <div>
          <Loading isLoading={this.props.isLoading} size={this.props.size} />
        </div>
      </div>
    );
  }
}

const StyledLoadingContainer = styled(UnstyledLoadingContainer)`
  position: relative;

  ${(props: any) =>
    props.fitContentWidth &&
    css`
      width: fit-content;
    `} ${(props: any) =>
    props.isLoading &&
    css`
      & > div:last-child {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.7);
        overflow: hidden;
        z-index: 1000;
      }
    `}


  & > div:last-child > i {
    position: absolute;
    top: 35%;
    width: 100%;
    text-align: center;
  }
`; //HACK: stupid ts. Had to add any to props or else failing right now

export default function LoadingContainer({
  children,
  ...props
}: LoadingContainerProps) {
  return <StyledLoadingContainer {...props}>{children}</StyledLoadingContainer>;
} //HACK: Somehow this is not working so doing this for now: https://react-styleguidist.js.org/docs/thirdparties.html#styled-components
