import classNames from "classnames";
import * as React from "react";

export interface ToolbarProps extends React.HTMLProps<HTMLDivElement> {}

export const Toolbar = ({ children, className, ...props }: ToolbarProps) => {
  return (
    <div className={classNames("panel-toolbar", className)} {...props}>
      {children}
    </div>
  );
};

export default Toolbar;
