import classNames from "classnames";
import * as React from "react";
import { Collapse } from "react-bootstrap";
import { containerSingularOpenManager } from "./containerSingularOpenManager";
import {
  ContainerArrow,
  ContainerMenuList
} from "./SidebarContainerMenuItemComponents";
import SidebarLinkMenuItem, {
  isSidebarLinkMenuItemActive
} from "./SidebarLinkMenuItem";
import SidebarMenuItem from "./SidebarMenuItem";
import {
  SidebarMenuItemIcon,
  SidebarMenuItemLink
} from "./SidebarMenuItemComponents";

export type SidebarContainerMenuItemProps = {
  /** SidebarContainerMenuItem,SidebarLinkMenuItem, or equivalent */
  children: any;
  name: string;
  iconClassName?: string;
  /** force rendering of html anchor tag, instead of react-router-link */
  renderHtmlAnchor?: boolean;
  /** @ignore */
  windowLocationPathname?: string;
};

export type SidebarContainerMenuItemState = {
  isActive: boolean;
  isOpen: boolean;
};

export default class SidebarContainerMenuItem extends React.Component<
  SidebarContainerMenuItemProps,
  SidebarContainerMenuItemState
> {
  static defaultProps = { windowLocationPathname: window.location.pathname };
  constructor(props: SidebarContainerMenuItemProps) {
    super(props);
    containerSingularOpenManager.addCloseCallback(props.name, this.close);
    const hasAnActiveDescendant = this.hasAnActiveDescendant(props.children);
    this.state = {
      isActive: hasAnActiveDescendant,
      isOpen: hasAnActiveDescendant
    };
  }

  close = () => {
    this.setState((prevState, props) => {
      return { isOpen: false };
    });
  };
  hasAnActiveDescendant = (children: any): boolean => {
    if (Array.isArray(children))
      return children.some(
        i =>
          (i.type === SidebarLinkMenuItem &&
            this.isSidebarLinkMenuItemActive(i.props)) ||
          (i.type === SidebarContainerMenuItem &&
            this.hasAnActiveDescendant(i.props.children))
      );
    else return this.isSidebarLinkMenuItemActive(children.props) || false;
  };

  isSidebarLinkMenuItemActive = ({
    to,
    toAlternatives,
    windowLocationPathname
  }: any) =>
    isSidebarLinkMenuItemActive(to, toAlternatives, windowLocationPathname);

  handleDropdownToggleClick = (e: any) => {
    e.preventDefault();
    this.setState((prevState, props) => {
      const isOpen = !prevState.isOpen;
      if (isOpen) containerSingularOpenManager.handleOnOpen(this.props.name);
      return { isOpen: isOpen };
    });
  };

  render() {
    return (
      <SidebarMenuItem className={classNames({ active: this.state.isActive })}>
        <SidebarMenuItemLink
          to="#"
          renderHtmlAnchor={this.props.renderHtmlAnchor}
          onClick={this.handleDropdownToggleClick}
        >
          {this.props.iconClassName && (
            <SidebarMenuItemIcon className={this.props.iconClassName} />
          )}
          <span className="container-menu-text">{this.props.name}</span>

          <ContainerArrow />
        </SidebarMenuItemLink>
        <Collapse data-testid="collapse" in={this.state.isOpen}>
          <ContainerMenuList>{this.props.children}</ContainerMenuList>
        </Collapse>
      </SidebarMenuItem>
    );
  } //container-menu-text is hack to easily style :(
}
