import {
  TableColumnReordering as DTableColumnReordering,
  TableColumnReorderingProps
} from "@devexpress/dx-react-grid-bootstrap3";
import * as React from "react";
import { createDefaultColumnOrder } from "../hooks/useColumnOrdering";
import { useTableContext } from "../table-context";

export const TableColumnReordering = (
  props: Partial<TableColumnReorderingProps>
) => {
  const {
    order: { columnOrder, setColumnOrder },
    columns
  } = useTableContext();

  return React.useMemo(() => {
    // console.log("order");
    const defaultColumnOrder = createDefaultColumnOrder(columns, columnOrder);
    return (
      <DTableColumnReordering
        order={defaultColumnOrder}
        onOrderChange={setColumnOrder}
        {...props}
      />
    );
  }, [columnOrder, columns, setColumnOrder, props]);
};

export const ColumnReordering = TableColumnReordering;
