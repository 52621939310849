import { Table } from "@devexpress/dx-react-grid-bootstrap3";
import { Icon, Loading } from "hcss-components";
import React from "react";
import styled from "styled-components";
import { useSmartDriveContext } from "../smartdrive-context";

export const TableActionsCell = ({ value, row }: Table.DataCellProps) => {
  const {
    api: { downloadFile, downloading },
    actions: { onLinkCopied },
    fileOperations: { setFileToDelete }
  } = useSmartDriveContext();

  const { isReadOnlyUser } = useSmartDriveContext();

  const isDownloading = downloading.indexOf(row.id) > -1;

  const handleCopyLink = () => {
    const textField = document.createElement("textarea");
    textField.innerText = row.shareUrl;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    onLinkCopied && onLinkCopied();
  };

  const handleDownloadFile = (e: any) => {
    e.stopPropagation();
    downloadFile(row);
  };

  const handleDeleteFile = (e: any) => {
    e.stopPropagation();
    setFileToDelete(row);
  };

  return (
    <td>
      <FileCell>
        <ActionsArea style={{ gridArea: "actions" }}>
          {row.shareUrl && row.shareUrl.trim() !== "" && (
            <TableActionButton onClick={handleCopyLink}>
              <Icon name="clone" /> Copy Link
            </TableActionButton>
          )}
          <TableActionButton
            onClick={handleDownloadFile}
            disabled={isDownloading}
          >
            {isDownloading ? (
              <Loading isLoading={isDownloading} />
            ) : (
              <Icon name="download" />
            )}{" "}
            Download
          </TableActionButton>
          <TableActionButton
            disabled={isReadOnlyUser}
            color="#9e0a0a"
            className="delete"
            onClick={handleDeleteFile}
          >
            <Icon name="trash-o" /> Delete
          </TableActionButton>
        </ActionsArea>
      </FileCell>
    </td>
  );
};

const TableActionButton = styled.button<{ color?: string }>`
  background-color: inherit;
  border: none;
  padding: none;
  color: ${props => (props.disabled ? "707070" : props.color || "#005eb8")};
`;

const ActionsArea = styled.div`
  justify-content: flex-end;
  align-items: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const FileCell = styled.div`
  padding: 4px;
`;
