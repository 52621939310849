import * as React from "react";
import { OAuthProvider } from "./models";

// tslint:disable-next-line:variable-name
export const OAuthProviderLogo_Microsoft = (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
    <title>Sign in with a Microsoft Account</title>
    <rect x="1" y="1" width="9" height="9" fill="#f25022" />
    <rect x="1" y="11" width="9" height="9" fill="#00a4ef" />
    <rect x="11" y="1" width="9" height="9" fill="#7fba00" />
    <rect x="11" y="11" width="9" height="9" fill="#ffb900" />
  </svg>
);

export const getUrlForOAuthProvider = (provider: OAuthProvider) => {
  switch (provider) {
    case OAuthProvider.Microsoft:
      return "login";
    default:
      return "login";
  }
};

export const getLogoForOAuthProvider = (provider: OAuthProvider): JSX.Element => {
  switch (provider) {
    case OAuthProvider.Microsoft:
      return OAuthProviderLogo_Microsoft;
    default:
      return OAuthProviderLogo_Microsoft;
  }
};

export const getDescriptionForOAuthProvider = (provider: OAuthProvider): string => {
  switch (provider) {
    case OAuthProvider.Microsoft:
      return "Outlook or Microsoft Exchange Account";
    case OAuthProvider.Google:
      return "GMail Account";
    case OAuthProvider.Yahoo:
      return "Yahoo! Mail Account";
    case OAuthProvider.AOL:
      return "AolMail Account";
    default:
      return "";
  }
};
