import * as React from "react";
import Dropdown from "./Dropdown";
import DropdownMenu from "./DropdownMenu";
import styled from "styled-components";

const MenuGroup = styled.div.attrs(() => ({ className: "nav-menu-group" }))`
  display: block;
  line-height: normal;
  break-inside: avoid; // used to prevent breaking group across columns in mega-menu
  padding: 36px 24px 0 24px;
`;

export type NavMenuProps = {
  /** (Optional) Additional class name to apply to this component */
  className?: string;
  /** (Optional) Specify name/label for the menu. Recommend use *either* icon *or* name, not both  */
  menuName?: string;
  /** (Optional) Specify an icon for the menu. Recommend use *either* icon *or* name, not both */
  menuIcon?: string;
  /** (Optional) Make the menu the full width of the window */
  fullWidth?: boolean;
  /** (Optional) Set the width of the dropdown menu in em (actually rem - relative to base font size) */
  widthEm?: number;
  /** (Optional) The max number of columns for wide screens */
  columnCount?: number;
  /** (Optional) The minimum width of columns in em (actually rem) */
  columnWidthEm?: number;
};

export default class NavMenu extends React.Component<NavMenuProps, any> {
  static Group = MenuGroup;

  static defaultProps = {
    columnCount: 1,
    columnWidthEm: 15
  };

  render() {
    var iconClassName;
    if (this.props.menuIcon) {
      iconClassName = "fa fa-" + this.props.menuIcon;
    }
    var iconStyle;
    if (this.props.menuIcon && this.props.menuName) {
      iconStyle = { marginRight: "0.25em" };
    }

    if (this.props.fullWidth === true) {
      return (
        <Dropdown fullWidth>
          <Dropdown.ToggleButton>
            {this.props.menuIcon && (
              <Dropdown.Icon className={iconClassName} style={iconStyle} />
            )}
            {this.props.menuName}
          </Dropdown.ToggleButton>
          <DropdownMenu
            fullWidth
            columnCount={this.props.columnCount}
            columnWidthEm={this.props.columnWidthEm}
          >
            {this.props.children}
          </DropdownMenu>
        </Dropdown>
      );
    } else {
      return (
        <Dropdown>
          <Dropdown.ToggleButton>
            {this.props.menuIcon && (
              <Dropdown.Icon className={iconClassName} style={iconStyle} />
            )}
            {this.props.menuName}
          </Dropdown.ToggleButton>
          <DropdownMenu
            right
            widthEm={this.props.widthEm}
            columnCount={this.props.columnCount}
            columnWidthEm={this.props.columnWidthEm}
          >
            {this.props.children}
          </DropdownMenu>
        </Dropdown>
      );
    }
  }
}
