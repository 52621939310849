import { ColumnChooser } from "@devexpress/dx-react-grid-bootstrap3";
import * as React from "react";
import { ButtonContainer } from "./toolbar-button-container";
import { ToolbarIconButton } from "./toolbar-icon-button";

export class ColumnVisibilityButton extends React.PureComponent<
  ColumnChooser.ToggleButtonProps
> {
  componentDidMount() {
    this.props.buttonRef(this);
  }
  render() {
    return (
      <ButtonContainer order={3}>
        <ToolbarIconButton onClick={this.props.onToggle} iconName="columns" />
      </ButtonContainer>
    );
  }
}
