import * as React from "react";
import styled from "styled-components";
import {
  PaneledPageContext,
  PaneledPageContextState
} from "./PaneledPageContext";
import { PaneledPageExtendedProps } from "./ExtendedProps";
import * as ConcreteColors from "../../ConcreteColors";

export interface HeaderPanelTitleProps extends PaneledPageExtendedProps {
  /** Automation testing id for the PaneledPage Header Title component. Defaults to "paneledpage-header-title". */
  testId?: string;
}
export class HeaderPanelTitle extends React.PureComponent<
  HeaderPanelTitleProps
> {
  render() {
    const { children, passThroughProps } = this.props;
    return (
      <StyledHeaderPanelTitle
        className="header-panel-title"
        data-testid={this.props.testId || "paneledpage-header-title"}
        {...passThroughProps}
      >
        <h1>{children}</h1>
      </StyledHeaderPanelTitle>
    );
  }
}

export interface HeaderPanelSubtitleProps extends PaneledPageExtendedProps {
  /** Automation testing id for the PaneledPage Header Subtitle component. Defaults to "paneledpage-header-subtitle". */
  testId?: string;
}
export class HeaderPanelSubtitle extends React.PureComponent<
  HeaderPanelSubtitleProps
> {
  render() {
    const { children, passThroughProps } = this.props;
    return (
      <StyledHeaderPanelSubtitle
        className="header-panel-subtitle"
        data-testid={this.props.testId || "paneledpage-header-subtitle"}
        {...passThroughProps}
      >
        {children}
      </StyledHeaderPanelSubtitle>
    );
  }
}

export interface HeaderPanelToolsProps extends PaneledPageExtendedProps {
  /** Automation testing id for the PaneledPage Header Tools component. Defaults to "paneledpage-header-tools". */
  testId?: string;
}
export class HeaderPanelTools extends React.PureComponent<
  HeaderPanelToolsProps
> {
  render() {
    const { children, passThroughProps } = this.props;
    return (
      <StyledHeaderPanelTools
        className="header-panel-tools"
        data-testid={this.props.testId || "paneledpage-header-tools"}
        {...passThroughProps}
      >
        {children}
      </StyledHeaderPanelTools>
    );
  }
}

export interface HeaderPanelFiltersTemplateProps
  extends PaneledPageExtendedProps {
  defaultExpanded?: boolean;
  panelLabel?: string;
  status?: any;
  templateTools?: any;
  defaultContentPadding?: boolean;
  /** Automation testing id for the PaneledPage Filters component. Defaults to "paneledpage-header-filters". */
  testId?: string;
}
export class HeaderPanelFiltersTemplate extends React.PureComponent<
  HeaderPanelFiltersTemplateProps
> {
  render() {
    return (
      <PaneledPageContext.Consumer>
        {(pageContext: PaneledPageContextState) => (
          <HeaderPanelFilters pageContext={pageContext} {...this.props} />
        )}
      </PaneledPageContext.Consumer>
    );
  }
}

interface HeaderPanelFiltersProps extends HeaderPanelFiltersTemplateProps {
  pageContext: PaneledPageContextState;
}
class HeaderPanelFilters extends React.PureComponent<HeaderPanelFiltersProps> {
  componentDidMount() {
    if (
      this.props.defaultExpanded &&
      this.props.pageContext.onDefaultExpandFilters
    )
      this.props.pageContext.onDefaultExpandFilters();
  }

  render() {
    const pageContext: PaneledPageContextState = this.props.pageContext;
    return (
      <StyledFiltersPanelContainer
        className={`header-panel-filters ${
          this.props.defaultContentPadding ? "default-content-padding" : ""
        }`}
        data-dropdown-open={pageContext.filtersHasOpenDropdown}
        data-panel-expanded={pageContext.filtersPanelExpanded}
        data-testid={this.props.testId || "paneledpage-header-filters"}
        {...this.props.passThroughProps}
      >
        {this.props.templateTools && (
          <div>This feature is not yet supported.</div>
        )}
        <div className="filters-controls">
          {!pageContext.filtersPanelExpanded && (
            <div
              className="filters-collapsed-toggle-region"
              onClick={pageContext.onToggleFiltersPanel}
            />
          )}
          <i
            className="filters-controls-toggle fa fa-filter"
            onClick={pageContext.onToggleFiltersPanel}
          />
          <span
            className="filters-controls-label"
            onClick={pageContext.onToggleFiltersPanel}
          >
            {this.props.panelLabel || "Filters"}
          </span>
          {this.props.status && (
            <div className="filters-controls-status">
              {this.props.status || ""}
            </div>
          )}
        </div>
        <div className="filters-content">{this.props.children}</div>
      </StyledFiltersPanelContainer>
    );
  }
}

export default class PageHeaderPanelTemplate extends React.PureComponent<
  PaneledPageExtendedProps
> {
  static Title = HeaderPanelTitle;
  static Subtitle = HeaderPanelSubtitle;
  static Tools = HeaderPanelTools;
  static Filters = HeaderPanelFiltersTemplate;

  render() {
    const { children, passThroughProps } = this.props;
    return (
      <PaneledPageContext.Consumer>
        {(pageContext: PaneledPageContextState) => (
          <StyledPageHeaderPanel
            className="page-header-panel"
            data-content-align={pageContext.contentAlign}
            maxpagewidth={pageContext.maxPageWidth as any}
            {...passThroughProps}
          >
            {children}
          </StyledPageHeaderPanel>
        )}
      </PaneledPageContext.Consumer>
    );
  }
}

export const StyledPageHeaderPanel = styled("div")<{ maxpagewidth?: number }>`
  background: ${ConcreteColors.gray100};
  border-bottom: 2px solid ${ConcreteColors.gray300};
  z-index: 1;

  & > div {
    padding: 0 16px;
  }
  & > div:first-child {
    padding-top: 10px;
    margin-bottom: 4px;
    border-bottom: none;
    padding-bottom: 0;
  }
  & > div:last-child:not(.header-panel-filters) {
    padding-bottom: 10px;
  }

  & > * {
    max-width: ${props =>
      props.maxpagewidth ? `${props.maxpagewidth}px` : "none"};
  }

  &[data-content-align="center"] > * {
    margin-left: auto;
    margin-right: auto;
  }
  &[data-content-align="left"] > * {
    margin-left: 0;
    margin-right: auto;
  }
`;
export const StyledHeaderPanelTitle = styled("div")`
  margin: 6px auto;
  border-bottom: none;

  & > h1 {
    margin: 0;
    padding: 0;
    font-size: 1.1em;
    font-weight: 700;
    color: #000000;
  }

  @media print {
    & > h1 {
      font-size: 22px;
      margin-bottom: 12px;
    }
  }
`;
export const StyledHeaderPanelSubtitle = styled("div")`
  font-size: 0.92em;
  @media print {
    margin-bottom: 10px;
  }
`;
export const StyledHeaderPanelTools = styled("div")`
  margin: 16px auto 8px auto;
  @media print {
    display: none;
  }
`;
export const StyledFiltersPanelContainer = styled("div")`
  position: relative;
  margin-top: 12px;
  padding: 10x 16px;
  transition: 0.2s;

  @media print {
    display: none;
  }

  & > .filters-content {
    font-size: 0.92em;
  }

  &[data-panel-expanded="true"] {
    background: #ffffff;
    .filters-content {
      padding-top: 10px;
      padding-bottom: 20px;
      background: #ffffff;
      box-shadow: -8px 16px 20px -12px #aaaaaa, 8px 16px 20px -12px #aaaaaa;
      overflow: visible;
    }
  }
  &[data-panel-expanded="false"] {
    background: #d6d6d6;
    & > .filters-controls {
      & > .filters-collapsed-toggle-region {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        z-index: 0;
      }
    }
    .filters-content {
      max-height: 0;
      background: #d6d6d6;
      box-shadow: none;
      overflow: hidden;
    }
  }

  & > .filters-controls {
    padding: 10px 0;
    overflow: auto;

    & > .filters-controls-toggle,
    & > .filters-controls-label,
    & > .filters-controls-status {
      position: relative;
      cursor: pointer;
      z-index: 2;
    }

    & > .filters-controls-toggle {
      font-size: 1em;
      margin-top: ${props =>
        props.theme.baseTheme === "Concrete" ? "1px" : "0"};
      margin-right: 10px;
      vertical-align: text-top;
    }
    & > .filters-controls-label {
      margin-right: 4px;
      font-size: 0.85em;
      font-weight: ${props =>
        props.theme.baseTheme === "Concrete" ? 600 : 700};
      text-transform: uppercase;
      &:hover {
        text-decoration: underline;
      }
    }
    & > .filters-controls-status {
      font-size: 0.9em;
    }
  }

  & > .filters-content {
    position: absolute;
    left: 0;
    width: 100%;
    transition: 0.2s;
    z-index: 50;
  }

  &.default-content-padding {
    & > .filters-content {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
`;
