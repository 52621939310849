import React from "react";
import { UserInfoSectionProps as ComponentProps } from "./Models";
import * as ConcreteColors from "../../../../ConcreteColors";
import styled, { css } from "styled-components";

const UserInfoSection: React.FC<ComponentProps> = (props: ComponentProps) => {
  // Destructure props
  const { firstName, lastName, companyName } = props;

  // Get user's initials
  const getFirstInitial = () => firstName[0]?.toUpperCase() ?? "";
  const getLastInitial = () => (lastName && lastName[0]?.toUpperCase()) ?? "";

  // Render user's full name
  const getFullName = () => {
    if (!lastName || lastName.trim() === "") return firstName;
    return `${firstName} ${lastName}`;
  };

  //
  //
  //
  return (
    <StyledUserInfoSection className="user-info-section">
      <div className="user-initials">
        <span
          className="initials-wrapper"
          data-testid="profile-menu-user-info-initials"
        >
          {getFirstInitial()}
          {getLastInitial()}
        </span>
      </div>
      <div
        className="user-full-name"
        data-testid="profile-menu-user-info-full-name"
        title={getFullName()}
      >
        {getFullName()}
      </div>
      <div
        className="user-company-name"
        data-testid="profile-menu-user-info-company-name"
        title={companyName}
      >
        {companyName}
      </div>
    </StyledUserInfoSection>
  );
};

export default UserInfoSection;

// Styled components
const cutoffText = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledUserInfoSection = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 2.4rem 2.8rem;
  grid-template-areas:
    "initials name"
    "initials company";
  grid-column-gap: 16px;
  grid-row-gap: 3px;
  margin: 1px 0 8px;
  padding: 24px 20px 0;
  background: #ffffff;
  box-shadow: inset 0 96px 24px -48px #ececec;

  & > .user-initials {
    grid-area: initials;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;

    background: ${ConcreteColors.blue200};
    border-radius: 60px;
    color: #ffffff;
    font-family: "ProximaNova", Arial, Helvetica, sans-serif;
    font-size: 2.4rem;
    letter-spacing: 0.1rem;
    ${cutoffText}
  }

  & > .user-full-name {
    grid-area: name;
    color: ${ConcreteColors.gray700};
    font-family: "ProximaNova Heavy", Arial, Helvetica, sans-serif;
    font-size: 2rem;
    line-height: normal;
    ${cutoffText}
  }

  & > .user-company-name {
    grid-area: company;
    color: ${ConcreteColors.gray500};
    font-size: 1.3rem;
    align-self: self-start;
    line-height: normal;
    ${cutoffText}
  }
`;
