import Uppy from "@uppy/core";
import XHRUpload from "@uppy/xhr-upload";
import React from "react";
import { FileMeta } from "../models";
import { uppyConfig } from "./config";
import { UppyResponse } from "./models";
import { isDuplicateFile, getDuplicateFilename } from "./duplicate-files";

export interface UseUppyOptions {
  endpoint: string;
  token: string;
  currentFiles: FileMeta[];
  onUploadComplete: (successful: FileMeta[], failed: any) => any;
}

export function useUppy({
  token,
  endpoint,
  currentFiles,
  onUploadComplete
}: UseUppyOptions) {
  const uppy = React.useRef<Uppy.Uppy | null>();

  React.useEffect(() => {
    uppy.current = Uppy({ ...uppyConfig }).use(XHRUpload, {
      endpoint,
      method: "post",
      formData: true,
      fieldName: "files[]",
      metaFields: ["filename"],
      bundle: false,
      timeout: 30 * 1000,
      limit: 5,
      responseType: "",
      headers: { authorization: "Bearer " + token }
    });
    const uppyRef = uppy.current;
    return () => {
      uppyRef?.close();
    };
  }, [endpoint, token]);

  React.useEffect(() => {
    const uppyRef = uppy.current;
    if (uppyRef) {
      uppyRef.on("complete", (result: any) => {
        const successful: FileMeta[] = result.successful.map(
          (file: UppyResponse) => {
            return file.response.body[0];
          }
        );

        onUploadComplete(
          successful,
          result.failed.length > 0
            ? {
                statusCode: result.failed[0].response.status,
                statusText: result.failed[0].error
              }
            : undefined
        );
      });
    }
  }, [onUploadComplete]);

  React.useEffect(() => {
    const uppyRef = uppy.current;
    if (uppyRef) {
      uppyRef.setOptions({
        onBeforeFileAdded: currentFile => {
          const existingFiles = currentFiles.map(file => file.fileName);
          if (isDuplicateFile(currentFile.name, existingFiles)) {
            currentFile.name = getDuplicateFilename(
              currentFile.name,
              existingFiles
            );
          }

          return currentFile;
        }
      });
    }
  }, [currentFiles]);

  const [showUploadModal, setShowUploadModal] = React.useState(false);

  const toggleFileModal = React.useCallback((show?: boolean) => {
    setShowUploadModal(prev => {
      const nextState = show !== undefined ? show : !prev;
      if (!nextState && uppy.current) {
        uppy.current.reset();
      }
      return nextState;
    });
  }, []);

  return { instance: uppy.current, ui: { showUploadModal, toggleFileModal } };
}
