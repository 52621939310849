import * as React from "react";
import Dropdown from "./Dropdown";
import DropdownMenu, { Divider } from "./DropdownMenu";
import DropdownMenuItem from "./DropdownMenuItem";

export type HelpMenuProps = {
  children?: any;
};

const HelpMenu: React.StatelessComponent<HelpMenuProps> = ({ children }) => {
  return (
    <Dropdown>
      <Dropdown.ToggleButton>
        <Dropdown.Icon className="fa fa-question-circle" />
      </Dropdown.ToggleButton>
      <DropdownMenu right>{children}</DropdownMenu>
    </Dropdown>
  );
};

HelpMenu.defaultProps = {
  children: (
    <>
      <DropdownMenuItem
        href="https://help.hcss.com"
        target="_blank"
        iconClassName="fa fa-file-text-o"
      >
        Help Center
      </DropdownMenuItem>
      <DropdownMenuItem
        href="https://support.hcss.com/chat/"
        target="_blank"
        iconClassName="fa fa-comments"
      >
        Live Chat with Support
      </DropdownMenuItem>
      <DropdownMenuItem
        href="https://help.hcss.com/s/contactsupport"
        target="_blank"
        iconClassName="fa fa-envelope"
      >
        Contact Support
      </DropdownMenuItem>
      <DropdownMenuItem href="tel:+18004443196" iconClassName="fa fa-phone">
        Help Line: 1-800-444-3196
      </DropdownMenuItem>
      <Divider />
      <DropdownMenuItem
        href="http://ideas.hcss.com/"
        target="_blank"
        iconClassName="fa fa-lightbulb-o"
      >
        Log a Product Suggestion
      </DropdownMenuItem>
      <DropdownMenuItem
        href="http://www.hcss.com/about-us/"
        target="_blank"
        iconClassName="fa fa-hcss"
      >
        About HCSS
      </DropdownMenuItem>
    </>
  )
};

export default HelpMenu;
