import React, { useEffect, useMemo, useState } from "react";
import { WizardProps as ComponentProps } from "./Models";
import WizardProgress from "./WizardProgress/";
import StepContainer from "./StepContainer";
import styled from "styled-components";

const Wizard: React.FC<ComponentProps> = (props: ComponentProps) => {
  // Destructure props
  const {
    currentStep,
    onChangeStep,
    steps,
    components,
    styles,
    className,
    testIds,
    containerBackground,
    disableTransitionEffect,
    minContentHeight,
    children
  } = props;

  //
  //
  //
  // Store the names of each step
  const [stepNames, setStepNames] = useState<string[]>([]);
  useEffect(() => {
    // Only update stored step name array if there has been some
    // kind of change, either in the actual names themselves or in
    // the order of the steps.
    // This is meant to prevent unnecessary props updates to (and
    // rerenders of) the progress bar, which uses the array of names
    // to render the progress steps.
    const updatedNames = steps.map(step => step.name);
    setStepNames(names =>
      JSON.stringify(names) !== JSON.stringify(updatedNames)
        ? updatedNames
        : names
    );
  }, [steps]);

  //
  //
  //
  // Track the user's completion
  const [lastCompletedStepIndex, setLastCompletedStepIndex] = useState<number>(
    currentStep ? currentStep - 1 : -1
  );
  useEffect(() => {
    setLastCompletedStepIndex(last =>
      currentStep - 1 > last ? currentStep - 1 : last
    );
  }, [currentStep]);

  //
  //
  //
  // Render the progress bar
  const progressBar = useMemo(() => {
    const ProgressBar = components?.progress || WizardProgress;
    return (
      <ProgressBar
        currentStep={currentStep}
        onChangeStep={onChangeStep}
        stepNames={stepNames}
        lastCompletedStepIndex={lastCompletedStepIndex}
        style={styles?.progress}
        testId={testIds?.progress}
        progressStepComponent={components?.progressStep}
        progressTrackComponent={components?.progressTrack}
        stepCompleteIndicatorComponent={components?.stepCompleteIndicator}
        containerBackground={containerBackground}
      />
    );
  }, [
    currentStep,
    onChangeStep,
    lastCompletedStepIndex,
    stepNames,
    components,
    styles,
    testIds,
    containerBackground
  ]);

  //
  //
  // Transition between displayed content as user progresses
  // through the steps in the Wizard. Disable this effect if
  // disableTransitionEffect is set to false.
  const [displayedStep, setDisplayedStep] = useState<number>(currentStep);
  const [isTransitioning, setIsTransitioning] = useState<boolean>(true);
  useEffect(() => {
    let transitionTimeout: any;

    if (disableTransitionEffect) {
      setDisplayedStep(currentStep);
      setIsTransitioning(false);
    } else {
      setIsTransitioning(true);
      transitionTimeout = setTimeout(() => {
        setDisplayedStep(currentStep);
        setIsTransitioning(false);
      }, 500);
    }

    return () => {
      if (!!transitionTimeout) clearTimeout(transitionTimeout);
    };
  }, [currentStep, disableTransitionEffect]);

  // Render the content of the displayed step
  const renderCurrentStepContent = () => {
    const CurrentStep = components?.stepContainer || StepContainer;
    return (
      <CurrentStep
        currentStepIndex={displayedStep}
        isTransitioning={isTransitioning}
        minContentHeight={minContentHeight}
        style={styles?.step}
        testId={testIds?.step}
      >
        {steps[displayedStep].content}
      </CurrentStep>
    );
  };

  //
  //
  //
  const Wrapper = components?.wrapper || StyledWizardWrapper;
  return (
    <Wrapper
      className={`wizard-content-wrapper ${className}`}
      data-testid={testIds?.wrapper || "wizard-content-wrapper"}
      style={styles?.wrapper}
    >
      {progressBar}
      {renderCurrentStepContent()}
      {children}
    </Wrapper>
  );
};
export default Wizard;

// Styled components
const StyledWizardWrapper = styled.div`
  position: relative;
  transform: translateZ(0);
`;
