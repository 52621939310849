export function serializeValue(value: any) {
  try {
    return JSON.stringify(value);
  } catch (error) {
    console.log("error serializing ", value);
  }
  return;
}

export function deserializeValue(value: any) {
  try {
    value = JSON.parse(value);
    return value;
  } catch (error) {
    console.log("error deserializing ", value);
  }
  return;
}
