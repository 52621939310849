import { DataTypeProvider } from "@devexpress/dx-react-grid";
import { Checkbox, Icon } from "hcss-components";
import moment from "moment";
import React, {
  useState,
  useMemo,
  useCallback,
  FC,
  useEffect,
  Dispatch
} from "react";
import NumberFormat from "react-number-format"; // TODO: Move this to hcss-components
import { DateTimePicker, DropdownList } from "react-widgets"; // TODO: Switch this out for react-select
import styled from "styled-components";
import {
  DataColumnType,
  TypedDataColumn,
  BaseTypedDataColumn
} from "../models";
import { useTableContext } from "../table-context";
import momentLocalizer from "react-widgets-moment";
import { sortBy, debounce } from "lodash";
import { useLocation, Link } from "react-router-dom";

export const localizer = momentLocalizer();

export const TextEditor: React.FC<DataTypeProvider.ValueEditorProps> = props => {
  const column = props.column as
    | BaseTypedDataColumn<DataColumnType.ShortText>
    | BaseTypedDataColumn<DataColumnType.LongText>;
  const { onValueChange, value: initialValue } = props;
  const [value, setValue] = useDebouncedGridValue(initialValue, onValueChange);

  const isDisabled = column.readOnly || props.disabled;

  return (
    <input
      value={value}
      onChange={e =>
        setValue(
          column.config?.setCellValue?.(e.target.value) ?? e.target.value
        )
      }
      type="text"
      disabled={isDisabled}
      className="form-control"
      maxLength={column.config?.maxLength}
    />
  );
};

export const TextProvider = () => {
  const { columns } = useTableContext();
  return React.useMemo(
    () => (
      <DataTypeProvider
        editorComponent={TextEditor}
        for={columns
          .filter(
            column =>
              column.type === DataColumnType.ShortText ||
              column.type === DataColumnType.LongText
          )
          .map(column => column.name)}
      />
    ),
    [columns]
  );
};

export const NumberFormatter = (
  props: DataTypeProvider.ValueFormatterProps
) => {
  const { value } = props;
  const column = props.column as TypedDataColumn;
  if (value !== undefined && value !== null && typeof value === "number") {
    return (
      <span
        style={{
          float: "right"
        }}
      >
        <NumberFormat
          displayType={"text"}
          thousandSeparator
          decimalScale={column.type === DataColumnType.Currency ? 2 : 5}
          value={value}
          prefix={column.type === DataColumnType.Currency ? "$" : undefined}
          fixedDecimalScale={
            column.type === DataColumnType.Currency ? true : false
          }
        />
      </span>
    );
  }

  return null;
};

export const NumberEditor = (props: DataTypeProvider.ValueEditorProps) => {
  const { value: initialValue, onValueChange } = props;
  const [value, setValue] = useDebouncedGridValue(initialValue, onValueChange);
  const column = props.column as
    | BaseTypedDataColumn<DataColumnType.Number>
    | BaseTypedDataColumn<DataColumnType.Currency>;
  const isCurrency = column.type === DataColumnType.Currency;
  const decimalScale = column.config?.decimalScale ?? (isCurrency ? 2 : 5);

  return (
    <StyledNumberEditorWrapper readOnly={column.readOnly}>
      <NumberFormat
        readOnly={column.readOnly}
        thousandSeparator
        decimalScale={decimalScale}
        value={value}
        prefix={isCurrency ? "$" : undefined}
        fixedDecimalScale={isCurrency ? true : false}
        onValueChange={({ floatValue }) => {
          setValue(column.config?.setCellValue?.(floatValue) ?? floatValue);
        }}
        isAllowed={values => {
          const { floatValue } = values;
          if (
            column.config?.min !== undefined &&
            floatValue < column.config.min
          )
            return false;
          if (
            column.config?.max !== undefined &&
            floatValue > column.config.max
          )
            return false;
          return true;
        }}
      />
    </StyledNumberEditorWrapper>
  );
};

const StyledNumberEditorWrapper = styled.div<{ readOnly?: boolean }>`
  & input {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: ${props => (props.readOnly ? "#eee" : "#fff")};
    cursor: ${props => (props.readOnly ? "no-drop" : "text")};
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }

  & input:focus {
    border-color: ${props => (props.readOnly ? "none" : "#66afe9")};
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: ${props =>
      props.readOnly
        ? "none"
        : `inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);`};
  }
`;

export const NumberFormatProvider = () => {
  const { columns } = useTableContext();

  return useMemo(
    () => (
      <DataTypeProvider
        formatterComponent={NumberFormatter}
        editorComponent={NumberEditor}
        for={columns
          .filter(
            column =>
              column.type === DataColumnType.Number ||
              column.type === DataColumnType.Currency
          )
          .map(column => column.name)}
      />
    ),
    [columns]
  );
};

const DateEditor = (props: DataTypeProvider.ValueEditorProps) => {
  const { value, onValueChange } = props;
  const column = props.column as TypedDataColumn;

  const includeTime = column.type === DataColumnType.DateTime;
  const dateValue = value ? moment(value).toDate() : undefined;
  const format = includeTime ? "M/DD/YY h:mm A" : "M/DD/YY";

  return (
    <DateTimeEditorContainer>
      <DateTimePicker
        time={includeTime}
        value={dateValue}
        currentDate={
          includeTime && !dateValue
            ? moment()
                .set("hour", 8)
                .set("minute", 0)
                .toDate()
            : undefined
        }
        onCurrentDateChange={newValue => {
          onValueChange(newValue && newValue.toISOString());
        }}
        onChange={newValue => {
          onValueChange(newValue && newValue.toISOString());
        }}
        disabled={column.readOnly}
        readOnly={column.readOnly}
        format={format}
        parse={parse}
      />
    </DateTimeEditorContainer>
  );
};

const parse = (stringValue: string): Date => {
  const dateValue = moment(stringValue);
  if (dateValue.isValid()) {
    return dateValue.toDate();
  }
  // @ts-ignore
  return null;
};

const DateTimeEditorContainer = styled.div`
  & .rw-widget-picker {
    height: 34px;
  }
  & .rw-cell.rw-state-selected {
    color: black !important;
  }
`;

export function normalizeDate(
  rawValue: string,
  convertFromUtc = false,
  ignoreTimezone = false
) {
  if (ignoreTimezone) {
    /* Turn the string "2019-06-01T00:00:00Z" into something more like "2019-06-01T05:00:00.000Z".
     *
     * The estimates system passes around dates as though they were date-times. This is a problem because
     * the bid date will come in as something equivalent to "midnight UTC on June 1st, 2019". When that
     * string gets turned into a Date (i.e., new Date(...)), javascript automatically takes that to mean
     * "7pm CDT on May 31st, 2019" which is not the date that we want at all. This function turns "7pm
     * CDT on May 31st, 2019" into "midnight CDT on June 1st, 2019" instead.
     * */

    const value = moment(rawValue);
    return value.subtract(value.utcOffset(), "m");
  }

  const dateValue = convertFromUtc
    ? moment.utc(rawValue).local()
    : moment(rawValue);

  return dateValue;
}

export const DateFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
  const { value } = props;
  const column = props.column as BaseTypedDataColumn<DataColumnType.DateTime>;

  if (value) {
    const dateValue = normalizeDate(value, column.config && column.config.utc);

    if (dateValue.isValid()) {
      return (
        <DateTimeContainer>
          <DateContainer>
            <div data-testid="table-cell-date-formatted">
              {dateValue.format("L")}
            </div>
          </DateContainer>
          {column.type === DataColumnType.DateTime && (
            <div data-testid="table-cell-time-formatted">
              {dateValue.format("LT")}
            </div>
          )}
        </DateTimeContainer>
      );
    }
  }

  return null;
};

export const DateTimeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const DateContainer = styled.div`
  margin-right: 8px;
`;

export const DateFormatProvider = () => {
  const { columns } = useTableContext();

  return useMemo(
    () => (
      <DataTypeProvider
        formatterComponent={DateFormatter}
        editorComponent={DateEditor}
        for={columns
          .filter(
            column =>
              column.type === DataColumnType.Date ||
              column.type === DataColumnType.DateTime
          )
          .map(column => column.name)}
      />
    ),
    [columns]
  );
};

export interface DropdownListOption {
  display: string;
  value?: string;
}

export const DropDownFormatter = (
  props: DataTypeProvider.ValueFormatterProps
) => {
  const { value } = props;
  const column = props.column as BaseTypedDataColumn<DataColumnType.List>;
  if (column.config?.showLabelValueInTable) {
    const labelValue = column.config.listValues?.find(
      opt => opt.value === value
    )?.display;
    if (labelValue) return labelValue;
  }
  return value ? value : "";
};

export const DropDownEditor: FC<DataTypeProvider.ValueEditorProps> = props => {
  const { value, onValueChange } = props;
  const column = props.column as BaseTypedDataColumn<DataColumnType.List>;
  const [options, setOptions] = useState<DropdownListOption[]>(
    column.config?.listValues ?? []
  );

  const data: DropdownListOption[] = options;

  let sortedData = useMemo(() => {
    if (!column.config?.customSort) {
      return sortBy(data, opt => opt.display.toLocaleLowerCase());
    }
    return data;
  }, [column.config, data]);

  if (value) {
    sortedData = sortedData.concat([{ display: "(clear)", value: undefined }]);
  }

  const allowCreate = column.config?.dynamic ?? false;

  const addFieldValue = useCallback(
    (newValue: string) => {
      if (newValue?.trim?.() !== "") {
        setOptions([...options, { display: newValue, value: newValue }]);
      }
    },
    [options]
  );

  return useMemo(
    () => (
      <DropdownContainer>
        <DropdownList
          value={value}
          disabled={column.readOnly ?? false}
          filter="contains"
          data={sortedData}
          textField="display"
          valueField="value"
          onChange={newValue =>
            onValueChange(
              newValue && newValue.value ? newValue.value : undefined
            )
          }
          allowCreate={allowCreate}
          onCreate={
            allowCreate
              ? newValue => {
                  addFieldValue(newValue);
                  if (newValue) {
                    onValueChange(newValue);
                  }
                }
              : undefined
          }
          messages={{
            emptyList: "",
            createOption: "To add a new option: type a value, press Enter"
          }}
        />
      </DropdownContainer>
    ),
    [
      addFieldValue,
      allowCreate,
      column.readOnly,
      onValueChange,
      sortedData,
      value
    ]
  );
};

export const DropDownFormatProvider = () => {
  const { columns } = useTableContext();

  return useMemo(
    () => (
      <DataTypeProvider
        formatterComponent={DropDownFormatter}
        editorComponent={DropDownEditor}
        for={columns
          .filter(
            column =>
              column.type === DataColumnType.List ||
              column.type === DataColumnType.States
          )
          .map(column => column.name)}
      />
    ),
    [columns]
  );
};

export const BooleanEditor = ({
  value,
  column,
  onValueChange,
  disabled
}: DataTypeProvider.ValueEditorProps) => {
  const col = column as BaseTypedDataColumn<DataColumnType.Boolean>;
  const isDisabled = col.readOnly || disabled;
  return (
    <div style={{ textAlign: "center" }}>
      <Checkbox
        disabled={isDisabled}
        checked={value}
        onChange={() => onValueChange(!value)}
      />
    </div>
  );
};

const BooleanFormatter = ({ value }: DataTypeProvider.ValueFormatterProps) => {
  const checked = value === true ? true : false;
  return (
    <div style={{ textAlign: "center" }}>
      {checked && <Icon name="check" />}
    </div>
  );
};

export const BooleanFormatProvider = () => {
  const { columns } = useTableContext();

  return useMemo(
    () => (
      <DataTypeProvider
        formatterComponent={BooleanFormatter}
        editorComponent={BooleanEditor}
        for={columns
          .filter(column => column.type === DataColumnType.Boolean)
          .map(column => column.name)}
      />
    ),
    [columns]
  );
};

const LinkFormatter = ({
  column,
  row,
  value
}: DataTypeProvider.ValueFormatterProps) => {
  const { pathname } = useLocation();
  if (!row) {
    return value;
  }

  const typedColumn = column as TypedDataColumn;

  if (typedColumn.config?.getCellRoute) {
    return (
      <Link
        to={typedColumn.config.getCellRoute({
          column: typedColumn,
          row,
          value
        })}
      >
        {value}
      </Link>
    );
  }

  if (typedColumn.config?.baseRoute) {
    return <Link to={typedColumn.config.baseRoute + row.id}>{value}</Link>;
  }

  return <Link to={`${pathname}/${row.id}`}>{value}</Link>;
};

export const LinkProvider = () => {
  const { columns } = useTableContext();
  return useMemo(
    () => (
      <DataTypeProvider
        formatterComponent={LinkFormatter}
        for={columns
          .filter(column => column.config?.link)
          .map(column => column.name)}
      />
    ),
    [columns]
  );
};

const HyperLinksFormatter = ({
  value
}: DataTypeProvider.ValueFormatterProps) => {
  if (!value || value === "") {
    return <div />;
  }
  return (
    <a
      href={value.indexOf("://") === -1 ? "https://" + value : value}
      target="_blank"
      rel="noopener noreferrer"
    >
      {value}
    </a>
  );
};

export const HyperLinksProvider = () => {
  const { columns } = useTableContext();
  return useMemo(
    () => (
      <DataTypeProvider
        formatterComponent={HyperLinksFormatter}
        for={columns
          .filter(column => column.type === DataColumnType.Links)
          .map(column => column.name)}
      />
    ),
    [columns]
  );
};

const DropdownContainer = styled.div<{ hasErrors?: boolean }>`
  & .rw-widget-input {
    height: 34px;
    ${props => (props.hasErrors ? "border: 1px solid #a94442;" : "")};
  }
`;

export function useDebouncedGridValue(
  initialValue: any,
  onValueChange: (value: any) => any,
  delay = 150
) {
  const [value, setValue] = useState(initialValue);

  const debouncedOnValueChange = useCallback(debounce(onValueChange, delay), [
    onValueChange
  ]);

  useEffect(() => {
    if (initialValue !== value) {
      debouncedOnValueChange(value);
    }
  }, [debouncedOnValueChange, value, initialValue]);

  return [value, setValue] as [any, Dispatch<any>];
}
